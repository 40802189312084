import React from "react"

import { Col, Card, CardBody, CardTitle } from "reactstrap"

import LoginUsageChart from "./Charts/LoginUsageChart"

const AvgLoginUsageChart = ({ monthlyData }) => {
  let avg_login = monthlyData?.map(currElem => currElem?.avg_login).reverse()
  let labelsData = monthlyData
    ?.map(currElem => currElem?.month + " " + currElem?.year)
    .reverse()

  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Average Login Usage</CardTitle>
            <LoginUsageChart labelsData={labelsData} avg_login={avg_login} />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default AvgLoginUsageChart
