import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Input,
  Container,
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { toast } from "react-toastify"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function ClienteleList() {
  document.title = "Clientele"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [clienteleList, setClienteleList] = useState([])
  const [filteredClienteleList, setFilteredClienteleList] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => {
    setShowModal(false)
  }
  
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getClienteleData = async () => {
    try {
      const res = await axios.get(URL.ALL_CLIENTELE)
      setClienteleList(res.data.data)
      setFilteredClienteleList(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getClienteleData()
  }, [])

  const columns = [
    {
      name: "Category",
      selector: row => row.category,
      width: "220px"
    },
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      width: "220px"
    },
    {
      name: "Image",
      width: "230px",
      selector: row => (
        <img
          style={{
            height: "60px",
            width: "60px",
            objectFit: "contain",
          }}
          alt="Image"
          src={row.image?.image_path}
        />
      ),
    },
    {
      name: "Status",
      width: "140px",
      cell: row => (
        <div>
          <Button
            variant="outline"
            color={`${row.status === true ? `success` : `dark`} `}
            className="btn btn-rounded float-end w-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            {row.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      name: "Action",
      cell: row => (
        <Col
          sm={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex gap-2 float-end">
            <Button
              className="btn btn-rounded btn-warning  w-sm"
              onClick={() => handleEditClientele(row)}
            >
              Edit
            </Button>
            <Button
              color="danger"
              className="btn btn-rounded  w-sm"
              onClick={() => showDeleteModal(row)}
            >
              Delete
            </Button>
          </div>
        </Col>
      ),
    },
  ]

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(URL.UPDATE_CLIENTELE_STATUS + `${item.id}`, {
        status: !sendStatus,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        getClienteleData()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = clienteleList.filter(item => {
      return (
        item.category.toLowerCase().match(search.toLowerCase()) ||
        item.title.toLowerCase().match(search.toLowerCase())
      )
    })

    setFilteredClienteleList(result)
  }, [search])

  const handleAddClientele = row => {
    history.push("/add-clientele")
  }

  const handleEditClientele = row => {
    history.push("/add-clientele", { state: row })
  }

  const handleDeleteClientele = async item => {
    try {
      const res = await axios.delete(URL.DELETE_CLIENTELE, {
        data: {
          entryIds: [item.id],
        },
      })
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        getClienteleData()
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Clientele</ModalHeader>
        <ModalBody>
          Do you want to delete this row with title {deleteRecord.title}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteClientele(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Row className="py-4 px-2">
          <div className="draggable-body-button">
            <Col sm={10}>
              <p className="h4 card-title font-size-22">Clientele</p>
            </Col>

            <Col sm={1}>
              <Button
                color="secondary"
                className="btn btn-rounded btn-info float-end w-sm"
                onClick={handleAddClientele}
              >
                Add
              </Button>
            </Col>
          </div>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredClienteleList}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  placeholder="Search"
                  id="table-search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ClienteleList
