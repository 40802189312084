import React from "react"
import Agreement from "./Agreement"
import VendorAnalytics from "./VendorAnalytics"

function Home({ dashboardAnalytics }) {
  return (
    <React.Fragment>
      <VendorAnalytics dashboardAnalytics={dashboardAnalytics} />
      <Agreement />
    </React.Fragment>
  )
}

export default Home
