import React from "react"

import { Col, Card, CardBody, CardTitle } from "reactstrap"
import YearlyKioskChart from "./Charts/YearlyKioskChart"

const YearlyKioskUsage = ({ yearlyKiosk }) => {
  let duration = yearlyKiosk?.map(currElem => currElem?.duration).reverse()
  let labelsData = yearlyKiosk
    ?.map(currElem => currElem?.month + " " + currElem?.year)
    .reverse()

  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Yearly KIOSK Usage</CardTitle>
            <YearlyKioskChart labelsData={labelsData} duration={duration} />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default YearlyKioskUsage
