import React, { useEffect, useState } from "react"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import Input from "rc-input"
import { Label, Container, Card, CardBody, Row, Col, Button } from "reactstrap"
import { toast } from "react-toastify"

function ManageContact() {
  document.title = "Edit Contact Details"
  const [contactData, setContactData] = useState({
    number: "",
    email: "",
    address: "",
    contacts: "",
    map: "",
    description: "",
  })

  const { number, email, address, contacts, map, description } = contactData
  const [isDisabled, setIsDisabled] = useState(true)
  const handleChange = e => {
    setContactData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const getManageConact = async () => {
    try {
      const res = await axios.get(URL.ALL_MANAGE_CONTACT)
      console.log("res", res)
      setContactData({
        number: res.data.data.number,
        email: res.data.data.email,
        address: res.data.data.address,
        contacts: res.data.data.contacts,
        map: res.data.data.map,
        description: res.data.data.description,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getManageConact()
  }, [])

  const updateManageContact = async () => {
    try {
      const res = await axios.put(URL.UPDATE_MANAGE_CONTACT, {
        number: number,
        email: email,
        address: address,
        contacts: contacts,
        map: map,
        description: description,
      })
      if (res.data.success) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    if(
       number > 0 &&
       email.length > 0 &&
       address.length > 0 &&
       contacts.length > 0 &&
       map.length > 0 &&
       description.length > 0
    ){
    setIsDisabled(false)
    }else{
    setIsDisabled(true)
    }
  }, [number, email, address, contacts, map, description])

  return (
    <React.Fragment>
      <div className="page-content">
        <Row className="py-4 px-2">
          <Col sm={10}>
            <p className="h4 card-title font-size-22">Edit Contact Details</p>
          </Col>
          <Col>
            <Button
              disabled={isDisabled}
              className={
                isDisabled
                ? `btn btn-rounded float-end w-sm`
                : `btn btn-info btn-rounded float-end w-sm`
              }
              onClick={updateManageContact}
            >
              Save
            </Button>
          </Col>
        </Row>
        <Container fluid={true}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label>Footer Contact Number</Label>
                    <Input
                      type="number"
                      name="number"
                      value={number}
                      className="form-control"
                      id="formrow-footer_contact_num-Input"
                      placeholder="Footer Contact Number"
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="mb-3">
                    <Label>Email Id</Label>
                    <Input
                      type="email"
                      name="email"
                      value={email || ""}
                      className="form-control"
                      id="formrow-email-Input"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label>Address</Label>
                    <Input
                      type="text"
                      name="address"
                      value={address || ""}
                      className="form-control"
                      id="formrow-address-Input"
                      placeholder="Address"
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label>
                      Multiple Contacts (use @ for multiple Contact Numbers)
                    </Label>
                    <Input
                      type="text"
                      name="contacts"
                      value={contacts || ""}
                      className="form-control"
                      id="formrow-multiple_contacts-Input"
                      placeholder="Multiple Contacts"
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label>Google Map Link</Label>
                    <Input
                      name="map"
                      value={map || ""}
                      className="form-control"
                      id="formrow-footer_contact_num-Input"
                      placeholder="Google Map Link"
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label>Footer Description</Label>
                    <Input
                      name="description"
                      value={description || ""}
                      className="form-control"
                      id="formrow-footer_contact_num-Input"
                      placeholder="Footer Description"
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageContact
