import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import DataTable from "react-data-table-component"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import {
  Input,
  Container,
  Button,
  Col,
  Card,
  CardBody,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function TestimonialList() {
  document.title = "Testimonial List"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [testimonialData, setTestimonialData] = useState([])
  const [filteredTestimonialData, setFilteredTestimonial] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => {
    setShowModal(false)
  }

  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const columns = [
    {
      name: "Logo",
      width: "120px",
      selector: row => (
        <img
          style={{
            height: "60px",
            width: "60px",
            objectFit: "contain",
          }}
          alt="Logo"
          src={row.logo?.image_path}
        />
      ),
    },
    {
      name: "Name",
      width: "140px",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Designation",
      width: "200px",
      selector: row => row.designation,
    },
    {
      name: "Description",
      width: "160px",
      selector: row => <div style={{ width: "120px" }}>{row.description}</div>,
    },
    {
      name: "Image",
      width: "160px",
      selector: row => (
        <img
          style={{
            height: "60px",
            width: "60px",
            objectFit: "contain",
          }}
          alt="Image"
          src={row.image?.image_path}
        />
      ),
    },
    {
      name: "Status",
      width: "160px",
      cell: row => (
        <Button
          variant="outline"
          color={`${row.status === true ? `success` : `dark`} `}
          className="btn btn-rounded float-end w-sm"
          onClick={() => handleUpdateStatus(row)}
        >
          {row.status === true ? "ACTIVE" : "NOT ACTIVE"}
        </Button>
      ),
    },
    {
      name: "Action",
      cell: row => (
        <Col
          sm={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex gap-2 float-end">
            <Button
              className="btn btn-rounded btn-warning float-end w-sm"
              onClick={() => handleEditTestimonial(row)}
            >
              Edit
            </Button>
            <Button
              color="danger"
              className="btn btn-rounded  w-sm"
              onClick={() => showDeleteModal(row)}
            >
              Delete
            </Button>
          </div>
        </Col>
      ),
    },
  ]

  const getTestimonialData = async () => {
    try {
      const res = await axios.get(URL.ALL_TESTIMONIAL)
      if (res.data.success) {
        console.log(res)
        setTestimonialData(res.data.data)
        setFilteredTestimonial(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTestimonialData()
  }, [])

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(
        URL.UPDATE_TESTIMONIAL_STATUS + `${item.id}`,
        {
          status: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getTestimonialData()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleDeleteTestimonial = async item => {
    try {
      const res = await axios.delete(URL.DELETE_TESTIMONIAL, {
        data: {
          entryIds: [item.id],
        },
      })
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getTestimonialData()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = testimonialData.filter(item => {
      return item.name.toLowerCase().match(search.toLowerCase())
    })

    setFilteredTestimonial(result)
  }, [search])

  const handleAddTestimonial = row => {
    history.push("/add-testimonial")
  }

  const handleEditTestimonial = row => {
    history.push("/add-testimonial", { state: row })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>All Testimonial</ModalHeader>
        <ModalBody>
          Do you want to delete this row with name {deleteRecord.name}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteTestimonial(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Row className="py-4 px-2">
          <div className="draggable-body-button">
            <Col sm={10}>
              <p className="h4 card-title font-size-22">All Testimonial</p>
            </Col>
            <Col sm={1}>
              <Button
                onClick={handleAddTestimonial}
                color="secondary"
                className="btn btn-rounded btn-info float-end w-sm"
              >
                Add
              </Button>
            </Col>
          </div>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredTestimonialData}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  placeholder="Search"
                  id="table-search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestimonialList
