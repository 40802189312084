import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ReactPlayer from "react-player"

function OurProcessSection({
  commercialData,
  setCommercialData,
  processFile,
  setProcessFile,
  processDataURL,
  setProcessDataURL,
}) {
  const { our_process_head, our_process_subhead, our_process_image } =
    commercialData

  const handleChange = e => {
    setCommercialData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let url = our_process_image.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setCommercialData(prevState => ({
        ...prevState,
        our_process_image: img,
      }))
    }
    const file = e.target.files[0]
    setProcessFile(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (processFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setProcessDataURL(result)
        }
      }
      fileReader.readAsDataURL(processFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [processFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile6")
    temp.value = null
    setProcessDataURL(null)
    setCommercialData(prevState => ({
      ...prevState,
      our_process_image: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-3">Our Process Section</CardTitle>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="first_section_heading"
                onChange={handleChange}
                value={our_process_head || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Sub Heading</Label>
              <Input
                type="text"
                name="first_section_heading"
                onChange={handleChange}
                value={our_process_subhead || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>

            <Row>
              <Col className="col-12">
                <Label>Upload Video/Image</Label>
                <div className="row">
                  <Col sm={6}>
                    <div >
                      <div className="img-container p-2">
                        {our_process_image !== undefined &&
                          Object.keys(our_process_image).length > 0 &&
                          url === "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <ReactPlayer
                                  url={our_process_image.image_path}
                                  controls={true}
                                  height="150px"
                                  width="150px"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {processDataURL &&
                          processFile?.type?.includes("video") && (
                            <div className="media-container">
                              <div className="media-main">
                                <ReactPlayer
                                  url={processDataURL}
                                  controls={true}
                                  height="150px"
                                  width="150px"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {our_process_image !== undefined &&
                          Object.keys(our_process_image).length > 0 &&
                          url !== "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={`${our_process_image.image_path}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${our_process_image.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt="Image"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {processDataURL &&
                          processFile?.type?.includes("image") && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={processDataURL}
                                  srcSet={processDataURL}
                                  alt="Banner"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <Input
                        name="file"
                        accept="image/*, video/*"
                        type="file"
                        onChange={handleMedia}
                        multiple
                        id="formFile6"
                      />
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default OurProcessSection
