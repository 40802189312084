import React, { useEffect } from "react"
import { Card, CardBody, CardTitle, Col, Row, Label, Input } from "reactstrap"

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm

function GallerySection({
  galleryImages,
  setGalleryImages,
  galleryImageFiles,
  setGalleryImageFiles,
  manageHomeData,
  setManageHomeData,
}) {
  const { home_gallery, removed_gallery_ids } = manageHomeData

  const handleMedia = e => {
    const { files } = e.target
    const validImageFiles = []
    if (e.target.files) {
      let img = e.target.files
      setManageHomeData(prevState => ({
        ...prevState,
        home_gallery: [...img, ...home_gallery],
      }))
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file)
      }
    }
    if (validImageFiles.length) {
      setGalleryImageFiles([...galleryImageFiles, ...validImageFiles])
      return
    }
    alert("Selected images are not of valid type!")
  }

  const handleRemove = (item, index) => {
    let val = document.querySelector("#gallery_images")
    val.value = null
    let fileToRemove = galleryImageFiles[index]
    const temp = galleryImages.filter((el, idx) => idx !== index)

    let temp_ = home_gallery.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })

    let temp__ = galleryImageFiles.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })

    setManageHomeData(prevState => ({
      ...prevState,
      home_gallery: [...temp_],
    }))
    setGalleryImages([...temp])
    setGalleryImageFiles([...temp__])
  }

  useEffect(() => {
    const fileReaders = []
    let isCancel = false
    if (galleryImageFiles.length) {
      const promises = galleryImageFiles.map(file => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReaders.push(fileReader)
          fileReader.onload = e => {
            const { result } = e.target
            if (result) {
              resolve(result)
            }
          }
          fileReader.onabort = () => {
            reject(new Error("File reading aborted"))
          }
          fileReader.onerror = () => {
            reject(new Error("Failed to read file"))
          }
          fileReader.readAsDataURL(file)
        })
      })
      Promise.all(promises)
        .then(images => {
          if (!isCancel) {
            setGalleryImages([...images])
          }
        })
        .catch(reason => {
          console.log(reason)
        })
    }
    return () => {
      isCancel = true
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [galleryImageFiles])

  const handleRemoveMedia = item => {
    let temp = home_gallery
    temp = temp.filter(el => el.id !== item.id)
    setManageHomeData(prevState => ({
      ...prevState,
      home_gallery: temp,
      removed_gallery_ids: [...removed_gallery_ids, item.id],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <CardTitle className="h4 py-3">Gallery Section</CardTitle>
        <Card>
          <CardBody>
            <Row>
              <Col className="col-12">
                <div className="row">
                  <Col sm={12}>
                    <div>
                      <div
                        className={
                          home_gallery !== undefined && home_gallery.length > 0
                            ? `img-container mb-5`
                            : `img-container `
                        }
                      >
                        {home_gallery !== undefined &&
                          home_gallery.length > 0 &&
                          home_gallery?.map(item => {
                            return (
                              item.image_name && (
                                <div key={item.id} className="media-container">
                                  <div className="media-main">
                                    <img
                                      className="media-img"
                                      src={`${item.image_path}`}
                                      srcSet={`${item.image_path}`}
                                      alt="Banner"
                                      loading="lazy"
                                    />

                                    <i
                                      className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                      onClick={() => handleRemoveMedia(item)}
                                    />
                                  </div>
                                </div>
                              )
                            )
                          })}

                        {galleryImages.length > 0 &&
                          galleryImages?.map((image, index) => {
                            return (
                              <div key={index} className="media-container">
                                <div className="media-main">
                                  <img
                                    className="media-img"
                                    src={image}
                                    srcSet={image}
                                    alt="Banner"
                                    loading="lazy"
                                  />

                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={() =>
                                      handleRemove(galleryImages, index)
                                    }
                                  />
                                </div>
                              </div>
                            )
                          })}
                      </div>
                      <Col sm={4}>
                        <Input
                          id="gallery_images"
                          name="file"
                          accept="image/*"
                          type="file"
                          onChange={handleMedia}
                          multiple
                        />
                        <Label className="font-size-12 text-gray">
                          (You Can Select Multiple Images)
                        </Label>
                      </Col>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default GallerySection
