import React, { useEffect } from "react"
import { Container, Label, Input } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function ManageMenuImage({
  banner_img,
  firstFile,
  setFirstFile,
  firstDataURL,
  setFirstDataURL,
  setManageMenu,
}) {
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL(result)
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleImage = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setManageMenu(prevState => ({
        ...prevState,
        banner_img: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setFirstFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#manageMenu")
    temp.value = null
    setFirstDataURL(null)
    setManageMenu(prevState => ({
      ...prevState,
      banner_img: [],
    }))
  }
  return (
    <React.Fragment>
      <Container fluid={true}>
        <Label>Image</Label>
        <div className="img-container p-2">
          {banner_img !== null && Object?.keys(banner_img)?.length > 0 && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={`${banner_img.image_path}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${banner_img.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt="Image"
                  loading="lazy"
                />
                <i
                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                  onClick={handleRemoveMedia}
                />
              </div>
            </div>
          )}

          {firstDataURL && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={firstDataURL}
                  srcSet={firstDataURL}
                  alt="Banner"
                  loading="lazy"
                />
                <i
                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                  onClick={handleRemoveMedia}
                />
              </div>
            </div>
          )}
        </div>

        <Input
          id="manageMenu"
          type="file"
          name="banner_img"
          placeholder="Choose image"
          accept="image/*"
          onChange={handleImage}
        />
      </Container>
    </React.Fragment>
  )
}

export default ManageMenuImage
