import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Row, Col, Button, Input, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component"
import axios from "../../helpers/api_helper"
import URL from "helpers/url_helper"
import { toast } from "react-toastify"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

const UserList = () => {
  document.title = "User List"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [adminList, setAdminList] = useState([])
  const [filteredAdminList, setFilteredAdminList] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => {
    setShowModal(false)
  }

  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getAdminList = async () => {
    try {
      const res = await axios.get(URL.ALL_ADMIN)
      setAdminList(res.data.users)
      setFilteredAdminList(res.data.users)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteUser = async (item)=> {
    try {
      const res = await axios.delete(URL.DELETE_ADMIN + `/${item.id}`)
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getAdminList()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    getAdminList()
  }, [])

  const columns = [
    {
      name: "S. no",
      selector: (row, index, data) => index + 1,
      width: "80px",
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: row => row.email,
    },
    {
      name: "Role",
      selector: row => row.role.name,
    },
    {
      name: "Contact Number",
      selector: row => row.phone_no,
    },
    {
      name: "Edit",
      cell: row => (
        <Button
          className="btn btn-rounded btn-warning float-end w-sm"
          onClick={() => handleEditMenu(row)}
        >
          Edit
        </Button>
      ),
    },
    {
      name: "Delete",
      cell: row => (
        <Button
          color="danger"
          className="btn btn-rounded float-end w-sm me-3"
          onClick={() => showDeleteModal(row)}
        >
          Delete
        </Button>
      ),
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = adminList.filter(item => {
      return item.name.toLowerCase().match(search.toLowerCase())
    })

    setFilteredAdminList(result)
  }, [search])

  const handleAddMenu = () => {
    history.push("/add-admin-user")
  }

  const handleEditMenu = row => {
    history.push("add-admin-user", { state: row })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>User List</ModalHeader>
        <ModalBody>
          Do you want to delete this row with user {deleteRecord.name}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteUser(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="py-4 px-2">
            <div className="draggable-body-button">
              <Col sm={11}>
                <p className="h4 card-title font-size-22">User List</p>
              </Col>
              <Col sm={1} className="mb-4">
                <Link to="/add-admin-user">
                  <Button
                    color="secondary"
                    className="btn btn-rounded btn-info float-end w-sm"
                    onClick={handleAddMenu}
                  >
                    Add User
                  </Button>
                </Link>
              </Col>
            </div>
          </Row>
          <Container fluid={true}>
            <DataTable
              columns={columns}
              data={filteredAdminList}
              fixedHeader
              pagination
              customStyles={customStyles}
              selectableRowsHighlight
              highlightOnHover
              fixedHeaderScrollHeight="500px"
              subHeader
              subHeaderComponent={
                <Col className="pt-4 data-table">
                  <i className="table-search-icon bx bx-search-alt font-size-22" />
                  <Input
                    placeholder="Search"
                    id="table-search"
                    name="search"
                    value={search}
                    onChange={handleSearch}
                  />
                </Col>
              }
              subHeaderAlign="left"
            />
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserList
