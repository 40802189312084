import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import Select from "react-select"
import Backdrop from "@mui/material/Backdrop"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import ActualSiteDataImage from "./ActualSiteDataImage"
import { toast } from "react-toastify"

import LoadingSpinner from "../../../assets/images/ball-triangle.svg"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

function ActualSiteData() {
  const { state } = useLocation()
  const history = useHistory()
  let sm = state?.state?.s_m
  document.title = state ? "Edit Actual Site Data" : "Add Actual Site Data"
  const [loading, setLoading] = useState(false)
  const [actualSiteData, setActualSiteData] = useState({
    property_id: state ? state?.state?.property?.id : "",
    vendor_id: state ? state?.state?.vendor?.id : "",
    sub_menu_id: state ? state?.state?.s_m?.id : "",
    entries: state
      ? state.state.ActualSiteImages
      : [{ id: null, image: {}, title: "" }],
    iframe_url: state ? state?.state?.iframe_url : "",
  })

  const { property_id, vendor_id, sub_menu_id, entries, iframe_url } =
    actualSiteData

  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")
  const [propertyList, setPropertyList] = useState([])
  const [propertyDropdown, setPropertyDropdown] = useState("")
  const [menuList, setMenuList] = useState([])
  const [menuDropdown, setMenuDropdown] = useState("")
  const [removedEntries, setRemovedEntries] = useState([])

  const handleAddEntries = () => {
    setActualSiteData(prevState => ({
      ...prevState,
      entries: [...entries, { id: null, image: {}, title: "" }],
    }))
  }

  const handleRemoveEntries = async (index, item) => {
    if (entries.length == 1) {
      return toast.error(
        "Minimum one site-photo is required, so we can't delete that entry"
      )
    }
    const list = [...entries]

    if (item?.id) {
      let tempList = [...removedEntries]
      tempList.push(item.id)
      tempList = [...new Set(tempList)]
      console.log("TempList", tempList)
      setRemovedEntries(tempList)
    }

    list.splice(index, 1)
    setActualSiteData(prevState => ({
      ...prevState,
      entries: list,
    }))
  }

  const getVendorParent = async () => {
    let vendorOptions = []
    try {
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let property = item.Property
        let obj = {
          id,
          name,
          property,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorParent()
  }, [])

  const getMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_ACTUAL_SITE_DATA_MENU)
      if (res.data.success) {
        setMenuList(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMenu()
  }, [])

  useEffect(() => {
    let temp = vendorList.find(el => el.id === vendor_id)
    setVendorDropdown(temp)
    setPropertyList(temp?.property)
  }, [vendor_id, vendorList?.length])

  useEffect(() => {
    let temp = propertyList?.find(el => el?.id === property_id)
    setPropertyDropdown(temp)
  }, [property_id, propertyList?.length])

  useEffect(() => {
    let temp = menuList?.find(el => el?.id === sub_menu_id)
    setMenuDropdown(temp)
  }, [sub_menu_id, menuList.length])

  const handleEntryChange = (e, index) => {
    const { name, value } = e.target
    const list = [...entries]
    list[index][name] = value
    setActualSiteData(prevState => ({
      ...prevState,
      entries: list,
    }))
  }

  const handleFrameChange = e => {
    setActualSiteData(prevState => ({
      ...prevState,
      iframe_url: e.target.value,
    }))
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const handleSave = async () => {
    setLoading(true)
    let formData = new FormData()

    {
      menuDropdown?.name === "Site Photo" &&
        Object.keys(actualSiteData).forEach(fieldName => {
          if (actualSiteData[fieldName]) {
            if (fieldName === "entries") {
              formData.append("entries", JSON.stringify(entries))
              actualSiteData[fieldName].forEach(el => {
                formData.append("image", el.image)
              })
            }
          }
        })
    }

    {
      menuDropdown?.name === "Actual Site Tour" &&
        formData.append("iframe_url", iframe_url)
      formData.append("entries", "")
      formData.append("images", "")
    }

    formData.append("property_id", propertyDropdown?.id)
    formData.append("vendor_id", vendorDropdown?.id)
    formData.append("sub_menu_id", menuDropdown?.id)

    try {
      setLoading(false)
      const res = await axios.post(URL.ADD_ACTUAL_SITE_DATA, formData)
      if (res.data.success) {
        history.push("/actual-site-data")
        toast.success(res.data.message)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdate = async () => {
    setLoading(true)
    let formData = new FormData()
    let entries_ = entries.map(el => {
      return {
        id: el.id,
        title: el.title,
        image: { name: el.image.name },
      }
    })

    menuDropdown?.name === "Site Photo" &&
      Object.keys(actualSiteData).forEach(fieldName => {
        if (actualSiteData[fieldName]) {
          if (fieldName === "entries") {
            formData.append("entries", JSON.stringify(entries_))
            actualSiteData[fieldName].forEach(el => {
              formData.append("image", el.image)
            })
          }
        }
      })

    menuDropdown?.name === "Site Photo" && formData.append("iframe_url", "")

    menuDropdown?.name === "Actual Site Tour" &&
      Object.keys(actualSiteData).forEach(fieldName => {
        if (actualSiteData[fieldName]) {
          if (fieldName === "entries") {
            formData.append("entries", "")
            formData.append("images", "")
          }
        }
      })

    menuDropdown?.name === "Actual Site Tour" &&
      formData.append("iframe_url", iframe_url)

    formData.append("property_id", propertyDropdown?.id)
    formData.append("vendor_id", vendorDropdown?.id)
    formData.append("sub_menu_id", menuDropdown?.id)

    axios
      .post(URL.DELETE_ACTUAL_SITE_DATA_CHILD, {
        ids: removedEntries,
      })
      .then(async res => {
        try {
          const res = await axios.put(
            URL.UPDATE_ACTUAL_SITE_DATA + `/${state?.state?.id}`,
            formData
          )
          if (res.data.success) {
            setLoading(false)
            toast.success(res.data.message)
            history.push("/actual-site-data")
          }
        } catch (error) {
          setLoading(false)
          toast.error(error)
          console.log(error)
          if (error.response) {
            toast.error(error.response.data.message)
          } else if (error.request) {
          } else {
            toast.error(error)
          }
        }
      })
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Actual Site Data" : "Add Actual Site Data"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  className="btn btn-info btn-rounded w-sm mb-4"
                  onClick={state ? handleUpdate : handleSave}
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label>Vendor</Label>
                    <Select
                      value={vendorDropdown}
                      options={vendorList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Vendor"
                      onChange={(event, val) => {
                        setVendorDropdown(event)
                        setPropertyList(event?.property)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Property</Label>
                    <Select
                      value={propertyDropdown}
                      options={propertyList}
                      getOptionLabel={option => `${option.property_id}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Property"
                      onChange={(event, val) => {
                        setPropertyDropdown(event)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Sub Menu</Label>
                    <Select
                      value={menuDropdown}
                      options={menuList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Type"
                      onChange={event => {
                        setMenuDropdown(event)
                      }}
                    />
                  </div>

                  {menuDropdown?.name === "Actual Site Tour" && (
                    <div className="mb-3">
                      <Label>Iframe Url</Label>
                      <Input
                        type="text"
                        name="iframe_url"
                        placeholder="Iframe Url"
                        value={iframe_url}
                        onChange={handleFrameChange}
                      />
                    </div>
                  )}

                  {menuDropdown?.name === "Site Photo" &&
                    entries.map((singleEntry, index) => {
                      console.log(singleEntry)
                      return (
                        <div key={index} className="add-children">
                          <ActualSiteDataImage
                            image={singleEntry.image}
                            index={index}
                            entries={entries}
                            setActualSiteData={setActualSiteData}
                          />

                          <div className="mb-3">
                            <Label>Title</Label>
                            <Input
                              type="text"
                              name="title"
                              placeholder="Title"
                              value={singleEntry.title}
                              onChange={e => handleEntryChange(e, index)}
                            />
                          </div>
                          <div className="mb-3">
                            {entries.length > 1 && (
                              <Button
                                color="secondary"
                                onClick={() =>
                                  handleRemoveEntries(index, singleEntry)
                                }
                                className="btn btn-rounded btn-danger w-sm me-4 mb-4"
                              >
                                Remove
                              </Button>
                            )}
                            {entries.length - 1 === index && (
                              <Button
                                color="secondary"
                                onClick={handleAddEntries}
                                className="btn btn-rounded btn-success w-sm mb-4"
                              >
                                Add
                              </Button>
                            )}
                          </div>
                        </div>
                      )
                    })}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  className="btn btn-info btn-rounded w-sm mb-4"
                  onClick={state ? handleUpdate : handleSave}
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ActualSiteData
