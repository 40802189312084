import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { Table } from "antd"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"
import { toast } from "react-toastify"
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import DragIcon from "../../assets/images/drag.png"

const type = "DragableBodyRow"

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop(
    () => ({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {}
        if (dragIndex === index) {
          return {}
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? "drop-over-downward" : "drop-over-upward",
        }
      },
      drop: item => {
        moveRow(item.index, index)
      },
    }),
    [index]
  )

  const [, drag] = useDrag(
    () => ({
      type,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index]
  )

  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    ></tr>
  )
}

const components = {
  body: {
    row: DragableBodyRow,
  },
}

const ManageTeamList = () => {
  document.title = "Manage Team List"
  const history = useHistory()
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const columns = [
    {
      key: "id",
      width: 40,
      render: () => (
        <img
          style={{
            textAlign: "center",
            height: "20px",
            width: "20px",
            objectFit: "cover",
          }}
          alt="Image"
          src={DragIcon}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      ellipsis: true,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      width: 160,
      ellipsis: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
      width: 140,
      render: imagePath => (
        <img
          style={{ height: "60px", width: "60px", objectFit: "cover" }}
          alt="Image"
          src={imagePath?.image_path}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 140,
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={() => handleUpdateStatus(record)}
            color={`${record.status === true ? `success` : `dark`} `}
          >
            {record.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div size="middle">
          <Button
            variant="outline "
            className="btn btn-info btn-rounded btn-warning w-sm me-3"
            onClick={() => handleEditTeam(record)}
          >
            Edit
          </Button>
          <Button
            color="danger"
            variant="outline "
            className="btn btn-rounded  w-sm"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ]

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    },
    [data]
  )

  const getManageTeam = async () => {
    try {
      const res = await axios.get(URL?.ALL_MANAGE_TEAM)
      if (res?.data?.success) {
        setData(res?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const temp = data?.map((item, idx) => {
    return { name: item?.name, id: item?.id, sort_order: idx + 1 }
  })

  useEffect(() => {
    getManageTeam()
  }, [])

  useEffect(() => {
    saveSortOrder()
  }, [data])

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(
        URL.UPDATE_MANAGE_TEAM_STATUS + `${item.id}`,
        {
          status: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getManageTeam()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleDelete = async item => {
    try {
      const res = await axios.delete(URL.DELETE_MANAGE_TEAM + `${item.id}`)
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getManageTeam()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const saveSortOrder = async () => {
    try {
      const res = await axios.put(URL?.MANAGE_TEAM_SORT_ORDER, {
        new_order: temp,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleNavigate = () => {
    history.push("/add-team")
  }

  const handleEditTeam = record => {
    history.push("/add-team", { state: record })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Our Team</ModalHeader>
        <ModalBody>
          Do you want to delete the row with name {deleteRecord.name}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDelete(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="py-4 draggable-body-button">
              <p className="h4 card-title font-size-22">Our Team</p>
              <Button
                className="btn btn-rounded btn-info float-end w-sm"
                onClick={handleNavigate}
              >
                Add
              </Button>
            </div>

            <DndProvider backend={HTML5Backend}>
              <Table
                key="team-list"
                columns={columns}
                dataSource={data}
                components={components}
                onRow={(record, index) => ({
                  index,
                  moveRow,
                })}
                scroll={{ x: "max-content" }}
              />
            </DndProvider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageTeamList
