import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import Select from "react-select"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import {
  CardBody,
  Input,
  Container,
  Col,
  Button,
  Card,
  Row,
  Label,
  CardTitle,
} from "reactstrap"

function CalculatorGroup() {
  document.title = "Add Calculator Group"
  const history = useHistory()
  const { state } = useLocation()

  const [isDisabled, setIsDisabled] = useState(true)
  const [calculatorGroup, setCalculatorGroup] = useState({
    name: state ? state?.state?.name : "",
    layout_name: state ? state?.state?.layout?.name : "",
  })

  const [dropdownValue, setDropdownValue] = useState("")
  const [calculatorDropdown, setCalculatorDropdown] = useState([])

  const { name, layout_name } = calculatorGroup

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setCalculatorGroup(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    if (name.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [name])

  const addCalculatorGroup = async () => {
    try {
      const res = await axios.post(URL.ADD_CALCULATOR_GROUP, {
        name: name,
        layout_id: dropdownValue.id,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-calculator-group")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateCalculatorGroup = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_CALCULATOR_GROUP + `/${state.state.id}`,
        {
          name: name,
          layout_id: dropdownValue.id,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-calculator-group")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const getCalculatorLayout = async () => {
    try {
      const res = await axios.get(URL.ALL_CALCULATOR_LAYOUT)
      if (res.data.success) {
        setCalculatorDropdown(res.data.layouts)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCalculatorLayout()
  }, [])

  useEffect(() => {
    let temp = calculatorDropdown?.find(el => el.name === layout_name)
    setDropdownValue(temp)
  }, [layout_name, calculatorDropdown?.length])

  console.log("dropdown", dropdownValue)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Calculator Group" : "Add Calculator Group"}
              </CardTitle>

              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateCalculatorGroup : addCalculatorGroup}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Col component="form" autoComplete="off">
                <div className="mb-3">
                  <Label>Select Calculator Layout</Label>
                  <Select
                    value={dropdownValue}
                    options={calculatorDropdown}
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => `${option.id}`}
                    onChange={(event, val) => {
                      setDropdownValue(event)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label>Name</Label>

                  <Input
                    name="name"
                    value={name}
                    onChange={onChange}
                    id="outlined-basic"
                    placeholder="Name"
                  />
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CalculatorGroup
