import React, { useState } from "react"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory, useLocation } from "react-router-dom"
import {
  Container,
  Input,
  Col,
  Button,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

function VendorMenu() {
  const history = useHistory()
  const { state } = useLocation()
  const [vendor, setVendor] = useState({
    name: state ? state?.state?.name : "",
  })

  const { name } = vendor

  const onChange = e => {
    if(e.target.value.length > 35) {
      return;
    }
    setVendor(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const updateVendor = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_VENDOR_MENU + `/${state.state.id}`,
        {
          name: name,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/vendor-menu")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col sm={12} className="d-flex justify-content-between align-items-center">
        <CardTitle className="h4 card-title font-size-22">{state && "Edit Vendor Menu"}</CardTitle>
           
          <div className="d-flex flex-wrap gap-2 float-end">
              <Button
                onClick={state && updateVendor}
                className="btn btn-info btn-rounded float-end w-sm"
              >
                Save
              </Button>
           
              <Button
                color="secondary"
                className="btn btn-rounded float-end w-sm"
                onClick={handleGoBack}
              >
                Back
              </Button>
              </div>
            </Col>
          </Row>

          <Col sm={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label>Vendor</Label>
                      <Input
                        name="name"
                        value={name}
                        onChange={onChange}
                        id="outlined-basic"
                        label="Menu"
                        variant="outlined"
                        placeholder="Name"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorMenu
