import React, { useState, useEffect } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import {
  CardBody,
  Input,
  Col,
  Button,
  Card,
  Row,
  Container,
  Label,
  CardTitle,
} from "reactstrap"
import Select from "react-select"

function KYCType() {
  document.title = "Add KYC Type"
  const history = useHistory()
  const { state } = useLocation()

  const [kycType, setKycType] = useState({
    title: state ? state.state.title : "",
    proof_type: state ? state.state.proof_type : "",
  })

  const [proofType, setProofType] = useState("")

  const { title, proof_type } = kycType
  const [isDisabled, setIsDisabled] = useState(true)
  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setKycType(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addKYCType = async () => {
    try {
      const res = await axios.post(URL.ADD_KYC_TYPE, {
        title: title,
        proof_type: proofType.val,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/kyc-type")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateKYCType = async () => {
    try {
      const res = await axios.put(URL.UPDATE_KYC_TYPE + `/${state.state.id}`, {
        title: title,
        proof_type: proofType.val,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/kyc-type")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const proofTypeDropdown = [
    {
      id: 1,
      name: "Address Proof",
      val: "Address_Proof",
    },
    {
      id: 2,
      name: "Identity Proof",
      val: "Identity_Proof",
    },
    {
      id: 3,
      name: "Both",
      val: "Both",
    },
  ]

  useEffect(() => {
    let temp = proofTypeDropdown.find(el => el.val === proof_type)
    setProofType(temp)
  }, [proof_type])

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (proof_type !== undefined && title.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [title, proof_type])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit KYC Type" : "Add KYC Type"}
              </CardTitle>

              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateKYCType : addKYCType}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Col component="form" noValidate autoComplete="off">
                <div className="mb-3">
                  <Label>User Role</Label>
                  <Select
                    disablePortal
                    id="combo-box-demo"
                    value={proofType}
                    options={proofTypeDropdown}
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => `${option.id}`}
                    onChange={event => {
                      setProofType(event)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label>Title</Label>
                  <Input
                    name="title"
                    value={title}
                    onChange={onChange}
                    id="outlined-basic"
                    placeholder="Status"
                  />
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default KYCType
