import React, { useEffect, useState } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { toast } from "react-toastify"

function Tour() {
  const { state } = useLocation()
  const history = useHistory()
  document.title = state ? "Edit 3D Tour" : "Add 3D Tour"

  const [tour, setTour] = useState({
    property_id: state ? state.state.property.id : "",
    vendor_id: state ? state.state.vendor.id : "",
    sub_sub_menu_id: state ? state.state.sub_sub_menu.id : "",
    entries: state ? state.state.ThreeDTourChild : [{ url: "" }],
  })

  const { property_id, vendor_id, sub_sub_menu_id, entries } = tour

  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")
  const [propertyList, setPropertyList] = useState([])
  const [propertyDropdown, setPropertyDropdown] = useState("")

  const [menuList, setMenuList] = useState([])
  const [menuDropdown, setMenuDropdown] = useState("")

  const getVendorParent = async () => {
    let vendorOptions = []
    try {
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let property = item.Property
        let obj = {
          id,
          name,
          property,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorParent()
  }, [])

  const getMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_TOUR_PROPERTY_SUB_MENU)
      if (res.data.success) {
        console.log(res)
        setMenuList(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMenu()
  }, [])

  useEffect(() => {
    let temp = vendorList.find(el => el.id === vendor_id)
    setVendorDropdown(temp)
    setPropertyList(temp?.property)
  }, [vendor_id, vendorList?.length])

  useEffect(() => {
    let temp = propertyList?.find(el => el?.id === property_id)
    setPropertyDropdown(temp)
  }, [property_id, propertyList?.length])

  useEffect(() => {
    let temp = menuList?.find(el => el?.id === sub_sub_menu_id)
    setMenuDropdown(temp)
  }, [sub_sub_menu_id, menuList.length])

  const handleEntryChange = (e, index) => {
    const { name, value } = e.target
    const list = [...entries]
    list[index][name] = value
    setTour(prevState => ({
      ...prevState,
      entries: list,
    }))
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const handleSaveTour = async () => {
    let temp_
    let formData = new FormData()
    let entries_ = entries.map(el => el.url)
    temp_ = entries_[0]
    Object.keys(tour).forEach(fieldName => {
      if (tour[fieldName]) {
        if (fieldName === "entries") {
          formData.append("url", temp_)
        }
      }
    })

    formData.append("property_id", propertyDropdown?.id)
    formData.append("vendor_id", vendorDropdown?.id)
    formData.append("type", menuDropdown?.id)

    try {
      const res = await axios.post(URL.ADD_TOUR_PROPERTY, formData)
      if (res.data.success) {
        history.push("/3d-tour")
        toast.success(res.data.message)
      }
    } catch (error) {
      toast.error(error)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdateTour = async () => {
    let temp_
    let formData = new FormData()
    let entries_ = entries.map(el => el.url)
    temp_ = entries_[0]

    Object.keys(tour).forEach(fieldName => {
      if (tour[fieldName]) {
        if (fieldName === "entries") {
          formData.append("url", temp_)
        }
      }
    })

    formData.append("property_id", propertyDropdown?.id)
    formData.append("vendor_id", vendorDropdown?.id)
    formData.append("type", menuDropdown?.id)

    try {
      const res = await axios.put(
        URL.UPDATE_TOUR_PROPERTY + `/${state?.state?.id}`,
        formData
      )
      if (res.data.success) {
        history.push("/3d-tour")
        toast.success(res.data.message)
      }
    } catch (error) {
      toast.error(error)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit 3D Tour" : "Add 3D Tour"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  className="btn btn-info btn-rounded w-sm mb-4"
                  onClick={state ? handleUpdateTour : handleSaveTour}
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label>Vendor</Label>
                    <Select
                      value={vendorDropdown}
                      options={vendorList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Vendor"
                      onChange={(event, val) => {
                        setVendorDropdown(event)
                        setPropertyList(event?.property)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Property</Label>
                    <Select
                      value={propertyDropdown}
                      options={propertyList}
                      getOptionLabel={option => `${option.property_id}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Property"
                      onChange={(event, val) => {
                        setPropertyDropdown(event)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Type</Label>
                    <Select
                      value={menuDropdown}
                      options={menuList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Type"
                      onChange={event => {
                        setMenuDropdown(event)
                      }}
                    />
                  </div>

                  {entries.map((singleEntry, index) => {
                    return (
                      <div key={index} className="add-children">
                        <div className="mb-3">
                          <Label>3D URL</Label>
                          <Input
                            type="text"
                            name="url"
                            placeholder="URL"
                            value={singleEntry.url}
                            onChange={e => handleEntryChange(e, index)}
                          />
                        </div>
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>

            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  className="btn btn-info btn-rounded w-sm mb-4"
                  onClick={state ? handleUpdateTour : handleSaveTour}
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Tour
