import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import DataTable from "react-data-table-component"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Input, Button, Container, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function AmenitiesList() {
  document.title = "Amenities List"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [amenitiesData, setAmenitiesData] = useState([])
  const [filteredAmenitiesData, setFilteredAmenitiesData] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const columns = [
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Status",
      cell: row => (
        <div>
          <Button
            variant="outline"
            color={`${row.status === true ? `success` : `dark`} `}
            className="btn btn-rounded float-end w-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            {row.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      name: "Action",
      cell: row => (
        <Col
          sm={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex gap-2 float-end">
            <Button
              className="btn btn-rounded btn-warning  w-sm"
              onClick={() => handleEditAminities(row)}
            >
              Edit
            </Button>
            <Button
              color="danger"
              className="btn btn-rounded  w-sm"
              onClick={() => showDeleteModal(row)}
            >
              Delete
            </Button>
          </div>
        </Col>
      ),
    },
  ]

  const getAminitiesData = async () => {
    try {
      const res = await axios.get(URL.ALL_AMENITIES)
      if (res.data.success) {
        console.log(res)
        setAmenitiesData(res.data.amenities)
        setFilteredAmenitiesData(res.data.amenities)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAminitiesData()
  }, [])

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(URL.UPDATE_AMENITIES_STATUS + `/${item.id}`, {
        status: !sendStatus,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        getAminitiesData()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleDeleteAminities = async item => {
    try {
      const res = await axios.delete(URL.DELETE_AMENITIES, {
        data: {
          entryIds: [item.id],
        },
      })
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getAminitiesData()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = amenitiesData.filter(item => {
      return item.name.toLowerCase().match(search.toLowerCase())
    })

    setFilteredAmenitiesData(result)
  }, [search])

  const handleAddAminities = row => {
    history.push("/add-amenities")
  }

  const handleEditAminities = row => {
    history.push("/add-amenities", { state: row })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>All Amenities</ModalHeader>
        <ModalBody>
          Do you want to delete the row with name {deleteRecord.name}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteAminities(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Row className="py-4 px-2">
          <div className="draggable-body-button">
            <Col sm={10}>
              <p className="h4 card-title font-size-22">All Amenities</p>
            </Col>
            <Col sm={1}>
              <Button
                onClick={handleAddAminities}
                color="secondary"
                className="btn btn-rounded btn-info float-end w-sm"
              >
                Add
              </Button>
            </Col>
          </div>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredAmenitiesData}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  id="table-search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AmenitiesList
