import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import FloorOriginal from "./FloorOriginal"
import FloorModified from "./FloorModified"

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function FloorPlanSection({
  commercialData,
  setCommercialData,
  floorPlanFile1,
  setFloorPlanFile1,
  floorPlanFile2,
  setFloorPlanFile2,
  floorPlanDataURL1,
  floorPlanDataURL2,
  setFloorPlanDataURL1,
  setFloorPlanDataURL2,
}) {
  const { floor_plan_head, floor_plan_desc } = commercialData

  const handleChange = e => {
    setCommercialData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-3">Floor Plan Section</CardTitle>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="first_section_heading"
                onChange={handleChange}
                value={floor_plan_head || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Form method="post">
                      <CKEditor
                        editor={ClassicEditor}
                        data={floor_plan_desc}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setCommercialData(prevState => ({
                            ...prevState,
                            floor_plan_desc: data,
                          }))
                        }}
                      />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <FloorOriginal
              commercialData={commercialData}
              setCommercialData={setCommercialData}
              floorPlanFile1={floorPlanFile1}
              setFloorPlanFile1={setFloorPlanFile1}
              floorPlanDataURL1={floorPlanDataURL1}
              setFloorPlanDataURL1={setFloorPlanDataURL1}
            />

            <FloorModified
              commercialData={commercialData}
              setCommercialData={setCommercialData}
              floorPlanFile2={floorPlanFile2}
              setFloorPlanFile2={setFloorPlanFile2}
              floorPlanDataURL2={floorPlanDataURL2}
              setFloorPlanDataURL2={setFloorPlanDataURL2}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default FloorPlanSection
