import React, { useEffect } from "react"
import { Container, Input, CardTitle } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function VendorProfile({
  profile,
  firstFile,
  setFirstFile,
  firstDataURL,
  setFirstDataURL,
  setVendor,
}) {
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL(result)
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleLogo = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setVendor(prevState => ({
        ...prevState,
        profile: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setFirstFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formImage")
    temp.value = null
    setFirstDataURL(null)
    setVendor(prevState => ({
      ...prevState,
      profile: [],
    }))
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <CardTitle>Image</CardTitle>

        <div className="img-container p-2">
          {Object.keys(profile).length > 0 && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={`${profile}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${profile}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt="Image"
                  loading="lazy"
                />
                <div>
                  <i
                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                    onClick={handleRemoveMedia}
                  />
                </div>
              </div>
            </div>
          )}

          {firstDataURL && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={firstDataURL}
                  srcSet={firstDataURL}
                  alt="Banner"
                  loading="lazy"
                />
                <div>
                  <i
                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                    onClick={handleRemoveMedia}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <Input
          name="file"
          accept="image/*, video/*"
          type="file"
          onChange={handleLogo}
          id="formImage"
        />
      </Container>
    </React.Fragment>
  )
}

export default VendorProfile
