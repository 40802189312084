import React, { useState, useEffect } from "react"
import Backdrop from "@mui/material/Backdrop"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Container,
} from "reactstrap"
import Select from "react-select"
import axios from "../../../helpers/api_helper"
import URL from "../../../helpers/url_helper"
import { Button } from "reactstrap"
import { toast } from "react-toastify"
import { useHistory, useLocation } from "react-router-dom"
import AllPropertyImage from "./AllPropertyImage"
import GoogleMaps from "../Maps/GoogleMaps"

import LoadingSpinner from "../../../assets/images/ball-triangle.svg"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

function AllProperty(props) {
  const { state } = useLocation()
  document.title = state ? "Edit Property" : "Add Property"
  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [addProperty, setAddProperty] = useState({
    vendor_id: state ? state.state.vendor_id : "",
    property_name: "",
    property_id: state ? state.state.property_id : "",
    visibility: state ? state.state.visibility : "",
    property_category: "",
    property_category: state ? state.state.property_category_id : "",
    property_type: state ? state.state.property_type_id : "",
    property_name: state ? state.state.property_name : "",
    address: state ? state.state.address : "",
    state: state ? state.state.state : "",
    // sublocality: state ? state.state.sublocality : "",
    latitude: state ? state.state.latitude : 18.5204,
    longitude: state ? state.state.longitude : 73.8567,
    beds: state ? state.state.beds : "",
    bath: state ? state.state.bath : "",
    area: state ? state.state.area : "",
    feature_image: state ? state.state.feature_image : [],
  })

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  const {
    vendor_id,
    property_id,
    // category_id,
    property_category,
    property_type,
    property_name,
    visibility,
    // location,
    // sublocality,
    // stateP,
    latitude,
    longitude,
    beds,
    bath,
    area,
    feature_image,
    address,
  } = addProperty

  const history = useHistory()
  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")
  const [categoryList, setCategoryList] = useState([])
  const [categoryDropdown, setCategoryDropdown] = useState("")
  const [propertyType, setPropertyType] = useState([])
  const [propertyDropdown, setPropertyDropdown] = useState("")
  const [visibiltyDropdown, setVisibilityDropdown] = useState("")

  const visibilityOptions = [
    {
      id: 1,
      name: "Public",
    },
    {
      id: 2,
      name: "Private",
    },
  ]

  const getVendorParent = async () => {
    let vendorOptions = []
    try {
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let obj = {
          id,
          name,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorParent()
  }, [])

  const handleChange = e => {
    setAddProperty(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const getPropertyCategory = async () => {
    try {
      const res = await axios.get(URL.ALL_PROPERTY_CATEGORY)
      if (res.data.success) {
        setCategoryList(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPropertyCategory()
  }, [])

  const getPropertyType = async () => {
    try {
      const res = await axios.get(URL.ALL_PROPERTY_TYPE)
      if (res.data.success) {
        setPropertyType(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPropertyType()
  }, [])

  useEffect(() => {
    let temp = vendorList?.find(el => el?.id === vendor_id)
    setVendorDropdown(temp)
  }, [vendor_id, vendorList?.length])

  useEffect(() => {
    let temp = categoryList?.find(el => el?.id === property_category)
    setCategoryDropdown(temp)
  }, [property_category, categoryList?.length])

  useEffect(() => {
    let temp = propertyType?.find(el => el?.id === property_type)
    setPropertyDropdown(temp)
  }, [property_type, propertyType?.length])

  useEffect(() => {
    let temp = visibilityOptions?.find(el => el?.name === visibility)
    setVisibilityDropdown(temp)
  }, [visibility])

  const handleSave = async () => {
    setLoading(true)
    let formData = new FormData()
    Object.keys(addProperty).forEach(fieldName => {
      if (addProperty[fieldName]) {
        formData.append(fieldName, addProperty[fieldName])
      }
    })

    formData.append("vendor_id", vendorDropdown?.id)
    formData.append("property_category", categoryDropdown?.id)
    formData.append("property_type", propertyDropdown?.id)
    formData.append("visibility", visibiltyDropdown?.name)

    try {
      setLoading(false)
      const res = await axios.post(URL.ADD_PROPERTY, formData)
      if (res.data.success) {
        history.push("/property")
        toast.success(res.data.message)
      }
    } catch (error) {
      toast.error(error)
      setLoading(false)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdate = async () => {
    setLoading(true)
    let formData = new FormData()
    Object.keys(addProperty).forEach(fieldName => {
      if (addProperty[fieldName]) {
        if (fieldName === "vendor_id") {
          formData.append("vendor_id", vendorDropdown?.id)
        } else if (fieldName === "property_name") {
          formData.append("property_name", property_name)
        } else if (fieldName === "visibility") {
          formData.append("visibility", visibiltyDropdown?.name)
        } else if (fieldName === "property_category") {
          formData.append("property_category", categoryDropdown?.id)
        } else {
          formData.append(fieldName, addProperty[fieldName])
        }
      }
    })

    try {
      const res = await axios.put(
        URL.UPDATE_PROPERTY + `/${state.state.id}`,
        formData
      )
      if (res.data.success) {
        setLoading(false)
        toast.success(res.data.message)
        history.push("/property")
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    if (
      vendorDropdown !== "" &&
      property_id.length > 0 &&
      categoryDropdown !== "" &&
      propertyDropdown !== "" &&
      property_name.length > 0 &&
      // location.length > 0
      // sublocality.length > 0 &&
      // state?.length > 0
      // latitude > 0 &&
      // longitude > 0 &&
      // beds > 0 &&
      // bath > 0 &&
      area.length > 0 &&
      feature_image !== undefined
      // address.length > 0
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [
    vendorDropdown,
    property_id,
    categoryDropdown,
    propertyDropdown,
    property_name,
    // location,
    state,
    latitude,
    longitude,
    beds,
    bath,
    area,
    feature_image,
    address,
  ])

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Property" : "Add Property"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  className={
                    isDisabled
                      ? "btn btn-rounded w-sm mb-4 float-end"
                      : "btn btn-rounded btn-info float-end w-sm mb-4"
                  }
                  onClick={state ? handleUpdate : handleSave}
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>

            <Col sm={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Vendor</Label>
                        <Select
                          value={vendorDropdown}
                          options={vendorList}
                          getOptionLabel={option => `${option.name}`}
                          getOptionValue={option => `${option.id}`}
                          placeholder="Select Vendor"
                          onChange={event => {
                            console.log("evt", event)

                            setVendorDropdown(event)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-propertyID-Input">
                          Property ID
                        </Label>
                        <Input
                          type="text"
                          name="property_id"
                          className="form-control"
                          id="formrow-propertyID-Input"
                          placeholder="Property ID"
                          onChange={handleChange}
                          value={property_id || ""}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Property Category</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          value={categoryDropdown}
                          options={categoryList}
                          getOptionLabel={option => `${option.title}`}
                          getOptionValue={option => `${option.id}`}
                          placeholder="Select Property Category"
                          onChange={event => {
                            console.log("evt", event)

                            setCategoryDropdown(event)
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Property Type</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          value={propertyDropdown}
                          options={propertyType}
                          getOptionLabel={option => `${option.title}`}
                          getOptionValue={option => `${option.id}`}
                          placeholder="Select Property Type"
                          onChange={event => {
                            console.log("evt", event)
                            setPropertyDropdown(event)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-propertyID-Input">
                          Property Name
                        </Label>
                        <Input
                          name="property_name"
                          className="form-control"
                          id="formrow-propertyID-Input"
                          placeholder="Property Name"
                          onChange={handleChange}
                          value={property_name || ""}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Location/Address</Label>

                        <Input
                          name="address"
                          className="form-control"
                          id="formrow-location-Input"
                          placeholder="Location/Address"
                          onChange={handleChange}
                          value={address || ""}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-propertyID-Input">
                          Visibility
                        </Label>
                        <Select
                          classNamePrefix="select2-selection"
                          value={visibiltyDropdown}
                          options={visibilityOptions}
                          getOptionLabel={option => `${option.name}`}
                          getOptionValue={option => `${option.id}`}
                          placeholder="Select Visibilty"
                          onChange={event => {
                            setVisibilityDropdown(event)
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <GoogleMaps
                          property={addProperty}
                          setAddProperty={d => setAddProperty(d)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className="h-3">
                        <Label>Configuration</Label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label>Beds</Label>
                        <Input
                          type="number"
                          name="beds"
                          className="form-control"
                          id="formrow-beds-Input"
                          placeholder="Beds"
                          onChange={handleChange}
                          value={beds || ""}
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label>Bath</Label>
                        <Input
                          type="text"
                          name="bath"
                          className="form-control"
                          id="formrow-bath-Input"
                          placeholder="Bath"
                          onChange={handleChange}
                          value={bath || ""}
                        />
                      </div>
                    </Col> */}
                    <Col md={4}>
                      <div className="mb-3">
                        <Label>Area</Label>
                        <Input
                          type="text"
                          name="area"
                          className="form-control"
                          id="formrow-area-Input"
                          placeholder="Area"
                          onChange={handleChange}
                          value={area || ""}
                        />
                      </div>
                    </Col>
                  </Row>
                  <AllPropertyImage
                    image={feature_image}
                    firstFile={firstFile}
                    setFirstFile={setFirstFile}
                    firstDataURL={firstDataURL}
                    setFirstDataURL={setFirstDataURL}
                    setAddProperty={setAddProperty}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <Button
                disabled={isDisabled}
                className={
                  isDisabled
                    ? "btn btn-rounded w-sm mb-4 float-end"
                    : "btn btn-rounded btn-info float-end w-sm mb-4"
                }
                onClick={state ? handleUpdate : handleSave}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllProperty
