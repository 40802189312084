import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory, useLocation, Link } from "react-router-dom"
import {
  CardBody,
  CardTitle,
  Container,
  Input,
  Button,
  Col,
  Card,
  Row,
  Label,
} from "reactstrap"

function PropertyType() {
  document.title = "Add Property Type"
  const history = useHistory()
  const { state } = useLocation()
  const [property, setProperty] = useState({
    title: state ? state?.state?.title : "",
  })

  const { title } = property
  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setProperty(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addProperty = async () => {
    try {
      const res = await axios.post(URL.ADD_PROPERTY_TYPE, {
        title: title,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/property-type")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateProperty = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_PROPERTY_TYPE + `/${state?.state?.id}`,
        {
          title: title,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/property-type")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (title.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [title])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Property Type" : "Add Property Type"}
              </CardTitle>

              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                  onClick={state ? updateProperty : addProperty}
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Col component="form" noValidate autoComplete="off">
                <Label>Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={onChange}
                  id="outlined-basic"
                  label="Title"
                  placeholder="Title"
                />
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PropertyType
