import React, { useEffect } from "react"
import { Label, Card, Input } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function TestimonialImage({
  image,
  secondFile,
  setSecondFile,
  secondDataURL,
  setSecondDataURL,
  setTestimonial,
}) {
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (secondFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setSecondDataURL(result)
        }
      }
      fileReader.readAsDataURL(secondFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [secondFile])

  const handleLogo = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setTestimonial(prevState => ({
        ...prevState,
        image: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setSecondFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#image")
    temp.value = null
    setSecondDataURL(null)
    setTestimonial(prevState => ({
      ...prevState,
      image: [],
    }))
  }
  return (
    <Card>
      <Label>Image</Label>
      <div className="img-container">
        {Object.keys(image).length > 0 && (
          <div className="media-container">
            <div className="media-main">
              <img
                className="media-img"
                src={`${image.image_path}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${image.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt="Image"
                loading="lazy"
              />
              <div>
                <i
                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                  onClick={handleRemoveMedia}
                />
              </div>
            </div>
          </div>
        )}

        {secondDataURL && (
          <div className="media-container">
            <div className="media-main">
              <img
                className="media-img"
                src={secondDataURL}
                srcSet={secondDataURL}
                alt="Banner"
                loading="lazy"
              />
              <div>
                <i
                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                  onClick={handleRemoveMedia}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <Input
        id="image"
        type="file"
        name="image"
        placeholder="Choose image"
        accept="image/*"
        onChange={handleLogo}
      />
    </Card>
  )
}

export default TestimonialImage
