import React, { useEffect } from "react"

import { Card, CardBody, CardTitle, Col, Row, Label, Input } from "reactstrap"

function ClientelSection({
  manageHomeData,
  setManageHomeData,
  clientelFile,
  setClientelFile,
  clientelDataURL,
  setClientelDataURL,
}) {
  const { clientele_heading, partner_section_image } = manageHomeData

  const handleChange = e => {
    setManageHomeData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let url = partner_section_image.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setManageHomeData(prevState => ({
        ...prevState,
        partner_section_image: img,
      }))
    }
    const file = e.target.files[0]
    setClientelFile(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (clientelFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setClientelDataURL(result)
        }
      }
      fileReader.readAsDataURL(clientelFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [clientelFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile3")
    temp.value = null
    setClientelDataURL(null)
    setManageHomeData(prevState => ({
      ...prevState,
      partner_section_image: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <CardTitle className="h4 py-3">Clientele Section</CardTitle>
        <Card>
          <CardBody>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="clientele_heading"
                onChange={handleChange}
                value={clientele_heading || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Clientele"
              />
            </div>

            <Row>
              <Label>Upload Image</Label>
              <Col className="col-12">
                <div className="row">
                  <Col sm={12}>
                    <div>
                      <div
                        className={
                          partner_section_image !== undefined &&
                          Object.keys(partner_section_image).length > 0
                            ? `img-container  mb-5`
                            : `img-container `
                        }
                      >
                        {partner_section_image !== undefined &&
                          Object.keys(partner_section_image).length > 0 &&
                          url !== "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={`${partner_section_image.image_path}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${partner_section_image.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt="Image"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {clientelDataURL &&
                          clientelFile?.type?.includes("image") && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={clientelDataURL}
                                  srcSet={clientelDataURL}
                                  alt="Banner"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <Col sm={4}>
                        <Input
                          id="formFile3"
                          name="file"
                          accept="image/*"
                          type="file"
                          onChange={handleMedia}
                          multiple
                        />
                      </Col>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ClientelSection
