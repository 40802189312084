import React from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

const PropertyTypeTable = ({ data }) => {
  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle>Property Type Data</CardTitle>
            <CardSubtitle className="mb-3">
              The property type data updated here will be refelected in
              viztown.in
            </CardSubtitle>

            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>Property Type</th>
                    <th>Standard</th>
                    <th>Premium</th>
                    <th>Luxury</th>
                  </tr>
                </thead>
                {data?.map((item, index) => {
                  let id = index + 1
                  return (
                    <tbody key={index}>
                      <tr>
                        <th scope="row">{id}</th>
                        <td>{item?.Property}</td>
                        <td>₹{item?.Standard}</td>
                        <td>₹{item?.Premium}</td>
                        <td>₹{item?.Luxury}</td>
                      </tr>
                    </tbody>
                  )
                })}
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PropertyTypeTable
