import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  CardBody,
  CardTitle,
  Container,
  Card,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { toast } from "react-toastify"

function AddSocialLinks() {
  const history = useHistory()
  const { state } = useLocation()
  const [linkData, setLinkData] = useState({
    class_name: state ? state?.state?.class_name : "",
    link: state ? state?.state?.link : "",
  })

  document.title = state ? "Edit Social Link" : "Add Social Link"
  const { class_name, link } = linkData
  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    // if(e.target.value.length > 35) {
    //   return;
    // }
    setLinkData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const addSocialLink = async () => {
    try {
      const res = await axios.post(URL.ADD_SOCIAL_LINK, {
        class_name: class_name,
        link: link,
      })
      if (res.data.success) {
        history.push("/all-social-links")
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateSocialLink = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_SOCIAL_LINK + `/${state?.state?.id}`,
        {
          class_name: class_name,
          link: link,
        }
      )
      if (res.data.success) {
        history.push("/all-social-links")
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    if (class_name.length > 0 && link.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [class_name, link])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Social Link" : "Add Social Link"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateSocialLink : addSocialLink}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  className="btn btn-rounded w-sm mb-4"
                  color="secondary"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="mb-3">
                <Label>Class Name</Label>
                <Input
                  name="class_name"
                  value={class_name}
                  className="form-control"
                  id="formrow-Class-Name-Input"
                  placeholder="Class Name(e.g fab fa-linkedin-in etc)"
                  onChange={onChange}
                />
              </div>
              <div className="mb-3">
                <Label>Link</Label>
                <Input
                  name="link"
                  value={link}
                  className="form-control"
                  id="formrow-link-Input"
                  placeholder="Link"
                  onChange={onChange}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddSocialLinks
