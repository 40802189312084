import React, { useEffect } from "react"
import { useState } from "react"
import Backdrop from "@mui/material/Backdrop"
import { Col, Container, Row, Button, CardTitle } from "reactstrap"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"

import BannerSection from "components/Commercial/Commercial/BannerSection"
import FirstSection from "components/Commercial/Commercial/FirstSection"
import SecondSection from "components/Commercial/Commercial/SecondSection"
import ThirdSection from "components/Commercial/Commercial/ThirdSection"
import GallerySection from "components/Commercial/Commercial/GallerySection"
import FloorPlanSection from "components/Commercial/Commercial/FloorPlanSection"
import VirtualTourSection from "components/Commercial/Commercial/VirtualTourSection"
import OurProcessSection from "components/Commercial/Commercial/OurProcessSection"
import { getCommercialData, postCommercialData } from "helpers/commercialData"

const CommercialSection = () => {
  document.title = "Commercial"
  const [loading, setLoading] = useState(false)
  const [bannerFile, setBannerFile] = useState(null)
  const [bannerDataURL, setBannerDataURL] = useState(null)
  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)
  const [secondFile, setSecondFile] = useState(null)
  const [secondDataURL, setSecondDataURL] = useState(null)
  const [thirdFile, setThirdFile] = useState(null)
  const [thirdDataURL, setThirdDataURL] = useState(null)
  const [galleryImages, setGalleryImages] = useState([])
  const [galleryImageFiles, setGalleryImageFiles] = useState([])
  const [floorPlanFile1, setFloorPlanFile1] = useState(null)
  const [floorPlanDataURL1, setFloorPlanDataURL1] = useState(null)
  const [floorPlanFile2, setFloorPlanFile2] = useState(null)
  const [floorPlanDataURL2, setFloorPlanDataURL2] = useState(null)
  const [virtualFloorFile, setVirtualFloorFile] = useState(null)
  const [virtualFloorDataURL, setVirtualFloorDataURL] = useState(null)
  const [processFile, setProcessFile] = useState(null)
  const [processDataURL, setProcessDataURL] = useState(null)

  const [commercialData, setCommercialData] = useState({
    banner_heading: "",
    banner_subheading: "",
    banner_img: {},
    first_section_heading: "",
    first_section_desc: "",
    first_section_file: {},
    second_section_heading: "",
    second_section_desc: "",
    second_section_file: {},
    third_section_heading: "",
    third_section_desc: "",
    third_section_file: {},
    gallery_head: "",
    gallery_desc: "",
    commercial_gallery: [],
    floor_plan_head: "",
    floor_plan_desc: "",
    floor_modified_image: {},
    floor_original_image: {},
    virtual_tour_head: "",
    virtual_tour_desc: "",
    virtual_tour_file: {},
    removed_gallery_ids: [],
    our_process_head: "",
    our_process_subhead: "",
    our_process_image: {},
  })

  useEffect(() => {
    getCommercialData(commercialData, setCommercialData, setLoading)
  }, [])

  const onSubmit = () => {
    setBannerFile(null)
    setBannerDataURL(null)
    setFirstDataURL(null)
    setSecondDataURL(null)
    setThirdDataURL(null)
    setGalleryImages([])
    setGalleryImageFiles([])
    setFloorPlanDataURL1(null)
    setFloorPlanDataURL2(null)
    setVirtualFloorDataURL(null)
    postCommercialData(commercialData, setCommercialData, setLoading)
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div
            style={{
              zIndex: 999999999,
              fontSize: "5rem",
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                Edit Commercial Page
              </CardTitle>

              <Button
                onClick={onSubmit}
                className="btn btn-rounded btn-info float-end w-sm mb-4"
              >
                Save Changes
              </Button>
            </Col>
          </Row>

          <BannerSection
            bannerFile={bannerFile}
            setBannerFile={setBannerFile}
            bannerDataURL={bannerDataURL}
            setBannerDataURL={setBannerDataURL}
            commercialData={commercialData}
            setCommercialData={setCommercialData}
          />

          <Row>
            <Col sm={12}>
              {/* <CardTitle className="h4 mb-3">Discovery</CardTitle> */}
              <div className="page-title-box d-block align-items-center justify-content-between">
                <FirstSection
                  firstFile={firstFile}
                  setFirstFile={setFirstFile}
                  firstDataURL={firstDataURL}
                  setFirstDataURL={setFirstDataURL}
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                />
                <SecondSection
                  secondFile={secondFile}
                  setSecondFile={setSecondFile}
                  secondDataURL={secondDataURL}
                  setSecondDataURL={setSecondDataURL}
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                />
                <ThirdSection
                  thirdFile={thirdFile}
                  setThirdFile={setThirdFile}
                  thirdDataURL={thirdDataURL}
                  setThirdDataURL={setThirdDataURL}
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                />

                <GallerySection
                  galleryImages={galleryImages}
                  setGalleryImages={setGalleryImages}
                  galleryImageFiles={galleryImageFiles}
                  setGalleryImageFiles={setGalleryImageFiles}
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                />

                <FloorPlanSection
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                  floorPlanFile1={floorPlanFile1}
                  setFloorPlanFile1={setFloorPlanFile1}
                  floorPlanFile2={floorPlanFile2}
                  setFloorPlanFile2={setFloorPlanFile2}
                  floorPlanDataURL1={floorPlanDataURL1}
                  floorPlanDataURL2={floorPlanDataURL2}
                  setFloorPlanDataURL1={setFloorPlanDataURL1}
                  setFloorPlanDataURL2={setFloorPlanDataURL2}
                />

                <VirtualTourSection
                  virtualFloorFile={virtualFloorFile}
                  setVirtualFloorFile={setVirtualFloorFile}
                  virtualFloorDataURL={virtualFloorDataURL}
                  setVirtualFloorDataURL={setVirtualFloorDataURL}
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                />
                <OurProcessSection
                  processFile={processFile}
                  setProcessFile={setProcessFile}
                  processDataURL={processDataURL}
                  setProcessDataURL={setProcessDataURL}
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                />
              </div>
            </Col>
            <Col sm={12} className="py-2">
              <Button
                onClick={onSubmit}
                className="btn btn-rounded btn-info float-end w-sm mb-4"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CommercialSection
