import React, { useEffect } from "react"
import ReactPlayer from "react-player"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function ThirdSection({
  commercialData,
  setCommercialData,
  thirdFile,
  setThirdFile,
  thirdDataURL,
  setThirdDataURL,
}) {
  const { third_section_heading, third_section_desc, third_section_file } =
    commercialData

  const handleChange = e => {
    setCommercialData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let url = third_section_file.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setCommercialData(prevState => ({
        ...prevState,
        third_section_file: img,
      }))
    }
    const file = e.target.files[0]
    setThirdFile(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (thirdFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setThirdDataURL(result)
        }
      }
      fileReader.readAsDataURL(thirdFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [thirdFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile3")
    temp.value = null
    setThirdDataURL(null)
    setCommercialData(prevState => ({
      ...prevState,
      third_section_file: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-3">Third Section</CardTitle>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="third_section_heading"
                onChange={handleChange}
                value={third_section_heading || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <Row>
              <Col>
                <Card className="shadow">
                  <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data={third_section_desc}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setCommercialData(prevState => ({
                          ...prevState,
                          third_section_desc: data,
                        }))
                      }}
                    />
                  </Form>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <CardTitle className="h4 mb-4">Upload Video/Image</CardTitle>

                <div className="row">
                  <Col sm={6}>
                    <div className="mt-3">
                      <div className="img-container">
                        {third_section_file !== undefined &&
                          Object.keys(third_section_file).length > 0 &&
                          url === "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <ReactPlayer
                                  url={third_section_file.image_path}
                                  controls={true}
                                  height="150px"
                                  width="150px"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {thirdDataURL && thirdFile?.type?.includes("video") && (
                          <div className="media-container">
                            <div className="media-main">
                              <ReactPlayer
                                url={thirdDataURL}
                                controls={true}
                                height="150px"
                                width="150px"
                              />
                              <div>
                                <i
                                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                  onClick={handleRemoveMedia}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {third_section_file !== undefined &&
                          Object.keys(third_section_file).length > 0 &&
                          url !== "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={`${third_section_file.image_path}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${third_section_file.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt="Image"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {thirdDataURL && thirdFile?.type?.includes("image") && (
                          <div className="media-container">
                            <div className="media-main">
                              <img
                                className="media-img"
                                src={thirdDataURL}
                                srcSet={thirdDataURL}
                                alt="Banner"
                                loading="lazy"
                              />
                              <div>
                                <i
                                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                  onClick={handleRemoveMedia}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <Input
                        name="file"
                        accept="image/*, video/*"
                        type="file"
                        onChange={handleMedia}
                        multiple
                        id="formFile3"
                      />
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ThirdSection
