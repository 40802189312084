import React, { useState, useEffect } from "react"
import { Row } from "reactstrap"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import Backdrop from "@mui/material/Backdrop"

import ExcelExample from "../../assets/files/BOQ.xlsx"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"

import PropertyTypeTable from "./PropertyTypeTable"
import BoqImport from "./BoqImport"
import AreaTypeTable from "./AreaTypeTable"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

function BoqCalculator() {
  document.title = "BOQ Calculator"
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sheetData, setSheetData] = useState({})

  const getBOQLayoutData = async () => {
    setLoading(true)
    try {
      const res = await axios.get(URL.GET_BOQ_LAYOUT)
      if (res.data.success) {
        setFile(null)
        setFileName(null)
        setLoading(false)
        setSheetData(res?.data)
      }
    } catch (error) {
      setFile(null)
      setFileName(null)
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getBOQLayoutData()
  }, [])

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Row className="px-3">
          <Row>
            <div className="py-4 draggable-body-button">
              <p className="h4 card-title font-size-22">BOQ Calculator</p>
              <div className="downloadCsv d-flex flex-column">
                <div className="font-size-14 line-height-1">
                  Download example{" "}
                  <a href={ExcelExample} download="BOQ.xlsx">
                    {" "}
                    BOQ.xlsx
                  </a>
                </div>
                <div className="mt-n1 font-size-12 text-gray">(File formats supported: xlsx & xls)</div>
              </div>
            </div>
          </Row>
          <PropertyTypeTable data={sheetData?.propertyType} />
          <AreaTypeTable data={sheetData?.areaType} />
          <BoqImport
            file={file}
            setFile={setFile}
            fileName={fileName}
            setFileName={setFileName}
            setLoading={setLoading}
            getBOQLayoutData={getBOQLayoutData}
          />
        </Row>
      </div>
    </React.Fragment>
  )
}

export default BoqCalculator
