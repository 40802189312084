import React, { useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, CardBody, Input, Label, Card } from "reactstrap"

const SubMenuList = ({ item, mainData }) => {
  const history = useHistory()
  const [subMenuData, setSubMenuData] = useState(mainData)

  const onChange = e => {
    var temp = [...subMenuData]
    var ssd = temp.findIndex(element => element.id == item.id)
    if (e.target.name === "subMenu") {
      temp[ssd].label = e.target.value
    } else if (e.target.name === "link") {
      temp[ssd].link = e.target.value
    }
    setSubMenuData(temp)
  }

  const updateSubMenu = item => {
    try {
      const res = axios.put(URL.UPDATE_SUB_MENU + `/${item.id}`, {
        link: item.link,
        name: item.name,
      })
      toast.success("Sub menu updated")
      history.push("/all-admin-menu")
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const deleteSubMenu = item => {
    try {
      const res = axios.delete(URL.DELETE_SUB_MENU + `/${item.id}`)
      toast.success("Sub Menu Deleted")
      history.push("/all-admin-menu")
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  console.log(item)

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="mb-3">
            <Label>Sub Menu</Label>
            <Input
              name="subMenu"
              value={item.label}
              // value={subMenuData[itemIndex].name}
              onChange={onChange}
              id="outlined-basic"
              label="Sub Menu"
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <Label>Link</Label>

            <Input
              name="link"
              value={item.link}
              onChange={onChange}
              id="outlined-basic"
              label="Link"
              variant="outlined"
            />
          </div>

          <div className="d-flex flex-wrap gap-2  float-end">
            <Button
              onClick={() => updateSubMenu(item)}
              className="btn btn-info btn-rounded w-sm mb-3"
            >
              Save
            </Button>
            <Button
              onClick={() => {
                deleteSubMenu(item)
                history.push("/all-admin-menu")
              }}
              className="btn btn-rounded w-sm mb-3"
              color="danger"
            >
              Delete
            </Button>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SubMenuList
