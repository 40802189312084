import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Button,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "../../helpers/api_helper"
import { toast } from "react-toastify"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

const MenuList = () => {
  document.title = "Menu List"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [menuList, setMenuList] = useState([])
  const [filteredMenuList, setFilteredMenuList] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => {
    setShowModal(false)
  }

  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_MENU)
      setMenuList(res.data.menus)
      setFilteredMenuList(res.data.menus)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteMenu = async item => {
    try {
      const res = await axios.delete(URL.DELETE_MENU + `/${item.id}`)
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({}) 
        toast.success(res.data.message)
        getMenu()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    getMenu()
  }, [])

  const columns = [
    {
      name: "S. no",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Menu Name",
      selector: row => row.label,
      sortable: true,
    },
    {
      name: "Link",
      selector: row => row.link,
    },
    {
      name: "Edit",
      cell: row => (
        <Button
          className="btn btn-rounded btn-warning float-end w-sm"
          onClick={() => handleEditMenu(row)}
        >
          Edit
        </Button>
      ),
    },
    {
      name: "Delete",
      cell: row => (
        <Button
          color="danger"
          className="btn btn-rounded float-end w-sm me-3"
          // onClick={() => handleDeleteMenu(row.id)}
          onClick={() => showDeleteModal(row)}
        >
          Delete
        </Button>
      ),
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = menuList.filter(item => {
      return item.label.toLowerCase().match(search.toLowerCase())
    })

    setFilteredMenuList(result)
  }, [search])

  const handleAddMenu = row => {
    history.push(`/add-admin-menu/`)
  }

  const handleEditMenu = row => {
    history.push("/edit-admin-menu", { state: row })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Menu List</ModalHeader>
        <ModalBody>
          Do you want to delete this row with menu name {deleteRecord.label}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteMenu(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="py-4 px-2">
            <Col sm={11}>
              <p className="h4 card-title font-size-22">Menu List</p>
            </Col>
            <Col sm={1} className="mb-4">
              <Link to="/add-admin-menu">
                <Button
                  onClick={handleAddMenu}
                  className="btn btn-rounded btn-info float-end w-sm"
                >
                  Add Menu
                </Button>
              </Link>
            </Col>
          </Row>
          <Container fluid={true}>
            <DataTable
              columns={columns}
              data={filteredMenuList}
              fixedHeader
              pagination
              customStyles={customStyles}
              selectableRowsHighlight
              highlightOnHover
              fixedHeaderScrollHeight="500px"
              subHeader
              subHeaderComponent={
                <Col className="pt-4 data-table">
                  <i className="table-search-icon bx bx-search-alt font-size-22" />
                  <Input
                    label="Search"
                    placeholder="Search"
                    id="table-search"
                    value={search}
                    onChange={handleSearch}
                  />
                </Col>
              }
              subHeaderAlign="left"
            />
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MenuList
