import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            <li>
              <Link to="/data-metrics">
                <i className="mdi mdi-google-analytics"></i>
                <span>{props.t("Data Metrics")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Home")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/home-page">{props.t("Manage Home")}</Link>
                </li>
                <li>
                  <Link to="/why-partner">{props.t("Why Partner")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-chevron-down-square"></i>
                <span>{props.t("About Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/manage-about">{props.t("Manage About")}</Link>
                </li>
                <li>
                  <Link
                    style={{ fontSize: "12px" }}
                    to="/all-space-owner-and-client"
                  >
                    {props.t("Manage Space Owner & Client")}
                  </Link>
                </li>
                <li>
                  <Link to="/all-team">{props.t("Manage Team")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-user"></i>
                <span>{props.t("Partner")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/clientele">{props.t("Clientele")}</Link>
                </li>
                <li>
                  <Link to="/testimonial">{props.t("Testimonial")} </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-business"></i>
                <span>{props.t("Commercial")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/commercial">{props.t("Commercial")}</Link>
                </li>
                <li>
                  <Link to="/experience-center">
                    {props.t("Experience Center")}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/our-process-steps">
                    {props.t("Our Process Steps")}{" "}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Vendor Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/vendors">{props.t("Vendor")}</Link>
                </li>
                <li>
                  <Link to="/vendors-login">{props.t("Login Data")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-building-house"></i>
                <span>{props.t("Property Parameters")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/property-category">
                    {props.t("Property Category")}
                  </Link>
                </li>
                <li>
                  <Link to="/property-type">{props.t("Property Type")}</Link>
                </li>
                <li>
                  <Link to="/property-feature">
                    {props.t("Property Features")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-food-menu"></i>
                <span>{props.t("Vendor Menu")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/vendor-menu">{props.t("All Vendor Menu")}</Link>
                </li>
                <li>
                  <Link to="/vendor-sub-menu">
                    {props.t("All Vendor Sub Menu")}
                  </Link>
                </li>
                <li>
                  <Link to="/vendor-sub-sub-menu">
                    {props.t("All Vendor Sub Sub Menu")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-task"></i>
                <span>{props.t("Agreement")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/amenities">{props.t("Amenities")}</Link>
                </li>
                <li>
                  <Link to="/kyc-type">{props.t("KYC Type")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/boq-calculator">
                <i className="bx bxs-calculator"></i>
                <span>{props.t("BOQ Calculator")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-calculator"></i>
                <span>{props.t("Space Calculator")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/all-calculator-layout">
                    {props.t("Manage Calculator Layout")}
                  </Link>
                </li>
                <li>
                  <Link to="/all-calculator-group">
                    {props.t("Manage Calculator Group")}
                  </Link>
                </li>
                <li>
                  <Link to="/all-calculator-group-item">
                    {props.t("Manage Calculator Items")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add-circulation-details"
                    style={{ fontSize: "10px" }}
                  >
                    {props.t("Manage Cost and Circulation Details")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-building"></i>
                <span>{props.t("Property Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/property">{props.t("All Property")}</Link>
                </li>
                <li>
                  <Link to="/info-and-map">{props.t("Info & Map")}</Link>
                </li>
                <li>
                  <Link to="/actual-site-data">
                    {props.t("Actual Site Data")}
                  </Link>
                </li>
                <li>
                  <Link to="/floor-layout">{props.t("Floor Layout")}</Link>
                </li>
                <li>
                  <Link to="/3d-render">{props.t("3D Render")}</Link>
                </li>
                <li>
                  <Link to="/3d-tour">{props.t("3D Tour")}</Link>
                </li>
                <li>
                  <Link to="/property-views">
                    {props.t("Property Views Data")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Contact Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-contact">{props.t("Manage Contact")}</Link>
                </li>
                <li>
                  <Link to="/all-social-links">
                    {props.t("Manage Social Links")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/general-site">
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <i className="bx bxs-detail" />
                <span>{props.t("General Site Setting")}</span>
              </Link>
            </li>

            <li>
              <Link to="/all-menu">
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <i className="bx bxs-detail" />
                <span>{props.t("Manage Menu")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("User Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/add-admin-user">{props.t("Add New User")}</Link>
                </li>
                <li>
                  <Link to="/all-admin-user">{props.t("User List")}</Link>
                </li>
                <li>
                  <Link to="/all-role-management">
                    {props.t("Manage Role")}
                  </Link>
                </li>
                <li>
                  <Link to="/all-admin-menu">{props.t("Menu List")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/create-build">
                <i className="bx bx-cog "></i>
                <span>{props.t("Create Build")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
