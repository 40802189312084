import React, { useState, useEffect } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import Select from "react-select"
import Backdrop from "@mui/material/Backdrop"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"
import { useLocation } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useHistory } from "react-router-dom"
import InfoMapImage from "./InfoMapImage"
import { toast } from "react-toastify"

import LoadingSpinner from "../../../assets/images/ball-triangle.svg"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function InfoMap() {
  const { state } = useLocation()
  document.title = state ? "Edit Info & Map" : "Add Info & Map"
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [infoList, setInfoList] = useState({
    property_id: state ? state.state.property_id : "",
    vendor_id: state ? state.state.vendor_id : "",
    heading: state ? state.state.heading : "",
    subheading: state ? state.state.subheading : "",
    description: state ? state.state.description : "",
    about_project: state ? state.state.about_project : "",
    build_year: state ? state.state.build_year : "",
    build_area: state ? state.state.build_area : "",
    carpet_area: state ? state.state.carpet_area : "",
    property_type: state ? state.state.property_type : "",
    booking_type: state ? state.state.booking_type : "",
    min_price: state ? state.state.min_price : "",
    max_price: state ? state.state.max_price : "",
    sqft_price: state ? state.state.sqft_price : "",
    feature: state ? state.state.feature : [],
    banner: state ? state?.state?.InfoBanner : [],
  })

  const [removed_banner_ids, set_Removed_banner_ids] = useState([])

  const {
    property_id,
    vendor_id,
    heading,
    subheading,
    description,
    about_project,
    build_year,
    build_area,
    carpet_area,
    property_type,
    booking_type,
    min_price,
    max_price,
    sqft_price,
    feature,
  } = infoList

  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")

  const [propertyList, setPropertyList] = useState([])
  const [propertyDropdown, setPropertyDropdown] = useState("")

  const [propertFeature, setPropertyFeature] = useState([])
  const [propertyFeatureDropdown, setPropertyFeatureDropdown] = useState([])

  const [images, setImages] = useState([])
  const [imageFiles, setImageFiles] = useState([])

  const getVendorParent = async () => {
    setLoading(true)
    let vendorOptions = []
    try {
      setLoading(false)
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let property = item.Property
        let obj = {
          id,
          name,
          property,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorParent()
  }, [])

  useEffect(() => {
    let temp = vendorList.find(el => el.id === vendor_id)
    setVendorDropdown(temp)
    setPropertyList(temp?.property)
  }, [vendor_id, vendorList.length])

  useEffect(() => {
    let temp = propertyList?.find(el => el?.id === property_id)
    setPropertyDropdown(temp)
  }, [property_id, propertyList?.length])

  useEffect(() => {
    let itemIds = feature.map(item => item.id)
    let temp = propertFeature?.filter(el => itemIds?.includes(el.id))
    setPropertyFeatureDropdown(temp)
  }, [feature, propertFeature])

  const handleGoBack = () => {
    history.goBack()
  }

  const handleSave = async () => {
    setLoading(true)
    let formData = new FormData()
    let tempFeature = propertyFeatureDropdown?.map(item => item?.id.toString())
    formData.append("property_id", propertyDropdown?.id)
    formData.append("vendor_id", vendorDropdown?.id)
    Object?.keys(infoList)?.forEach(fieldName => {
      if (infoList[fieldName])
        if (fieldName === "banner") {
          infoList[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        } else if (fieldName === "feature") {
          formData.append("feature", JSON.stringify(tempFeature))
        } else {
          formData.append(fieldName, infoList[fieldName])
        }
    })

    try {
      setLoading(false)
      const res = await axios.post(URL.ADD_PROPERTY_INFO, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/info-and-map")
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdate = async () => {
    setLoading(true)
    let formData = new FormData()
    let tempFeature = propertyFeatureDropdown?.map(item => item?.id.toString())
    Object?.keys(infoList).forEach(fieldName => {
      if (infoList[fieldName]) {
        if (fieldName === "banner") {
          infoList[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        } else if (fieldName === "property_id") {
          formData.append(fieldName, parseInt(propertyDropdown?.id))
        } else if (fieldName === "vendor_id") {
          formData.append(fieldName, parseInt(vendorDropdown?.id))
        } else if (fieldName === "feature") {
          formData.append("feature", JSON.stringify(tempFeature))
        } else {
          formData.append(fieldName, infoList[fieldName])
        }
      }
    })

    formData.append("removed_banner_ids", JSON.stringify(removed_banner_ids))

    try {
      setLoading(false)
      const res = await axios.put(
        URL.UPDATE_PROPERTY_INFO + `/${state.state.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/info-and-map")
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const onChange = e => {
    setInfoList(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handlePrice = e => {
    if (e.target.value.length > 8) {
      return
    }
    setInfoList(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleChange = e => {
    // if (e.target.value < 0 || e.target.value.length > 16) {
    //   return
    // }
    setInfoList(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  var invalidChars = ["-", "e", "+", "E"]

  const onKeyDown = e => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const getPropertyFeature = async () => {
    try {
      const res = await axios.get(URL.ALL_PROPERTY_FEATURE)
      if (res.data.success) {
        setPropertyFeature(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPropertyFeature()
  }, [])

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                Add Info & Map
              </CardTitle>
              <div className="d-flex flex-wrap float-end gap-2">
                <Button
                  className="btn btn-info btn-rounded  w-sm mb-4"
                  onClick={state ? handleUpdate : handleSave}
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label>Vendor</Label>
                    <Select
                      value={vendorDropdown}
                      options={vendorList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Vendor"
                      onChange={(event, val) => {
                        setVendorDropdown(event)
                        setPropertyList(event?.property)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Property</Label>
                    <Select
                      value={propertyDropdown}
                      options={propertyList}
                      getOptionLabel={option => `${option.property_id}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Property"
                      onChange={(event, val) => {
                        setPropertyDropdown(event)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Heading</Label>
                    <Input
                      type="text"
                      name="heading"
                      value={heading || ""}
                      className="form-control"
                      id="formrow-heading-Input"
                      placeholder="Heading"
                      onChange={onChange}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Sub Heading</Label>
                    <Input
                      type="text"
                      name="subheading"
                      value={subheading}
                      className="form-control"
                      id="formrow-heading-Input"
                      placeholder="Sub Heading"
                      onChange={onChange}
                    />
                  </div>

                  <InfoMapImage
                    // banner={banner}
                    // firstFile={firstFile}
                    // setFirstFile={setFirstFile}
                    // firstDataURL={firstDataURL}
                    // setFirstDataURL={setFirstDataURL}
                    removed_banner_ids={removed_banner_ids}
                    set_Removed_banner_ids={set_Removed_banner_ids}
                    images={images}
                    setImages={setImages}
                    imageFiles={imageFiles}
                    setImageFiles={setImageFiles}
                    setInfoList={setInfoList}
                    infoList={infoList}
                  />

                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Description</Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setInfoList(prevState => ({
                          ...prevState,
                          description: data,
                        }))
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">About Project</Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={about_project}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setInfoList(prevState => ({
                          ...prevState,
                          about_project: data,
                        }))
                      }}
                    />
                  </div>
                  <Label className="h-2">Build Details</Label>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputCity">Year of Build</Label>
                        <Input
                          type="number"
                          className="form-control"
                          name="build_year"
                          value={build_year}
                          onKeyDown={onKeyDown}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">
                          Build up area
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="build_area"
                          value={build_area}
                          onKeyDown={onKeyDown}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputZip">Carpet Area</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="carpet_area"
                          value={carpet_area}
                          onKeyDown={onKeyDown}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputCity">Property Type</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="property_type"
                          value={property_type}
                          onChange={onChange}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">Booking Type</Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="booking_type"
                          value={booking_type}
                          onChange={onChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputCity">Minimum Price</Label>
                        <Input
                          type="number"
                          className="form-control"
                          name="min_price"
                          value={min_price}
                          onKeyDown={onKeyDown}
                          onChange={handlePrice}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputState">
                          Maximum Price
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          name="max_price"
                          value={max_price}
                          onKeyDown={onKeyDown}
                          onChange={handlePrice}
                        />
                      </div>
                    </Col>

                    <Col lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-InputZip">
                          Per Sq.ft Price
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="sqft_price"
                          value={sqft_price}
                          // onKeyDown={onKeyDown}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-3">
                        <Label>Feature</Label>
                        <Select
                          isMulti
                          value={propertyFeatureDropdown}
                          options={propertFeature}
                          getOptionLabel={option => `${option.title}`}
                          getOptionValue={option => `${option.id}`}
                          placeholder="Select Feature"
                          onChange={event => {
                            setPropertyFeatureDropdown(event)
                          }}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="py-4">
            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="d-flex flex-wrap float-end gap-2">
                <Button
                  className="btn btn-info btn-rounded  w-sm mb-4"
                  onClick={state ? handleUpdate : handleSave}
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InfoMap
