import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Input, Button, Col, Row, Container, Label } from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import Select from "react-select"
import moment from "moment"
import { DatePicker } from "antd"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function PropertyViewsData() {
  const [search, setSearch] = useState("")
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [showTable, setShowTable] = useState(false)
  const [propertyViewsData, setPropertyViewsData] = useState([])
  const [filteredPropertyViewsData, setFilteredPropertyViewsData] = useState([])

  const getPropertyViewsData = async () => {
    document.title = "Property Views Data"
    try {
      const res = await axios.post(URL.ALL_PROPERTY_VIEW_DATA_VIEWS, {
        minDate: minDate,
        maxDate: maxDate,
      })
      setPropertyViewsData(res.data?.data)
      setFilteredPropertyViewsData(res.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      // sortable: true,
      width: "100px",
    },
    {
      name: "Vendor Name",
      selector: row => row.vendor,
      sortable: true,
    },
    {
      name: "Property Name",
      selector: row => row.property,
    },
    {
      name: "No. of Views",
      selector: row => row.num_of_views,
    },
    // {
    //   name: "Created At",
    //   selector: row => moment(row.created_at).format("L"),
    // },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = propertyViewsData.filter(item => {
      return item.vendor.toLowerCase().match(search.toLowerCase())
    })

    setFilteredPropertyViewsData(result)
  }, [search])

  const handleMaxDate = (date, dateString) => {
    let newDate = moment(dateString).format("MM-DD-yyyy")
    setMaxDate(newDate)
  }

  const handleMinDate = (date, dateString) => {
    let newDate = moment(dateString).format("MM-DD-yyyy")
    setMinDate(newDate)
  }

  const onSubmit = () => {
    getPropertyViewsData()
    setShowTable(true)
  }

  useEffect(() => {
    console.log("trigger")
    if (maxDate !== null && minDate !== null) {
      setIsDisabled(false)
    } else if (maxDate === "Invalid date" && minDate === "Invalid date") {
      setIsDisabled(true)
    } else {
      setIsDisabled(true)
    }
  }, [minDate, maxDate, isDisabled])

  console.log(minDate, maxDate === "Invalid date")

  return (
    <div className="page-content">
      <Container fluid>
        <Row className="py-2 px-2">
          <div className="draggable-body-button">
            <Col sm={12}>
              <p className="h4 card-title font-size-22">Property Views Data</p>
            </Col>
          </div>
        </Row>

        <div className="draggable-body-button py-3">
          <Col sm={4} className="d-flex  justify-content-center">
            {/* <Label className="card-title font-size-16 me-2">From</Label> */}
            <DatePicker
              className="w-100 me-3"
              placeholder="Min Date"
              onChange={handleMinDate}
              disabledDate={d => !d || d.isAfter(maxDate)}
            />
          </Col>
          <Col sm={4} className="ms-1">
            <DatePicker
              className="w-100 "
              placeholder="Max Date"
              onChange={handleMaxDate}
              disabledDate={d => !d || d.isSameOrBefore(minDate)}
            />
          </Col>
          <Col sm={4} className="ms-1">
            <Button
              disabled={isDisabled}
              className={
                isDisabled
                  ? `btn btn-rounded float-end w-sm`
                  : `btn btn-rounded btn-info float-end w-sm`
              }
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Col>
        </div>

        {showTable && (
          <DataTable
            title=""
            columns={columns}
            data={filteredPropertyViewsData}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  placeholder="Search"
                  id="table-search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        )}
      </Container>
    </div>
  )
}

export default PropertyViewsData
