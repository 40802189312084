import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { Table } from "antd"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"
import { toast } from "react-toastify"
import {
  Button,
  Col,
  Row,
  Container,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import DragIcon from "../../assets/images/drag.png"

const type = "DragableBodyRow"

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop(
    () => ({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {}
        if (dragIndex === index) {
          return {}
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? "drop-over-downward" : "drop-over-upward",
        }
      },
      drop: item => {
        moveRow(item.index, index)
      },
    }),
    [index]
  )

  const [, drag] = useDrag(
    () => ({
      type,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index]
  )

  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    ></tr>
  )
}

const components = {
  body: {
    row: DragableBodyRow,
  },
}

function WhyPartnerList() {
  document.title = "Why Partner List"
  const history = useHistory()
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const columns = [
    {
      key: "id",
      width: 40,
      render: () => (
        <img
          style={{
            textAlign: "center",
            height: "20px",
            width: "20px",
            objectFit: "cover",
          }}
          alt="Image"
          src={DragIcon}
        />
      ),
    },
    {
      title: "Heading",
      dataIndex: "heading",
      key: "heading",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // width: 280,
      // ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 180,
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            color={`${record.status === true ? `success` : `dark`} `}
            onClick={() => handleUpdateStatus(record)}
          >
            {record.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div size="middle">
          <Button
            className="btn btn-rounded btn-warning w-sm me-3"
            onClick={() => handleEditMenu(record)}
          >
            Edit
          </Button>
          <Button
            color="danger"
            variant="outline"
            className="btn btn-rounded w-sm"
            // onClick={() => handleDeletePartners(record)}
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ]

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    },
    [data]
  )

  const getWhyPartnerDetails = async () => {
    try {
      const res = await axios.get(URL.WHY_PARTNER_ALL)
      if (res.data.success) {
        setData(res.data.data)

        let temp = res.data.data.map(item => ({
          key: item.id,
          ...item,
        }))
        console.log(temp)
        setData(temp)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const temp = data.map((item, idx) => {
    return { name: item.heading, id: item.id, sort_order: idx + 1 }
  })

  console.log(temp)

  useEffect(() => {
    getWhyPartnerDetails()
  }, [])

  const handleDeletePartners = async item => {
    try {
      const res = await axios.delete(URL.WHY_PARTNER_DELETE, {
        data: {
          entryIds: item.id,
        },
      })
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getWhyPartnerDetails()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(
        URL.WHY_PARTNER_UPDATE_STATUS + `${item.id}`,
        {
          status: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getWhyPartnerDetails()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    saveSortOrder()
  }, [data])

  const saveSortOrder = async () => {
    try {
      const res = await axios.put(URL.WHY_PARTNER_SORT_ORDER, {
        new_order: temp,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddMenu = () => {
    history.push("/add-why-partner")
  }

  const handleEditMenu = row => {
    history.push("/add-why-partner", { state: row })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Our Team</ModalHeader>
        <ModalBody>
          Do you want to delete the row with name {deleteRecord.name}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeletePartners(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col sm={11}>
              <p className="h4 card-title font-size-22">Why Partner List</p>
            </Col>
            <Col sm={1}>
              <Button
                onClick={handleAddMenu}
                className="btn btn-rounded btn-info float-end w-sm"
              >
                Add
              </Button>
            </Col>
          </Row>

          <DndProvider backend={HTML5Backend}>
            <Table
              columns={columns}
              dataSource={data}
              components={components}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
              // scroll={{ x: "max-content" }}
            />
          </DndProvider>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WhyPartnerList
