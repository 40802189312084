import React from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

const AreaTypeTable = ({ data }) => {
  const IdWidth = {
    width: "5%",
  }

  const ItemWidth = {
    width: "20%",
  }

  const costingWidth = {
    width: "15%",
  }

  const descriptionWidth = {
    width: "55%",
  }

  const ItemStyles = {
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }

  const descriptionStyles = {
    height: "100px",
    overflow: "hidden",
    overflowY: "scroll",
    padding: "0px 10px 5px 5px",
  }

  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle>Area Type Data</CardTitle>
            <CardSubtitle className="mb-3">
              The Area type data updated here will be refelected in the
              viztown.in
            </CardSubtitle>

            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Description</th>
                    <th>Costing %</th>
                  </tr>
                </thead>
                {data?.map((item, index) => {
                  let id = index + 1
                  return (
                    <tbody key={index}>
                      <tr>
                        <th scope="row" style={IdWidth}>
                          {id}
                        </th>
                        <td style={ItemWidth}>
                          <div style={ItemStyles}>
                            {item?.Item}
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Enim ad sit iusto error vitae consectetur
                            numquam hic, ducimus cum optio! Aperiam illo,
                            inventore ipsa veniam similique odio. Nisi, minima
                            eligendi! */}
                          </div>
                        </td>
                        <td style={descriptionWidth}>
                          <div style={descriptionStyles}>
                            {/* Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Laudantium accusantium repudiandae
                            necessitatibus iste dolorem assumenda quae atque,
                            nesciunt modi exercitationem cumque molestias minima
                            tempore voluptates libero inventore odio placeat
                            sequi, asperiores est cum voluptate a neque nostrum.
                            Harum unde suscipit deserunt dolorum repellendus
                            nostrum non velit odio, vitae, qui fugit quaerat.
                            Hic repudiandae cum veritatis aspernatur perferendis
                            vitae inventore dolores? Inventore iure quod
                            mollitia nam sed. Est in voluptate rerum veniam
                            doloribus! Dolores facere nesciunt mollitia
                            laudantium assumenda omnis doloribus amet
                            necessitatibus possimus libero numquam nam cumque
                            laboriosam, sunt, eos, praesentium cupiditate quidem
                            deserunt! Amet deleniti molestiae provident ducimus.
                            Labore. */}
                            {item?.Description}
                          </div>
                        </td>
                        <td style={costingWidth}>{item?.Costing}%</td>
                      </tr>
                    </tbody>
                  )
                })}
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AreaTypeTable
