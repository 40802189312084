import React, { useCallback, useEffect, useState } from "react"
import { Table } from "antd"
import update from "immutability-helper"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Button, Container, Row, Col } from "reactstrap"
import NoImage from "../../assets/images/no-image-placeholder.png"

function ManageMenuList() {
  document.title = "Our Menu"
  const history = useHistory()
  const [data, setData] = useState([])

  console.log(data)

  const columns = [
    {
      title: "Menu",
      dataIndex: "name",
      key: "name",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Banner Heading",
      dataIndex: "banner_heading",
      key: "banner_heading",
      width: 260,
      ellipsis: true,
    },
    {
      title: "Banner Image",
      dataIndex: "banner_img",
      key: "banner_img",
      width: 150,
      render: imagePath => (
        <img
          style={{ height: "60px", width: "60px", objectFit: "cover" }}
          alt="Image"
          src={imagePath?.image_path ? imagePath?.image_path : NoImage}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={() => handleUpdateStatus(record)}
            color={`${record.status === true ? `success` : `dark`} `}
          >
            {record.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Quick Links",
      dataIndex: "quickLinks",
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={() => handleUpdateQuickLinkStatus(record)}
            color={`${
              record.status_for_quicklinks === true ? `success` : `dark`
            } `}
          >
            {record.status_for_quicklinks === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Our Products",
      dataIndex: "ourProducts",
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={() => handleUpdateOurProductStatus(record)}
            color={`${
              record.status_for_ourproduct === true ? `success` : `dark`
            } `}
          >
            {record.status_for_ourproduct === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <div size="middle">
          <Button
            className="btn btn-rounded w-sm btn-warning me-3"
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ]

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    },
    [data]
  )

  const getManageMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_MANAGE_MENU)
      setData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getManageMenu()
  }, [])

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    console.log(sendStatus)
    try {
      const res = await axios.put(
        URL.UPDATE_MANAGE_MENU_STATUS + `/${item.id}`,
        {
          status: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getManageMenu()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.success(res.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdateQuickLinkStatus = async item => {
    let sendStatus = item.status_for_quicklinks
    console.log(sendStatus)
    try {
      const res = await axios.put(
        URL.UPDATE_MANAGE_MENU_QUICK_LINK_STATUS + `/${item.id}`,
        {
          status_for_quicklinks: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getManageMenu()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.success(res.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdateOurProductStatus = async item => {
    let sendStatus = item.status_for_ourproduct
    console.log(sendStatus)
    try {
      const res = await axios.put(
        URL.UPDATE_MANAGE_MENU_PRODUCT_STATUS + `/${item.id}`,
        {
          status_for_ourproduct: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getManageMenu()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.success(res.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const temp = data?.map((item, idx) => {
    return { heading: item.heading, id: item.id, sort_order: idx + 1 }
  })

  useEffect(() => {
    saveSortOrder()
  }, [data])

  const saveSortOrder = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_SPACE_OWNER_AND_CLIENT_STATUS_SORT_ORDER,
        {
          new_order: temp,
        }
      )
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleEdit = record => {
    history.push("/add-menu", { state: record })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col sm={10}>
              <div className="h4 card-title font-size-22">Our Menu List</div>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={data}
            scroll={{ x: "max-content" }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageMenuList
