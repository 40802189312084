import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { toast } from "react-toastify"
import moment from "moment"
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function CreateBuild() {
  document.title = "Create Build"

  const [search, setSearch] = useState("")
  const [buildData, setBuildData] = useState([])
  const [filteredBuildData, setFilteredBuildData] = useState([])
  const [showModal, setShowModal] = useState(false)

  const toggle = () => setShowModal(false)
  const triggerBuildConfirm = record => {
    setShowModal(true)
  }

  const columns = [
    {
      name: "Name",
      selector: row => row.user.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row.user.email,
    },
    {
      name: "Build Status",
      selector: row => row.build_status,
    },
    {
      name: "Create At",
      selector: row => moment(row.created_at).format("LLLL"),
    },
  ]

  const getBuildData = async () => {
    try {
      const res = await axios.get(URL.ALL_SITE_BUILD)
      if (res.data.success) {
        console.log(res)
        setBuildData(res.data.data)
        setFilteredBuildData(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBuildData()
  }, [])

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = buildData.filter(item => {
      return item.user.name.toLowerCase().match(search.toLowerCase())
    })

    setFilteredBuildData(result)
  }, [search])

  const getBuildAll = async () => {
    try {
      const res = await axios.get(URL.TRIGGER_BUILD)
      if (res.data.success) {
        console.log(res)
        getBuildData()
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Create Build</ModalHeader>
        <ModalBody>
          Viztown.in will be down for 10 - 15 minutes while the build is being
          created
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            className="btn shadow btn-rounded"
            color="success"
            onClick={getBuildAll}
          >
            Tigger
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Row className="px-3">
          <Col sm={12} className="mb-3">
            <p className="h4 card-title font-size-22">Create Build</p>
          </Col>
          <Col className="p-4 mb-2">
            <Col
              sm={12}
              className="d-flex  justify-content-center align-items-center"
            >
              <Button
                className="btn shadow btn-rounded"
                color="success"
                size="lg"
                onClick={triggerBuildConfirm}
              >
                Trigger Build
              </Button>
            </Col>
          </Col>
        </Row>
        <Row className="px-1">
          <Col className="p-1 mb-4">
            <Col
              sm={12}
              className="d-flex  justify-content-center align-items-center"
            >
              <p className="h6 fst-italic fw-lighter card-title ">
                All the CMS data will get updated on Viztown.in when the build
                is triggered.
              </p>
            </Col>
          </Col>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredBuildData}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  placeholder="Search"
                  id="table-search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateBuild
