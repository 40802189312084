import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { Table } from "antd"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"
import { toast } from "react-toastify"
import { Container, Button, Row, Col } from "reactstrap"
import DragIcon from "../../assets/images/drag.png"

const type = "DragableBodyRow"

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop(
    () => ({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {}
        if (dragIndex === index) {
          return {}
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? "drop-over-downward" : "drop-over-upward",
        }
      },
      drop: item => {
        moveRow(item.index, index)
      },
    }),
    [index]
  )

  const [, drag] = useDrag(
    () => ({
      type,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index]
  )

  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    ></tr>
  )
}

const components = {
  body: {
    row: DragableBodyRow,
  },
}

function VendorMenuList() {
  document.title = "All Vendor Menu"
  const history = useHistory()
  const [data, setData] = useState([])

  const columns = [
    {
      key: "id",
      width: 40,
      render: () => (
        <img
          style={{
            textAlign: "center",
            height: "20px",
            width: "20px",
            objectFit: "cover",
          }}
          alt="Image"
          src={DragIcon}
        />
      ),
    },
    {
      title: "Menu",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={e => handleUpdateStatus(record)}
            color={`${record.status === true ? `success` : `dark`} `}
          >
            {record.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div size="middle">
          <Button
            className="btn btn-rounded btn-warning w-sm me-3"
            onClick={() => handleEditMenu(record)}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ]

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    },
    [data]
  )

  const getMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_VENDOR_MENU)
      if (res.data.success) {
        setData(res.data.menus)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const temp = data?.map((item, idx) => {
    return { name: item.name, id: item.id, sort_order: idx + 1 }
  })

  useEffect(() => {
    getMenu()
  }, [])

  useEffect(() => {
    saveSortOrder()
  }, [data])

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(
        URL.UPDATE_VENDOR_MENU_STATUS + `/${item.id}`,
        {
          status: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getMenu()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const saveSortOrder = async () => {
    try {
      const res = await axios.put(URL.UPDATE_VENDOR_MENU_SORT_ORDER, {
        new_order: temp,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditMenu = row => {
    history.push("/add-vendor-menu", { state: row })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col sm={10}>
              <div className="h4 card-title font-size-22">All Vendor Menu</div>
            </Col>
          </Row>

          <DndProvider backend={HTML5Backend}>
            <Table
              columns={columns}
              dataSource={data}
              components={components}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
              scroll={{ x: "max-content" }}
            />
          </DndProvider>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorMenuList
