import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory, useLocation } from "react-router-dom"
import {
  CardBody,
  CardTitle,
  Card,
  Container,
  Row,
  Col,
  Button,
  Input,
  Label,
} from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function PropertyFeatures() {
  document.title = "Add Property Feature"
  const history = useHistory()
  const { state } = useLocation()
  const [property, setProperty] = useState({
    title: state ? state?.state?.title : "",
    icon: state ? state?.state?.icon : "",
  })

  const { title, icon } = property
  const [isDisabled, setIsDisabled] = useState(true)

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setProperty(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addProperty = async () => {
    let formData = new FormData()
    Object.keys(property).forEach(fieldName => {
      if (property[fieldName]) {
        formData.append(fieldName, property[fieldName])
      }
    })
    try {
      const res = await axios.post(URL.ADD_PROPERTY_FEATURE, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/property-feature")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateProperty = async () => {
    let formData = new FormData()
    Object.keys(property).forEach(fieldName => {
      if (property[fieldName]) {
        formData.append(fieldName, property[fieldName])
      }
    })
    try {
      const res = await axios.put(
        URL.UPDATE_PROPERTY_FEATURES + `/${state?.state?.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/property-feature")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL(result)
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setProperty(prevState => ({
        ...prevState,
        icon: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setFirstFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#propertyFile")
    temp.value = null
    setFirstDataURL(null)
    setProperty(prevState => ({
      ...prevState,
      icon: [],
    }))
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (title.length > 0 && icon !== "") {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [title, firstFile, firstDataURL])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Property Feature" : "Add Property Feature"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateProperty : addProperty}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label>Title</Label>
                    <Input
                      name="title"
                      value={title}
                      onChange={onChange}
                      id="outlined-basic"
                      label="Title"
                      placeholder="Title"
                    />
                  </div>
                </Col>
              </Row>

              <Label>Image</Label>

              <div
                className={
                  icon !== null && Object.keys(icon).length > 0
                    ? `img-container  mb-5`
                    : `img-container `
                }
              >
                {icon !== null && Object.keys(icon).length > 0 && (
                  <div className="media-container">
                    <div className="media-main">
                      <img
                        className="media-main"
                        src={`${icon.image_path}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${icon.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt="Image"
                        loading="lazy"
                      />
                      <i
                        className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                        onClick={handleRemoveMedia}
                      />
                    </div>
                  </div>
                )}

                {firstDataURL && (
                  <div className="media-container">
                    <div className="media-main">
                      <img
                        className="media-img"
                        src={firstDataURL}
                        srcSet={firstDataURL}
                        alt="Banner"
                        loading="lazy"
                      />
                      <i
                        className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                        onClick={handleRemoveMedia}
                      />
                    </div>
                  </div>
                )}
              </div>

              <Input
                id="propertyFile"
                type="file"
                name="icon"
                placeholder="Choose image"
                accept="image/*"
                onChange={handleMedia}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PropertyFeatures
