import React, { useState, useEffect } from "react"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import {
  Container,
  Row,
  Input,
  Button,
  Col,
  CardBody,
  Card,
  Label,
  CardTitle,
} from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import VendorProfile from "./VendorProfile"
import Select from "react-select"

function Vendor() {
  document.title = "Add Vendor"
  const history = useHistory()
  const { state } = useLocation()

  const [genderOption, setGenderOption] = useState(null)
  const [dropdownValue, setDropdownValue] = useState("")

  const [userRoleOption, setUserRoleOptions] = useState(null)
  const [userRoleDropdown, setUserRoleDropdown] = useState("")

  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")

  const [vendorTypeOption, setVendorTypeOption] = useState(null)
  const [vendorTypeDropdown, setVendorTypeDropdown] = useState("")

  const [vendor, setVendor] = useState({
    role: state ? state?.state?.role : "",
    parent_id: state ? state?.state?.parent_id : "",
    fname: state ? state?.state?.fname : "",
    lname: state ? state?.state?.lname : "",
    email: state ? state?.state?.email : "",
    number: state ? state?.state?.number : "",
    city: state ? state?.state?.city : "",
    gender: state ? state?.state?.gender : "",
    password: state ? state?.state?.password : "",
    agency_name: state ? state?.state?.agency_name : "",
    profile: state ? state?.state?.profile : "",
    vendor_type: state ? state?.state?.vendor_type : "",
  })

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)

  const {
    role,
    parent_id,
    fname,
    lname,
    email,
    number,
    city,
    gender,
    password,
    agency_name,
    profile,
    vendor_type,
  } = vendor

  let userRoleDropdownOptions = [
    {
      id: 1,
      name: "Admin",
    },
    {
      id: 2,
      name: "Manager",
    },
    {
      id: 3,
      name: "Sales",
    },
  ]

  const onChange = e => {
    setVendor(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  var invalidChars = ["-", "e", "+", "E"]

  const onKeyDown = e => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  let genderDropdownOptions = [
    {
      id: 1,
      name: "Male",
    },
    {
      id: 2,
      name: "Female",
    },
  ]

  let vendorTypeDropdownOptions = [
    {
      id: 1,
      name: "Real Estate Partner",
      val: "REP",
    },
    {
      id: 2,
      name: "Space Owner Partner",
      val: "SOP",
    },
  ]

  const getVendorParent = async () => {
    let vendorOptions = []
    try {
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let property = item.Property
        let obj = {
          id,
          name,
          property,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorParent()
  }, [])

  useEffect(() => {
    let temp = genderDropdownOptions.find(el => el.name === gender)
    setDropdownValue(temp)
    setGenderOption(gender)
  }, [gender])

  useEffect(() => {
    let temp = userRoleDropdownOptions.find(el => el.name === role)
    setUserRoleDropdown(temp)
    setUserRoleOptions(role)
  }, [role])

  useEffect(() => {
    let temp = vendorList.find(el => el.id === parent_id)
    setVendorDropdown(temp)
  }, [parent_id, vendorList?.length])

  useEffect(() => {
    let temp = vendorTypeDropdownOptions.find(el => el.val === vendor_type)
    setVendorTypeDropdown(temp)
  }, [vendor_type])

  console.log("vendor_", vendorDropdown)

  const addVendor = async () => {
    let formData = new FormData()
    Object.keys(vendor).forEach(fieldName => {
      if (vendor[fieldName]) {
        formData.append(fieldName, vendor[fieldName])
      }
    })
    formData.append("gender", genderOption)
    formData.append("role", userRoleOption)

    formData.append("parent_id", vendorDropdown ? vendorDropdown?.id : null)
    formData.append(
      "parent_vendor",
      vendorDropdown ? vendorDropdown?.name : null
    )

    formData.append("vendor_type", vendorTypeDropdown?.val)

    try {
      const res = await axios.post(URL.ADD_VENDOR, formData)
      if (res.data.success) {
        history.push("/vendors")
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateVendor = async () => {
    let formData = new FormData()
    Object.keys(vendor).forEach(fieldName => {
      if (vendor[fieldName]) {
        if (fieldName === "gender") {
          formData.append(fieldName, genderOption)
        } else if (fieldName === "role") {
          formData.append(fieldName, userRoleOption)
        } else if (fieldName === "vendor_type") {
          formData.append(fieldName, vendorTypeDropdown?.val)
        } else {
          formData.append(fieldName, vendor[fieldName])
        }
      }
    })

    // else if (fieldName === "parent_id") {
    //   formData.append(fieldName, vendorDropdown?.id)
    // }

    // formData.append("parent_id", vendorDropdown ? vendorDropdown?.id : null)
    formData.append(
      "parent_vendor",
      vendorDropdown ? vendorDropdown?.name : null
    )

    try {
      const res = await axios.put(
        URL.UPDATE_VENDOR + `/${state?.state?.id}`,
        formData
      )
      if (res.data.success) {
        history.push("/vendors")
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (
      userRoleDropdown !== undefined &&
      // vendorDropdown !== undefined &&
      // vendorTypeOption !== undefined &&
      fname?.length > 0 &&
      lname?.length > 0 &&
      email?.length > 0 &&
      // number > 0 &&
      // city?.length > 0 &&
      dropdownValue !== undefined &&
      // password?.length > 0 &&
      // agency_name?.length > 0 &&
      profile !== ""
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [
    userRoleDropdown,
    vendorDropdown,
    // vendorTypeOption,
    fname,
    lname,
    email,
    number,
    city,
    dropdownValue,
    // password,
    agency_name,
    profile,
    firstDataURL,
    firstFile,
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true} autoComplete="off">
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Vendor" : "Add Vendor"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  // disabled={isDisabled}
                  onClick={state ? updateVendor : addVendor}
                  className={`btn btn-rounded w-sm mb-4 btn-info`}
                  // className={
                  //   isDisabled
                  //     ? `btn btn-rounded w-sm mb-4`
                  //     : `btn btn-rounded w-sm mb-4 btn-info`
                  // }
                >
                  Save
                </Button>
                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">User Role</Label>
                <Select
                  value={userRoleDropdown}
                  options={userRoleDropdownOptions}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  onChange={event => {
                    setUserRoleDropdown(event)
                    setUserRoleOptions(event.name)
                  }}
                  autoComplete="nope"
                  isClearable
                />
              </div>

              <div className="mb-3">
                <Label>Parent Vendor</Label>
                <Select
                  value={vendorDropdown}
                  options={vendorList}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  placeholder="Select Vendor"
                  onChange={(event, val) => {
                    setVendorDropdown(event)
                  }}
                  autoComplete="nope"
                  isClearable
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">Vendor Type</Label>
                <Select
                  value={vendorTypeDropdown}
                  options={vendorTypeDropdownOptions}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  onChange={(event, val) => {
                    setVendorTypeDropdown(event)
                  }}
                  autoComplete="nope"
                  isClearable
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">First Name</Label>
                <Input
                  type="text"
                  name="fname"
                  onChange={onChange}
                  value={fname || ""}
                  className="form-control"
                  id="formrow-heading-Input"
                  placeholder="Enter First Name"
                  autoComplete="nope"
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">Last Name</Label>
                <Input
                  type="text"
                  name="lname"
                  onChange={onChange}
                  value={lname || ""}
                  className="form-control"
                  id="formrow-heading-Input"
                  placeholder="Enter Last Name"
                  autoComplete="nope"
                />
              </div>
              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">Email</Label>
                <Input
                  type="email"
                  name="email"
                  onChange={onChange}
                  value={email || ""}
                  className="form-control"
                  id="formrow-heading-Input"
                  placeholder="Enter Email"
                  autoComplete="nope"
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">Number</Label>
                <Input
                  type="number"
                  name="number"
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  value={number || ""}
                  className="form-control"
                  id="formrow-heading-Input"
                  placeholder="Enter number"
                  autoComplete="nope"
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">City</Label>
                <Input
                  type="text"
                  name="city"
                  onChange={onChange}
                  value={city || ""}
                  className="form-control"
                  id="formrow-heading-Input"
                  placeholder="Enter City"
                  autoComplete="nope"
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">Gender</Label>
                <Select
                  id="combo-box-demo"
                  value={dropdownValue}
                  options={genderDropdownOptions}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  onChange={(event, val) => {
                    setDropdownValue(event)
                    setGenderOption(event.name)
                  }}
                  isClearable
                  // onFocus={e => {
                  //   if (e.target.autocomplete) {
                  //     e.target.autocomplete = "nope"
                  //   }
                  // }}
                  autoComplete="nope"
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">Password</Label>
                <Input
                  type="text"
                  name="password"
                  onChange={onChange}
                  value={password || ""}
                  className="form-control"
                  id="formrow-heading-Input"
                  placeholder="Enter password"
                  autoComplete="nope"
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="formrow-heading-Input">Agency Name</Label>
                <Input
                  type="text"
                  name="agency_name"
                  onChange={onChange}
                  value={agency_name || ""}
                  className="form-control"
                  id="formrow-heading-Input"
                  placeholder="Enter Agency Name"
                  autoComplete="nope"
                />
              </div>

              <VendorProfile
                profile={profile}
                firstFile={firstFile}
                setFirstFile={setFirstFile}
                firstDataURL={firstDataURL}
                setFirstDataURL={setFirstDataURL}
                setVendor={setVendor}
              />
            </CardBody>
          </Card>

          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <Button
                disabled={isDisabled}
                onClick={state ? updateVendor : addVendor}
                className={
                  isDisabled
                    ? "btn btn-rounded w-sm mb-4 float-end"
                    : "btn btn-rounded btn-info float-end w-sm mb-4"
                }
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Vendor
