import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory, useLocation } from "react-router-dom"
import {
  Container,
  Button,
  Col,
  Row,
  Card,
  CardBody,
  Input,
  Label,
  CardTitle,
} from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "components/Common/Breadcrumb"

function VendorSubMenu() {
  const history = useHistory()
  const { state } = useLocation()
  const [vendor, setVendor] = useState({
    menu: state ? state?.state?.menu?.name : "",
    name: state ? state?.state?.name : "",
  })

  const { menu, name } = vendor
  const [menuOptions, setMenuOptions] = useState([])
  const [dropdownValue, setDropdownValue] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setVendor(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const getVendorMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_VENDOR_MENU)
      if (res.data.success) {
        setMenuOptions(res.data.menus)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorMenu()
  }, [])

  const updateVendorSubMenu = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_VENDOR_SUB_MENU + `/${state.state.id}`,
        {
          menu_id: dropdownValue.id,
          name: name,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/vendor-sub-menu")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    let temp = menuOptions.find(el => el.name === menu)
    setDropdownValue(temp)
  }, [menu, menuOptions.length])

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (dropdownValue !== undefined && name.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [dropdownValue, name])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state && "Edit Vendor Sub Menu"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state && updateVendorSubMenu}
                  className={
                    isDisabled
                      ? "btn btn-rounded  w-sm mb-4"
                      : "btn btn-info btn-rounded  w-sm mb-4"
                  }
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded float-end w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label>Menu</Label>
                      <Select
                        disablePortal
                        id="combo-box-demo"
                        value={dropdownValue}
                        options={menuOptions}
                        getOptionLabel={option => `${option.name}`}
                        getOptionValue={option => `${option.id}`}
                        onChange={event => {
                          setDropdownValue(event)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label>Name</Label>
                      <Input
                        name="name"
                        value={name}
                        onChange={onChange}
                        id="outlined-basic"
                        label="Sub Menu"
                        variant="outlined"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorSubMenu
