import React, { useEffect } from "react"
import { Label, Input } from "reactstrap"

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm

function LargeMultiImage({
  largeImages,
  setLargeImages,
  largeImageFiles,
  setLargeImageFiles,
  calculatorItem,
  setCalculatorItem,
}) {
  const { lg_multi_image, lg_multi_removed_imgs } = calculatorItem

  const handleMedia = e => {
    const { files } = e.target
    const validImageFiles = []
    if (e.target.files) {
      let img = e.target.files
      setCalculatorItem(prevState => ({
        ...prevState,
        lg_multi_image: [...img, ...lg_multi_image],
      }))
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file)
      }
    }
    if (validImageFiles.length) {
      setLargeImageFiles([...largeImageFiles, ...validImageFiles])
      return
    }
    alert("Selected images are not of valid type!")
  }

  const handleRemoveMedia = item => {
    let temp = lg_multi_image
    temp = temp.filter(el => el.image_name !== item.image_name)
    setCalculatorItem(prevState => ({
      ...prevState,
      lg_multi_image: temp,
      lg_multi_removed_imgs: [...lg_multi_removed_imgs, item.image_name],
    }))
  }

  const handleRemove = (item, index) => {
    let fileToRemove = largeImageFiles[index]
    const temp = largeImages.filter((el, idx) => idx !== index)
    let temp_ = lg_multi_image.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })
    let temp__ = largeImageFiles.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })

    setCalculatorItem(prevState => ({
      ...prevState,
      lg_multi_image: [...temp_],
    }))
    setLargeImages([...temp])
    setLargeImageFiles([...temp__])
  }

  useEffect(() => {
    const fileReaders = []
    let isCancel = false
    if (largeImageFiles.length) {
      const promises = largeImageFiles.map(file => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReaders.push(fileReader)
          fileReader.onload = e => {
            const { result } = e.target
            if (result) {
              resolve(result)
            }
          }
          fileReader.onabort = () => {
            reject(new Error("File reading aborted"))
          }
          fileReader.onerror = () => {
            reject(new Error("Failed to read file"))
          }
          fileReader.readAsDataURL(file)
        })
      })
      Promise.all(promises)
        .then(images => {
          if (!isCancel) {
            setLargeImages([...images])
          }
        })
        .catch(reason => {
          console.log(reason)
        })
    }
    return () => {
      isCancel = true
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [largeImageFiles])

  return (
    <div className="mb-3">
      <Label>Large Size Images</Label>
      <div className="img-container">
        {lg_multi_image?.length > 0 &&
          lg_multi_image?.map(item => {
            return (
              item.image_name && (
                <div key={item.id} className="media-container">
                  <div className="media-main">
                    <img
                      className="media-img"
                      src={`${item.image_path}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${item.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt="Banner"
                      loading="lazy"
                    />
                    <div>
                      <i
                        className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                        onClick={() => handleRemoveMedia(item)}
                      />
                    </div>
                  </div>
                </div>
              )
            )
          })}

        {largeImages.length > 0 &&
          largeImages?.map((image, index) => {
            return (
              <div key={index} className="media-container">
                <div className="media-main">
                  <img
                    className="media-img"
                    src={image}
                    srcSet={image}
                    alt="Banner"
                    loading="lazy"
                  />
                  <div>
                    <i
                      className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                      onClick={() => handleRemove(largeImages, index)}
                    />
                  </div>
                </div>
              </div>
            )
          })}
      </div>
      <Input
        name="file"
        accept="image/*"
        type="file"
        onChange={handleMedia}
        multiple
        id="small_images"
      />
      <Label htmlFor="small_images" className="font-size-12 text-gray">
        (You Can Select Multiple Images)
      </Label>
    </div>
  )
}

export default LargeMultiImage
