import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Col, Container, Row } from "reactstrap"
import Backdrop from "@mui/material/Backdrop"

import Home from "components/Home/Home"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import TotalVendors from "components/Home/TotalVendors"
import TotalProperties from "components/Home/TotalProperties"
import TotalMeetings from "components/Home/TotalMeetings"
import TotalAgreement from "components/Home/TotalAgreements"

import LoadingSpinner from "../../assets/images/ball-triangle.svg"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

const Dashboard = props => {
  document.title = "Viztown | Admin Dashboard"
  const [loading, setLoading] = useState(false)
  const [dashboardAnalytics, setDashboardAnalytics] = useState([])

  const getDashboardAnalytics = async () => {
    setLoading(true)
    try {
      const res = await axios.get(URL.DASHBOARD_ANALYTICS)
      setDashboardAnalytics(res.data?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getDashboardAnalytics()
  }, [])

  const vendorReport = {
    icon: "bx bx-copy-alt",
    title: "TOTAL VENDORS",
    value: "153",
    // badgeValue: "0.2",
    color: "success",
    desc: "View All Vendors",
  }

  const totalProperties = {
    icon: "bx bx-archive-in",
    title: "TOTAL PROPERTIES",
    value: "178",
    badgeValue: "6014",
    color: "success",
    desc: "View All Properties",
  }

  const totalMeetings = {
    icon: "bx bx-purchase-tag-alt",
    title: "TOTAL MEETINGS",
    value: "22",
    // badgeValue: "0",
    color: "success",
    desc: "22 Meetings done",
  }

  const totalAgreement = {
    icon: "bx bx-purchase-tag-alt",
    title: "TOTAL AGREEMENTS",
    value: "49",
    // badgeValue: "0",
    color: "success",
    desc: "49 Agreements shared",
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid>
          <Row className="py-4 px-2">
            <div className="draggable-body-button">
              <Col sm={12}>
                <p className="h4 card-title font-size-22">Dashboard</p>
              </Col>
            </div>
          </Row>

          <Row>
            <Col xl="12">
              <Row>
                <TotalVendors
                  report={vendorReport}
                  dashboardAnalytics={dashboardAnalytics}
                />
                <TotalProperties
                  report={totalProperties}
                  dashboardAnalytics={dashboardAnalytics}
                />

                <TotalMeetings
                  report={totalMeetings}
                  dashboardAnalytics={dashboardAnalytics}
                />

                <TotalAgreement
                  report={totalAgreement}
                  dashboardAnalytics={dashboardAnalytics}
                />
              </Row>
            </Col>
          </Row>
          <Home dashboardAnalytics={dashboardAnalytics} />
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
