import React, { useState, useRef, useEffect } from "react"
import * as XLSX from "xlsx"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { toast } from "react-toastify"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"

const acceptedFileName = ["xlsx", "xls"]

function BoqImport({
  file,
  setFile,
  fileName,
  setFileName,
  setLoading,
  getBOQLayoutData,
}) {
  const [sheetNames, setSheetNames] = useState([])
  const [sheetData, setSheetData] = useState({})

  const checkFileName = name => {
    return acceptedFileName.includes(name.split(".").pop().toLowerCase())
  }

  const readDataFromExcel = data => {
    const wb = XLSX.read(data)
    setSheetNames(wb.SheetNames)
    var mySheetData = {}

    for (let i = 0; i < wb.SheetNames.length; i++) {
      let sheetName = wb.SheetNames[i]
      const worksheet = wb.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(worksheet)
      mySheetData[sheetName] = jsonData
    }
    setSheetData(mySheetData)

    return mySheetData
  }

  const handleFile = async e => {
    const myFile = e.target.files[0]
    if (!myFile) return

    if (!checkFileName(myFile.name)) {
      toast.error("Invalid file type")
      return
    }
    const data = await myFile.arrayBuffer()
    const mySheetData = readDataFromExcel(data)
    setFile(myFile)
    setFileName(myFile.name)
  }

  const handleRemove = () => {
    setFile(null)
    setFileName(null)
    let inputRef = document.querySelector("#upload-excel")
    inputRef.value = null
  }

  const uploadPropertyLayout = async file => {
    setLoading(true)
    const formData = new FormData()
    formData.append("xlsx", file)
    try {
      const res = await axios.post(URL.UPLOAD_BOQ_LAYOUT, formData)
      if (res.data.success) {
        handleRemove()
        setLoading(false)
        getBOQLayoutData()
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      handleRemove()
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    if (file !== null) {
      uploadPropertyLayout(file)
    }
  }, [file])

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <div className="mb-3">
              {fileName && (
                <Label className="me-2">File Name: {fileName}</Label>
              )}
              {!fileName && <Label htmlFor="upload-excel">Upload Excel</Label>}
              {fileName && (
                <i
                  className="fs-4 cursor-pointer mdi mdi-close-circle-outline"
                  onClick={handleRemove}
                />
              )}

              <div className="dropzone cursor-pointer">
                <Label
                  htmlFor="upload-excel"
                  className="dz-message needsclick mt-2"
                >
                  <Input
                    type="file"
                    accept="xlsx, xls"
                    multiple={false}
                    placeholder="Enter Heading"
                    id="upload-excel"
                    onChange={e => handleFile(e)}
                    style={{ visibility: "hidden" }}
                  />
                  <div className="mb-3" style={{ cursor: "pointer" }}>
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4 style={{ cursor: "pointer" }}>Click to upload.</h4>
                </Label>
              </div>
              <Label className="font-size-12 text-gray">
                (Select xlsx or xls sheet)
              </Label>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BoqImport
