import React, { useState, useEffect } from "react"
import Select from "react-select"
import axios from "../../helpers/api_helper"
import URL from "../../helpers/url_helper"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

function AddAdminMenu() {
  document.title = "Add Menu"
  const history = useHistory()
  const [menuList, setMenuList] = useState([])
  const [menuOption, setMenuOption] = useState("menu")
  const [menuId, setMenuId] = useState(null)
  const [menuData, setMenuData] = useState({
    menuName: "",
    menuLink: "",
  })

  const { menuName, menuLink } = menuData
  const [isDisabled, setIsDisabled] = useState(true)

  const getMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_MENU)
      setMenuList(res.data.menus)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMenu()
  }, [])

  const onChange = e => {
    setMenuData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const postMenu = async () => {
    try {
      const res = await axios.post(URL.ADD_MENU, {
        menu_or_submenu: menuOption,
        menu: menuOption === "menu" ? undefined : { id: menuId },
        name: menuName,
        link: menuLink,
      })
      if (res.data.success) {
        history.push("/all-admin-menu")
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const menu_Options = [
    { id: 1, name: "menu", label: "Menu" },
    { id: 2, name: "sub_menu", label: "Sub Menu" },
  ]

  const handleGoBack = () => {
    history.push("/all-admin-menu")
  }

  useEffect(() => {
    if (menuName.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [menuName, menuLink])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                Add Menu
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={postMenu}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  onClick={handleGoBack}
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label>Select Menu or Sub Menu</Label>
                    <Select
                      disablePortal
                      id="combo-box-demo"
                      options={menu_Options}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      renderInput={params => (
                        <Input
                          {...params}
                          placeholder="Select Menu or Sub Menu"
                        />
                      )}
                      onChange={event => setMenuOption(event.name)}
                    />
                  </div>

                  {menuOption === "menu" ? (
                    <div className="mb-3">
                      <Label>Menu Name</Label>
                      <Input
                        name="menuName"
                        value={menuName}
                        onChange={onChange}
                        label="Menu Name"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="mb-3">
                        <label>Select Menu</label>
                        <Select
                          disablePortal
                          id="combo-box_demo"
                          options={menuList}
                          getOptionLabel={option => `${option.label}`}
                          getOptionValue={option => `${option.id}`}
                          renderInput={params => (
                            <Input {...params} placeholder="Select Menu" />
                          )}
                          onChange={event => setMenuId(event.id)}
                        />
                      </div>
                      <div className="mb-3">
                        <label>Sub Menu Name</label>
                        <Input
                          name="menuName"
                          value={menuName}
                          onChange={onChange}
                          id="outlined-basic"
                          placeholder="Sub Menu Name"
                          variant="outlined"
                        />
                      </div>
                    </>
                  )}

                  <div className="mb-3">
                    <Label>Link</Label>
                    <Input
                      type="link"
                      name="menuLink"
                      value={menuLink}
                      onChange={onChange}
                      className="form-control"
                      id="formrow-heading-Input"
                      placeholder="Link"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddAdminMenu
