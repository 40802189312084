import React, { useEffect } from "react"
import { Label, Input } from "reactstrap"

const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

const SmallImage = ({
  sm_image,
  secondFile,
  setSecondFile,
  secondDataURL,
  setSecondDataURL,
  setCalculatorItem,
}) => {
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (secondFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setSecondDataURL(result)
        }
      }
      fileReader.readAsDataURL(secondFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [secondFile])

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setCalculatorItem(prevState => ({
        ...prevState,
        sm_image: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setSecondFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#calculatorIcon")
    temp.value = null
    setSecondDataURL(null)
    setCalculatorItem(prevState => ({
      ...prevState,
      sm_image: [],
    }))
  }

  return (
    <div className="mb-3">
      <Label>Small Size Image</Label>
      <div className="img-container">
        {sm_image !== null && Object?.keys(sm_image).length > 0 && (
          <div className="media-container">
            <div className="media-main">
              <img
                className="media-img"
                src={`${sm_image.image_path}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${sm_image.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt="Image"
                loading="lazy"
              />
              <i
                className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                onClick={handleRemoveMedia}
              />
            </div>
          </div>
        )}

        {secondDataURL && (
          <div className="media-container">
            <div className="media-main">
              <img
                className="media-img"
                src={secondDataURL}
                srcSet={secondDataURL}
                alt="Banner"
                loading="lazy"
              />
              <i
                className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                onClick={handleRemoveMedia}
              />
            </div>
          </div>
        )}
      </div>

      <Input
        id="calculatorIcon"
        type="file"
        name="img"
        placeholder="Choose image"
        accept="image/*"
        onChange={handleMedia}
      />
    </div>
  )
}

export default SmallImage
