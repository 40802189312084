import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function ExperienceFirstSection({
  experienceCenterData,
  setExperienceCenterData,
  firstFile,
  setFirstFile,
  firstDataURL,
  setFirstDataURL,
}) {
  const { first_section_heading, first_section_desc, first_section_file } =
    experienceCenterData

  console.log(first_section_desc)

  const handleChange = e => {
    setExperienceCenterData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let url = first_section_file.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setExperienceCenterData(prevState => ({
        ...prevState,
        first_section_file: img,
      }))
    }
    const file = e.target.files[0]
    setFirstFile(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL(result)
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile1")
    temp.value = null
    setFirstDataURL(null)
    setExperienceCenterData(prevState => ({
      ...prevState,
      first_section_file: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <CardTitle className="h4 py-3">First Section</CardTitle>
        <Card>
          <CardBody>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="first_section_heading"
                onChange={handleChange}
                value={first_section_heading || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <Row>
              <Col>
                <Label htmlFor="formrow-heading-Input">Description</Label>
                <Card>
                  <CKEditor
                    editor={ClassicEditor}
                    data={first_section_desc}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setExperienceCenterData(prevState => ({
                        ...prevState,
                        first_section_desc: data,
                      }))
                    }}
                  />
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Label>Upload Image</Label>
                <div className="row">
                  <Col sm={12}>
                    <div className="mt-3">
                      <div className="img-container p-2">
                        {Object.keys(first_section_file).length > 0 &&
                          url !== "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={`${first_section_file.image_path}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${first_section_file.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt="Image"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {firstDataURL && firstFile?.type?.includes("image") && (
                          <div className="media-container">
                            <div className="media-main">
                              <img
                                className="media-img"
                                src={firstDataURL}
                                srcSet={firstDataURL}
                                alt="Banner"
                                loading="lazy"
                              />
                              <div>
                                <i
                                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                  onClick={handleRemoveMedia}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <Col sm={4} className="mt-5">
                        <Input
                          name="file"
                          accept="image/*"
                          type="file"
                          onChange={handleMedia}
                          multiple
                          id="formFile1"
                        />
                      </Col>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ExperienceFirstSection
