import React, { useState, useEffect } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import {
  CardBody,
  Container,
  Col,
  Button,
  Card,
  Input,
  Label,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"

const CostCirculationDetails = () => {
  document.title = "Manage Cost & Circulation Details"
  const [costCirculation, setCostCirculation] = useState({
    circulation: 0,
    semi_conventional_cost_1: 0,
    conventional_cost_1: 0,
    modern_cost_2: 0,
    semi_conventional_cost_2: 0,
    conventional_cost_2: 0,
  })

  const {
    circulation,
    modern_cost_1,
    semi_conventional_cost_1,
    conventional_cost_1,
    modern_cost_2,
    semi_conventional_cost_2,
    conventional_cost_2,
  } = costCirculation

  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    setCostCirculation(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleChange = e => {
    if (e.target.value.length > 8) {
      return
    }
    setCostCirculation(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  var invalidChars = ["-", "e", "+", "E"]

  const onKeyDown = e => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const getCostCirculationDetails = async () => {
    try {
      const res = await axios.get(URL.ALL_COST_AND_CIRCULATION)
      if (res.data.success) {
        setCostCirculation({
          circulation: res.data.data.name,
          modern_cost_1: res.data.data.modern,
          semi_conventional_cost_1: res.data.data.semiConventional,
          conventional_cost_1: res.data.data.conventional,
          modern_cost_2: res.data.data.m_modern,
          semi_conventional_cost_2: res.data.data.m_semiConventional,
          conventional_cost_2: res.data.data.m_conventional,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCostCirculationDetails()
  }, [])

  const updateCostCirculationDetails = async () => {
    try {
      const res = await axios.put(URL.UPDATE_COST_AND_CIRCULATION, {
        name: circulation,
        modern: parseInt(modern_cost_1),
        semiConventional: parseInt(semi_conventional_cost_1),
        conventional: parseInt(conventional_cost_1),
        m_modern: parseInt(modern_cost_2),
        m_semiConventional: parseInt(semi_conventional_cost_2),
        m_conventional: parseInt(conventional_cost_2),
      })
      if (res.data.success) {
        getCostCirculationDetails()
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (
      circulation.length > 0 &&
      modern_cost_1 > 0 &&
      semi_conventional_cost_1 > 0 &&
      conventional_cost_1 > 0 &&
      modern_cost_2 > 0 &&
      semi_conventional_cost_2 > 0 &&
      conventional_cost_2 > 0
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [costCirculation])

  console.log(circulation)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col sm={11}>
              <div className="h4 card-title  font-size-22">
                Manage Cost and Circulation Details
              </div>
            </Col>

            <Col sm={1}>
              <Button
                disabled={isDisabled}
                onClick={updateCostCirculationDetails}
                className={
                  isDisabled
                    ? `btn btn-rounded w-sm mb-4 float-end`
                    : `btn btn-rounded w-sm mb-4 btn-info float-end`
                }
              >
                Save
              </Button>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="mb-3">
                <Label>Circulation</Label>
                <Input
                  name="circulation"
                  label="Circulation"
                  onChange={onChange}
                  value={circulation || ""}
                />
              </div>
              <div className="mb-3">
                <Label>Modern Cost (Per Sqft)</Label>
                <Input
                  type="number"
                  name="modern_cost_1"
                  label="Modern Cost (Per Sqft)"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  value={modern_cost_1 || ""}
                />
              </div>
              <div className="mb-3">
                <Label>Semi Conventional Cost (Per Sqft)</Label>
                <Input
                  type="number"
                  name="semi_conventional_cost_1"
                  label="Semi Conventional Cost (Per Sqft)"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  value={semi_conventional_cost_1 || ""}
                />
              </div>
              <div className="mb-3">
                <Label>Conventional Cost (Per Sqft)</Label>
                <Input
                  type="number"
                  name="conventional_cost_1"
                  label="Conventional Cost (Per Sqft)"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  value={conventional_cost_1 || ""}
                />
              </div>
              <div className="mb-3">
                <Label>Modern Cost (Per Sqmt)</Label>
                <Input
                  type="number"
                  name="modern_cost_2"
                  label="Modern Cost (Per Sqmt)"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  value={modern_cost_2 || ""}
                />
              </div>
              <div className="mb-3">
                <Label>Semi Conventional Cost (Per Sqmt)</Label>
                <Input
                  type="number"
                  name="semi_conventional_cost_2"
                  label="Semi Conventional Cost (Per Sqmt)"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  value={semi_conventional_cost_2 || ""}
                />
              </div>
              <div className="mb-3">
                <Label>Conventional Cost (Per Sqmt)</Label>
                <Input
                  type="number"
                  name="conventional_cost_2"
                  label="Conventional Cost (Per Sqmt)"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  value={conventional_cost_2 || ""}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CostCirculationDetails
