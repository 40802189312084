import axios from "../helpers/api_helper"
import URL from "../helpers/url_helper"
import { toast } from "react-toastify"

export const getManageHomeData = async (
  manageHomeData,
  setManageHomeData,
  setLoading
) => {
  setLoading(true)
  try {
    const res = await axios.get(URL.MANAGE_HOME)
    if (res.data.success) {
      setManageHomeData({
        banner_heading: res.data.data.banner_heading,
        banner_subheading: res.data.data.banner_subheading,
        first_button_name: res.data.data.first_button_name,
        first_button_link: res.data.data.first_button_link,
        second_button_name: res.data.data.second_button_name,
        second_button_link: res.data.data.second_button_link,
        home_banner: res.data.data.banner_images,
        first_section_heading: res.data.data.first_section_heading,
        first_section_desc: res.data.data.first_section_desc,
        first_section_file: res.data.data.first_section_file,
        second_section_heading: res.data.data.second_section_heading,
        second_section_desc: res.data.data.second_section_desc,
        second_section_file: res.data.data.second_section_file,
        third_section_heading: res.data.data.third_section_heading,
        third_section_desc: res.data.data.third_section_desc,
        third_section_file: res.data.data.third_section_file,
        fourth_section_heading: res.data.data.fourth_section_heading,
        fourth_section_desc: res.data.data.fourth_section_desc,
        fourth_section_file: res.data.data.fourth_section_file,
        fifth_section_heading: res.data.data.fifth_section_heading,
        fifth_section_desc: res.data.data.fifth_section_desc,
        fifth_section_file: res.data.data.fifth_section_file,
        why_partner_heading: res.data.data.why_partner_heading,
        partner_section_image: res.data.data.partner_section_image,
        clientele_heading: res.data.data.clientele_heading,
        contact_us_head: res.data.data.contact_us_head,
        contact_us_subhead: res.data.data.contact_us_subhead,
        home_gallery: res.data.data.gallery_images,
        removed_banner_ids: [],
        removed_gallery_ids: [],
      })
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  } catch (error) {
    setLoading(false)
    toast.error(error)
    if (error.response) {
      toast.error(error.response.data.message)
    } else if (error.request) {
    } else {
      toast.error(error)
    }
  }
}

export const postManageHomeData = async (
  manageHomeData,
  setManageHomeData,
  setLoading
) => {
  setLoading(true)
  let formData = new FormData()
  Object.keys(manageHomeData).forEach(fieldName => {
    if (manageHomeData[fieldName]) {
      if (fieldName === "home_banner" || fieldName === "home_gallery") {
        manageHomeData[fieldName].forEach(el => {
          formData.append(fieldName, el)
        })
      } else if (
        fieldName === "removed_banner_ids" ||
        fieldName === "removed_gallery_ids"
      ) {
        formData.append(fieldName, JSON.stringify(manageHomeData[fieldName]))
      } else {
        formData.append(fieldName, manageHomeData[fieldName])
      }
    }
  })

  try {
    const res = await axios.put(URL.UPDATE_MANAGE_HOME, formData)
    if (res.data.success) {
      getManageHomeData(manageHomeData, setManageHomeData, setLoading)
      toast.success(res.data.message)
    }
    setLoading(false)
  } catch (error) {
    toast.error(error)
    setLoading(false)
    if (error.response) {
      toast.error(error.response.data.message)
    } else if (error.request) {
    } else {
      toast.error(error)
    }
  }
}
