import React, { useState, useEffect } from "react"
import { Container, Col, Input, Row } from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import "flatpickr/dist/themes/material_blue.css"
import { DatePicker } from "antd"
import moment from "moment/moment"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function LoginDataList() {
  document.title = "Vendor Login Data"
  const [search, setSearch] = useState("")
  const [loginDataList, setLoginDataList] = useState([])
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [filteredLoginData, setFilteredLoginDataList] = useState([])

  const getLoginDataList = async () => {
    try {
      const res = await axios.get(URL.LOGIN_DATA + `?from=${from}&to=${to}`)
      if (res.data.success) {
        console.log(res)
      }
      setLoginDataList(res.data.data)
      setFilteredLoginDataList(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLoginDataList()
  }, [from, to])

  const columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "No. of logins",
      selector: row => row.num_of_logins,
      sortable: true,
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = loginDataList.filter(item => {
      return item.name.toLowerCase().match(search.toLowerCase())
    })

    setFilteredLoginDataList(result)
  }, [search])

  const handleFrom = (date, dateString) => {
    let newDate = moment(dateString).format("MM-DD-YYYY")
    setFrom(newDate)
  }

  const handleTo = (date, dateString) => {
    let newDate = moment(dateString).format("MM-DD-YYYY")
    // console.log(date, dateString)
    setTo(newDate)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row className="py-4 px-2">
          <div className="draggable-body-button">
            <Col sm={6}>
              <p className="h4 card-title font-size-22">Vendor Login Data</p>
            </Col>
            <Col sm={3}>
              <DatePicker
                className="w-100 "
                placeholder="From"
                onChange={handleFrom}
                disabledDate={d => !d || d.isAfter(to)}
              />
            </Col>
            <Col sm={3} className="ms-1">
              <DatePicker
                className="w-100"
                placeholder="To"
                onChange={handleTo}
                disabledDate={d => !d || d.isSameOrBefore(from)}
              />
            </Col>
          </div>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredLoginData}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  id="table-search"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LoginDataList
