import React, { useState, useEffect } from "react"

import axios from "../../helpers/api_helper"
import URL from "../../helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"
import { toast } from "react-toastify"
import CheckboxTree from "react-checkbox-tree"
import "react-checkbox-tree/lib/react-checkbox-tree.css"

function AddRole() {
  const { state } = useLocation()
  document.title = state ? "Edit Role" : "Add Role"
  const history = useHistory()
  const [roleName, setRoleName] = useState(state ? state.state.name : "")
  const [roles, setRoles] = useState([])
  const [checked, setChecked] = useState([])
  const [expanded, setExpanded] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)

  const getAllMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_MENU)
      if (res.data.success) {
        setRoles(res.data.menus)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSelectedMenu = async () => {
    try {
      const res = await axios.get(URL.MENU_BY_ROLE + `/${state.state.id}`)
      if (res.data.success) {
        setChecked(res.data.selectedFields)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllMenu()
  }, [])

  useEffect(() => {
    if (state) {
      getSelectedMenu()
    }
  }, [])

  useEffect(() => {
    if (roleName.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [roleName])

  const onChange = e => {
    setRoleName(e.target.value)
  }

  var result = []
  const genFinalData = () => {
    roles.map(node => {
      let submenu_ids = node.children.map(sub => {
        if (checked.includes(sub.label)) {
          return sub.id
        }
        return
      })
      submenu_ids = submenu_ids.filter(el => typeof el === "number")
      if (!submenu_ids.length) {
        if (checked.includes(node.label)) {
          result.push({
            menu_id: node.id,
            sub_menu_ids: [],
          })
        }
      } else {
        result.push({
          menu_id: node.id,
          sub_menu_ids: submenu_ids,
        })
      }
      return
    })
    console.log(result)
  }

  const addRole = async () => {
    try {
      const res = await axios.post(URL.ADD_ROLE, {
        name: roleName,
        menu_available: result,
      })
      if (res.data.success) {
        toast.success("Role added")
        history.push("/all-role-management")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateRole = async () => {
    try {
      const res = await axios.put(URL.UPDATE_ROLE + `/${state.state.id}`, {
        name: roleName,
        menu_available: result,
      })

      if (res.data.success) {
        toast.success("Role Updated")
        history.push("/all-role-management")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Role" : "Add Role"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                  onClick={() => {
                    genFinalData()
                    {
                      state ? updateRole() : addRole()
                    }
                  }}
                >
                  Save
                </Button>
                <Button
                  onClick={handleGoBack}
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Name</Label>
                    <Input
                      type="text"
                      name="banner_heading"
                      value={roleName}
                      onChange={onChange}
                      className="form-control"
                      id="formrow-heading-Input"
                      placeholder="Enter Name"
                    />
                  </div>

                  <CheckboxTree
                    nodes={roles}
                    checked={checked}
                    expanded={expanded}
                    onCheck={checked => setChecked(checked)}
                    onExpand={expanded => setExpanded(expanded)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddRole
