import React, { useState, useEffect } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import ClienteleImage from "./ClienteleImage"
import {
  CardBody,
  Col,
  Input,
  Label,
  Card,
  Container,
  Row,
  Button,
} from "reactstrap"
import Select from "react-select"

function Clientele() {
  document.title = "Add Clientele"
  const history = useHistory()
  const { state } = useLocation()
  const [partnerStatus, setPartnerStatus] = useState(null)
  const [dropdownValue, setDropdownValue] = useState("")

  const [clientele, setClientele] = useState({
    category: state ? state?.state?.category : "",
    title: state ? state?.state?.title : "",
    image: state ? state?.state?.image : "",
  })

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  const { category, title, image } = clientele

  const onChange = e => {
    setClientele(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addTestimonial = async () => {
    let formData = new FormData()
    formData.append("title", title)
    formData.append("category", partnerStatus)
    formData.append("image", image)
    try {
      const res = await axios.post(URL.ADD_CLIENTELE, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/clientele")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateTestimonial = async () => {
    let formData = new FormData()
    Object.keys(clientele).forEach(fieldName => {
      if (clientele[fieldName]) {
        if (fieldName === "category") {
          formData.append(fieldName, partnerStatus)
        } else {
          formData.append(fieldName, clientele[fieldName])
        }
      }
    })
    try {
      const res = await axios.put(
        URL.UPDATE_CLIENTELE + `${state.state.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/clientele")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const [statusOptions, setStatusOptions] = useState([
    {
      id: 1,
      name: "Developers",
      category: "Developers",
    },
    {
      id: 2,
      name: "RE Partners",
      category: "RE_Partners",
    },
    {
      id: 3,
      name: "Clients",
      category: "Clients",
    },
  ])

  useEffect(() => {
    let temp = statusOptions.find(el => el.category === category)
    setDropdownValue(temp)
    setPartnerStatus(category)
  }, [category])

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="py-4">
              <Col
                lg={12}
                className="d-flex justify-content-between align-items-center"
              >
                <p className="h4 card-title font-size-22">
                  {state ? "Edit Clientele" : "Add Clientele"}
                </p>
                <div className="d-flex flex-wrap gap-2 float-end">
                  <Button
                    onClick={state ? updateTestimonial : addTestimonial}
                    className="btn btn-info btn-rounded float-end w-sm"
                  >
                    Save
                  </Button>

                  <Button
                    className="btn btn-rounded float-end w-sm"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </div>
          </Row>
          <Card>
            <CardBody>
              <div className="mb-3">
                <Label>Status</Label>
                <Select
                  disablePortal
                  id="combo-box-demo"
                  value={dropdownValue}
                  options={statusOptions}
                  getOptionLabel={option => `${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  onChange={event => {
                    setDropdownValue(event)
                    setPartnerStatus(event.category)
                  }}
                />
              </div>

              <div className="mb-3">
                <Label>Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={onChange}
                  id="outlined-basic"
                  placeholder="Title"
                  variant="outlined"
                />
              </div>

              <ClienteleImage
                image={image}
                firstFile={firstFile}
                setFirstFile={setFirstFile}
                firstDataURL={firstDataURL}
                setFirstDataURL={setFirstDataURL}
                setClientele={setClientele}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Clientele
