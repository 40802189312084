import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Input, Col, Row } from "reactstrap"
import DataTable from "react-data-table-component"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function VendorAnalytics({ dashboardAnalytics }) {
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [vendorAnalytics, setVendorAnalytics] = useState(
    dashboardAnalytics?.vendorAnalytics
  )
  const [filteredVendorAnalytics, setFilteredVendorList] = useState(
    dashboardAnalytics?.vendorAnalytics
  )

  useEffect(() => {
    setVendorAnalytics(dashboardAnalytics?.vendorAnalytics)
    setFilteredVendorList(dashboardAnalytics?.vendorAnalytics)
  }, [dashboardAnalytics])

  const columns = [
    {
      name: "ID",
      selector: row => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: row => row.name,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Number",
      selector: row => row.number,
    },
    {
      name: "Listed Property",
      selector: row => row.listedProperty,
    },
    {
      name: "Meetings",
      selector: row => row.meetings,
    },
    {
      name: "Agreements",
      selector: row => row.agreements,
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = vendorAnalytics?.filter(item => {
      return item?.name?.toLowerCase().match(search?.toLowerCase())
    })

    setFilteredVendorList(result)
  }, [search])

  const handleEditClientele = row => {
    history.push("/add-vendor", { state: row })
  }

  return (
    <React.Fragment>
      <Row className="py-4 px-2">
        <div className="draggable-body-button">
          <Col sm={12}>
            <p className="h4 card-title font-size-22">Vendor Analytics</p>
          </Col>
        </div>
      </Row>
      <DataTable
        columns={columns}
        data={filteredVendorAnalytics}
        fixedHeader
        pagination
        selectableRowsHighlight
        highlightOnHover
        fixedHeaderScrollHeight="500px"
        subHeader
        customStyles={customStyles}
        subHeaderComponent={
          <Col className="pt-4 data-table">
            <i className="table-search-icon bx bx-search-alt font-size-22" />
            <Input
              label="Search"
              placeholder="Search"
              id="table-search"
              value={search}
              onChange={handleSearch}
            />
          </Col>
        }
        subHeaderAlign="left"
      />
    </React.Fragment>
  )
}

export default VendorAnalytics
