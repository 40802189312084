import React, { useEffect } from "react"
import { Col, Row, Label, Input } from "reactstrap"

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm

function InfoMapImage({
  images,
  setImages,
  imageFiles,
  setImageFiles,
  infoList,
  setInfoList,
  removed_banner_ids,
  set_Removed_banner_ids,
}) {
  const {
    // removed_banner_ids,
    banner,
  } = infoList

  const handleMedia = e => {
    const { files } = e.target
    const validImageFiles = []
    if (e.target.files) {
      let img = e.target.files
      setInfoList(prevState => ({
        ...prevState,
        banner: [...img, ...banner],
      }))
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file)
      }
    }
    if (validImageFiles.length) {
      setImageFiles([...imageFiles, ...validImageFiles])
      return
    }
    alert("Selected images are not of valid type!")
  }

  const handleRemoveMedia = item => {
    let cmp = document.querySelector("#formFile1")
    cmp.value = null
    let temp = banner
    temp = temp.filter(el => el.id !== item.id)
    setInfoList(prevState => ({
      ...prevState,
      banner: temp,
    }))

    set_Removed_banner_ids([...removed_banner_ids, item.id])
  }

  const handleRemove = (item, index) => {
    let val = document.querySelector("#formFile1")
    val.value = null
    let fileToRemove = imageFiles[index]
    const temp = images.filter((el, idx) => idx !== index)

    let temp_ = banner?.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })
    let temp__ = imageFiles?.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })
    setInfoList(prevState => ({
      ...prevState,
      banner: [...temp_],
    }))
    setImages([...temp])
    setImageFiles([...temp__])
  }

  useEffect(() => {
    const fileReaders = []
    let isCancel = false
    if (imageFiles?.length) {
      const promises = imageFiles.map(file => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReaders.push(fileReader)
          fileReader.onload = e => {
            const { result } = e.target
            if (result) {
              resolve(result)
            }
          }
          fileReader.onabort = () => {
            reject(new Error("File reading aborted"))
          }
          fileReader.onerror = () => {
            reject(new Error("Failed to read file"))
          }
          fileReader.readAsDataURL(file)
        })
      })
      Promise.all(promises)
        .then(images => {
          if (!isCancel) {
            setImages([...images])
          }
        })
        .catch(reason => {
          console.log(reason)
        })
    }
    return () => {
      isCancel = true
      fileReaders?.forEach(fileReader => {
        if (fileReader?.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles])

  return (
    <Row>
      <Col sm={12}>
        <div className="h4 py-3 card-title font-size-14">Image</div>
        <Row>
          <Col className="col-12">
            <div className="row">
              <Col sm={12}>
                <div>
                  <div
                    className={
                      banner !== undefined && banner?.length > 0
                        ? `img-container mb-5`
                        : `img-container `
                    }
                  >
                    {banner !== undefined &&
                      banner?.length > 0 &&
                      banner?.map(item => {
                        return (
                          item?.id && (
                            <div key={item.id} className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={`${item.image}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt="Banner"
                                  loading="lazy"
                                />
                                <i
                                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                  onClick={() => {
                                    handleRemoveMedia(item)
                                  }}
                                />
                              </div>
                            </div>
                          )
                        )
                      })}

                    {images?.length > 0 &&
                      images?.map((image, index) => {
                        return (
                          <div key={index} className="media-container">
                            <div className="media-main">
                              <img
                                className="media-img"
                                src={image}
                                srcSet={image}
                                alt="Banner"
                                loading="lazy"
                              />
                              <i
                                className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                onClick={() => handleRemove(image, index)}
                              />
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  <Col sm={4}>
                    <Input
                      name="file"
                      accept="image/*"
                      type="file"
                      onChange={handleMedia}
                      multiple
                      id="formFile1"
                    />
                    <Label className="font-size-12 text-gray">
                      (You Can Select Multiple Images)
                    </Label>
                  </Col>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default InfoMapImage
