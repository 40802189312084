import React from "react"

import { Col, Card, CardBody, CardTitle, CardImg, CardText } from "reactstrap"

const HotPropertyCards = ({ currElem }) => {
  return (
    <React.Fragment>
      <Col lg={4}>
        <Card>
          <CardBody>
            <CardTitle className="mt-0">{currElem.property}</CardTitle>
            <CardText style={{ height: "100px" }}>
              {currElem.property_address}
            </CardText>
            <CardText>
              <small className="text-muted font-size-14">
                <span className="badge badge-soft-success font-size-14 me-2">
                  {currElem.num_of_views}
                </span>
                Views this week
              </small>
            </CardText>
          </CardBody>
          <CardImg
            style={{ height: "220px", objectFit: "contain", padding: "20px" }}
            src={currElem.property_image}
            alt="Skote"
          />
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default HotPropertyCards
