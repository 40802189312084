import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory, useLocation } from "react-router-dom"
import {
  CardBody,
  Container,
  Input,
  Row,
  Col,
  Button,
  Card,
  Label,
  CardTitle,
} from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "components/Common/Breadcrumb"

function VendorSubSubMenu() {
  document.title = "Add Vendor Sub Menu"
  const history = useHistory()
  const { state } = useLocation()
  const [vendor, setVendor] = useState({
    name: state ? state?.state?.name : "",
    menu: state ? state?.state?.sub_menu?.menu?.name : "",
    subMenu: state ? state?.state?.sub_menu?.name : "",
  })

  const { subMenu, name, menu } = vendor
  const [subDropdownValue, setSubDropdownValue] = useState("")
  const [subMenuOptions, setSubMenuOptions] = useState([])
  const [menuOptions, setMenuOptions] = useState([])
  const [dropdownValue, setDropdownValue] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setVendor(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const getVendorMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_VENDOR_MENU)
      if (res.data.success) {
        setMenuOptions(res.data.menus)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorMenu()
  }, [])

  const addVendorSubMenu = async () => {
    try {
      const res = await axios.post(URL.ADD_VENDOR_SUB_SUB_MENU, {
        name: name,
        menu_id: dropdownValue?.id,
        sub_menu_id: subDropdownValue?.id ? subDropdownValue?.id : null,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/vendor-sub-sub-menu")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateVendorSubMenu = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_VENDOR_SUB_SUB_MENU + `/${state.state.id}`,
        {
          name: name,
          menu_id: dropdownValue?.id,
          sub_menu_id: subDropdownValue?.id ? subDropdownValue?.id : null,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/vendor-sub-sub-menu")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setSubMenuOptions(
        dropdownValue?.vendor_submenu ? dropdownValue?.vendor_submenu : []
      )
    }, 300)
  })

  useEffect(() => {
    let temp = menuOptions.find(el => el.name === menu)
    setDropdownValue(temp)
  }, [menu, menuOptions.length])

  useEffect(() => {
    let temp = subMenuOptions.find(el => el.name === subMenu)
    setSubDropdownValue(temp)
  }, [subMenu, subMenuOptions.length])

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (
      dropdownValue !== undefined &&
      subDropdownValue !== undefined &&
      name.length > 0
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [name, dropdownValue, subDropdownValue])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Vendor Sub Menu" : "Add Vendor Sub Menu"}
              </CardTitle>

              <div className="d-flex flex-wrap float-end gap-2">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateVendorSubMenu : addVendorSubMenu}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Col component="form" noValidate autoComplete="off">
                <div className="mb-3">
                  <Label>Menu</Label>
                  <Select
                    disablePortal
                    id="combo-box-demo"
                    value={dropdownValue}
                    options={menuOptions}
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => `${option.id}`}
                    onChange={event => {
                      setDropdownValue(event)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label>Sub Menu</Label>
                  <Select
                    disablePortal
                    id="combo-box-demo"
                    value={subDropdownValue}
                    options={subMenuOptions}
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => `${option.id}`}
                    renderInput={params => (
                      <Input {...params} label="Sub Menu" />
                    )}
                    onChange={event => {
                      setSubDropdownValue(event)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label>Sub Sub Menu</Label>
                  <Input
                    name="name"
                    value={name}
                    onChange={onChange}
                    id="outlined-basic"
                    label="Sub Sub Menu"
                    variant="outlined"
                  />
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorSubSubMenu
