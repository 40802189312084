import React, { useState, useEffect } from "react"
import { Input, Col, Row } from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import Select from "react-select"
import moment from "moment"
import { DatePicker } from "antd"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function Agreement() {
  const [search, setSearch] = useState("")
  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [agreementList, setAgreementList] = useState([])
  const [filteredAgreementList, setFilteredAgreementList] = useState([])

  const getAgreementData = async () => {
    try {
      const res = await axios.get(
        URL.AGREEMENT_ANALYTICS +
          `?minDate=${minDate === null ? "all" : minDate}&maxDate=${
            maxDate === null ? "all" : maxDate
          }&vendor_id=${
            vendorDropdown.id === undefined ? "All" : vendorDropdown.id
          }`
      )
      setAgreementList(res.data?.data)
      setFilteredAgreementList(res.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getVendorParent = async () => {
    let vendorOptions = []
    try {
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let obj = {
          id,
          name,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAgreementData()
  }, [vendorDropdown, minDate, maxDate])

  useEffect(() => {
    getVendorParent()
  }, [])

  const columns = [
    {
      name: "ID",
      selector: row => row.id,
      sortable: true,
    },
    {
      name: "Vendor Name",
      selector: row => row.vendor_name,
      sortable: true,
    },
    {
      name: "Agreement Type",
      selector: row => row.agreement_type,
      sortable: true,
    },
    {
      name: "Title",
      selector: row => row.title,
    },
    {
      name: "Created At",
      selector: row => moment(row.created_at).format("L"),
    },
    {
      name: "Shared Date",
      selector: row => row.shared_date,
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = agreementList.filter(item => {
      return item.vendor_name.toLowerCase().match(search.toLowerCase())
    })

    setFilteredAgreementList(result)
  }, [search])

  const handleMaxDate = (date, dateString) => {
    let newDate = moment(dateString).format("MM-DD-yyyy")
    setMaxDate(newDate)
  }

  const handleMinDate = (date, dateString) => {
    let newDate = moment(dateString).format("MM-DD-yyyy")
    setMinDate(newDate)
  }

  return (
    <div className="mt-5">
      <Row className="py-2 px-2">
        <div className="draggable-body-button">
          <Col sm={12}>
            <p className="h4 card-title font-size-22">Agreements</p>
          </Col>
        </div>
      </Row>

      <div className="draggable-body-button py-3">
        <Col sm={3}>
          <Select
            value={vendorDropdown}
            options={vendorList}
            getOptionLabel={option => `${option.name}`}
            getOptionValue={option => `${option.id}`}
            placeholder="Select Vendor"
            onChange={(event, val) => {
              setVendorDropdown(event)
            }}
          />
        </Col>
        <Col sm={3}>
          <DatePicker
            className="w-100"
            placeholder="Min Date"
            onChange={handleMinDate}
            disabledDate={d => !d || d.isAfter(maxDate)}
          />
        </Col>
        <Col sm={3} className="ms-1">
          <DatePicker
            className="w-100 "
            placeholder="Max Date"
            onChange={handleMaxDate}
            disabledDate={d => !d || d.isSameOrBefore(minDate)}
          />
        </Col>
      </div>

      <DataTable
        title=""
        columns={columns}
        data={filteredAgreementList}
        fixedHeader
        pagination
        selectableRowsHighlight
        highlightOnHover
        fixedHeaderScrollHeight="500px"
        subHeader
        customStyles={customStyles}
        subHeaderComponent={
          <Col className="pt-4 data-table">
            <i className="table-search-icon bx bx-search-alt font-size-22" />
            <Input
              label="Search"
              placeholder="Search"
              id="table-search"
              value={search}
              onChange={handleSearch}
            />
          </Col>
        }
        subHeaderAlign="left"
      />
    </div>
  )
}

export default Agreement
