import React from "react"
import { useHistory } from "react-router-dom"
import { Col, Card, CardBody } from "reactstrap"

const MonthlyUsers = ({ report, loginDataList }) => {
  const history = useHistory()
  const onClick = () => {
    history.push("/vendors")
  }

  return (
    <React.Fragment>
      <Col sm="4">
        <Card>
          <CardBody>
            <div className="d-flex align-items-center mb-3">
              <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                  <i className={report.icon} />
                </span>
              </div>
              <h5 className="font-size-14 mb-0">{report.title}</h5>
            </div>
            <div className="text-muted mt-4">
              <h4>
                {loginDataList[0]?.avg_login}{" "}
                <i className="mdi mdi-chevron-up ms-1 text-success" />
              </h4>

              <div className="d-flex">
                {report.badgeValue && (
                  <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}
                  </span>
                )}
                <span
                  className="text-truncate"
                  style={{
                    marginLeft: report.badgeValue && "10px",
                    cursor: "pointer",
                  }}
                  onClick={onClick}
                >
                  {report.desc}
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default MonthlyUsers
