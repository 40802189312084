import React, { useEffect } from "react"
import { useState } from "react"
import Backdrop from "@mui/material/Backdrop"
import { Col, Container, Row, Button } from "reactstrap"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"

import BannerSection from "components/ManageHome/BannerSection"
import FirstSection from "components/ManageHome/FirstSection"
import SecondSection from "components/ManageHome/SecondSection"
import ThirdSection from "components/ManageHome/ThirdSection"
import FourthSection from "components/ManageHome/FourthSection"
import ClientelSection from "components/ManageHome/ClientelSection"
import ContactSection from "components/ManageHome/Contact_us"
import GallerySection from "components/ManageHome/Gallery"
import { getManageHomeData, postManageHomeData } from "helpers/manageHomeData"

function ManageHome() {
  document.title = "Manage Home"

  const [loading, setLoading] = useState(false)

  const [images, setImages] = useState([])
  const [imageFiles, setImageFiles] = useState([])

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  const [secondFile, setSecondFile] = useState(null)
  const [secondDataURL, setSecondDataURL] = useState(null)

  const [thirdFile, setThirdFile] = useState(null)
  const [thirdDataURL, setThirdDataURL] = useState(null)

  const [fourthFile, setFourthFile] = useState(null)
  const [fourthDataURL, setFourthDataURL] = useState(null)

  const [clientelFile, setClientelFile] = useState(null)
  const [clientelDataURL, setClientelDataURL] = useState(null)

  const [galleryImages, setGalleryImages] = useState([])
  const [galleryImageFiles, setGalleryImageFiles] = useState([])

  const [manageHomeData, setManageHomeData] = useState({
    banner_heading: "",
    banner_subheading: "",
    first_button_name: "",
    first_button_link: "",
    second_button_name: "",
    second_button_link: "",
    home_banner: [],
    first_section_heading: "",
    first_section_desc: "",
    first_section_file: [],
    second_section_heading: "",
    second_section_desc: "",
    second_section_file: {},
    third_section_heading: "",
    third_section_desc: "",
    third_section_file: {},
    fourth_section_heading: "",
    fourth_section_desc: "",
    fourth_section_file: {},
    fifth_section_heading: "",
    fifth_section_desc: "     ",
    fifth_section_file: "",
    why_partner_heading: "",
    partner_section_image: {},
    clientele_heading: "",
    contact_us_head: "",
    contact_us_subhead: "",
    home_gallery: [],
    removed_banner_ids: [],
    removed_gallery_ids: [],
  })

  useEffect(() => {
    getManageHomeData(manageHomeData, setManageHomeData, setLoading)
  }, [])

  const onSubmit = () => {
    setImages([])
    setImageFiles([])
    setGalleryImages([])
    setGalleryImageFiles([])
    setFirstFile(null)
    setFirstDataURL(null)
    setSecondFile(null)
    setSecondDataURL(null)
    setThirdFile(null)
    setThirdDataURL(null)
    setFourthFile(null)
    setFourthDataURL(null)
    setClientelFile(null)
    setClientelDataURL(null)
    postManageHomeData(manageHomeData, setManageHomeData, setLoading)
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div
            style={{
              zIndex: 999999999,
              fontSize: "5rem",
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4 draggable-body-button">
            <Col sm={10}>
              <p className="h4 card-title font-size-22">Edit Heading</p>
            </Col>
            <Col sm={2} className="py-2 mb-4">
              <Button
                className="btn btn-rounded btn-info float-end w-sm"
                onClick={onSubmit}
              >
                Save Changes
              </Button>
            </Col>
          </Row>

          <BannerSection
            images={images}
            setImages={setImages}
            imageFiles={imageFiles}
            setImageFiles={setImageFiles}
            manageHomeData={manageHomeData}
            setManageHomeData={setManageHomeData}
          />

          <Row>
            <Col sm={12}>
              <div className="h4 py-3 card-title font-size-18">Discovery</div>
              <div className="page-title-box d-block align-items-center justify-content-between">
                <FirstSection
                  firstFile={firstFile}
                  setFirstFile={setFirstFile}
                  firstDataURL={firstDataURL}
                  setFirstDataURL={setFirstDataURL}
                  manageHomeData={manageHomeData}
                  setManageHomeData={setManageHomeData}
                />

                <SecondSection
                  secondFile={secondFile}
                  setSecondFile={setSecondFile}
                  secondDataURL={secondDataURL}
                  setSecondDataURL={setSecondDataURL}
                  manageHomeData={manageHomeData}
                  setManageHomeData={setManageHomeData}
                />

                <ThirdSection
                  thirdFile={thirdFile}
                  setThirdFile={setThirdFile}
                  thirdDataURL={thirdDataURL}
                  setThirdDataURL={setThirdDataURL}
                  manageHomeData={manageHomeData}
                  setManageHomeData={setManageHomeData}
                />

                <FourthSection
                  fourthFile={fourthFile}
                  setFourthFile={setFourthFile}
                  fourthDataURL={fourthDataURL}
                  setFourthDataURL={setFourthDataURL}
                  manageHomeData={manageHomeData}
                  setManageHomeData={setManageHomeData}
                />

                <ClientelSection
                  clientelFile={clientelFile}
                  setClientelFile={setClientelFile}
                  clientelDataURL={clientelDataURL}
                  setClientelDataURL={setClientelDataURL}
                  manageHomeData={manageHomeData}
                  setManageHomeData={setManageHomeData}
                />

                <ContactSection
                  manageHomeData={manageHomeData}
                  setManageHomeData={setManageHomeData}
                />

                <GallerySection
                  galleryImages={galleryImages}
                  setGalleryImages={setGalleryImages}
                  galleryImageFiles={galleryImageFiles}
                  setGalleryImageFiles={setGalleryImageFiles}
                  manageHomeData={manageHomeData}
                  setManageHomeData={setManageHomeData}
                />
              </div>
            </Col>
            <Col sm={12} className="py-2">
              <Button
                className="btn btn-rounded btn-info float-end w-sm"
                onClick={onSubmit}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageHome
