import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Input,
  Container,
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { toast } from "react-toastify"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function FloorLayoutList() {
  document.title = "All Floor Layout"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [floorLayout, setAllFloorLayout] = useState([])
  const [filteredFloorLayout, setFilteredFloorLayout] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getAllFloorLayout = async () => {
    try {
      const res = await axios.get(URL.ALL_FLOOR_LAYOUT)
      console.log(res)
      setAllFloorLayout(res.data?.data)
      setFilteredFloorLayout(res.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllFloorLayout()
  }, [])

  const columns = [
    {
      name: "Vendor",
      selector: row => row.vendor.fname + " " + row.vendor.lname,
      sortable: true,
      width: "120px",
    },
    {
      name: "Property ID",
      selector: row => row.property.property_id,
      width: "120px",
    },
    {
      name: "Type",
      selector: row => row.sub_sub_menu.name,
      width: "200px",
    },
    {
      name: "Image",
      selector: row => (
        <img
          style={{
            height: "60px",
            width: "60px",
            objectFit: "contain",
          }}
          alt="Profile"
          src={row.image.image_path}
        />
      ),
      width: "160px",
    },
    {
      name: "Title",
      selector: row => row.title,
      width: "180px",
    },
    {
      name: "Status",
      cell: row => (
        <div>
          <Button
            variant="outline"
            color={`${row.status === true ? `success` : `dark`} `}
            className="btn btn-rounded float-end w-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            {row.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Action",
      cell: row => (
        <Col
          sm={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex gap-2 float-end">
            <Button
              className="btn btn-rounded btn-warning  w-sm"
              onClick={() => handleEditAllProperty(row)}
            >
              Edit
            </Button>
            <Button
              color="danger"
              className="btn btn-rounded  w-sm"
              onClick={() => showDeleteModal(row)}
            >
              Delete
            </Button>
          </div>
        </Col>
      ),
    },
  ]

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(
        URL.UPDATE_FLOOR_LAYOUT_STATUS + `/${item.id}`,
        {
          status: !sendStatus,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getAllFloorLayout()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = floorLayout.filter(item => {
      return (
        item.vendor.fname.toLowerCase().match(search.toLowerCase()) ||
        item.vendor.lname.toLowerCase().match(search.toLowerCase())
      )
    })

    setFilteredFloorLayout(result)
  }, [search])

  const handleAddAllProperty = row => {
    history.push("/add-floor-layout")
  }

  const handleEditAllProperty = row => {
    history.push("/add-floor-layout", { state: row })
  }

  const handleDeleteAllProperty = async item => {
    try {
      const res = await axios.delete(URL.DELETE_FLOOR_LAYOUT, {
        data: {
          entryIds: [item.id],
        },
      })
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getAllFloorLayout()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>All Floor Layout</ModalHeader>
        <ModalBody>
          Do you want to delete the row with vendor name{" "}
          {deleteRecord.vendor?.fname}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteAllProperty(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Row className="py-4 px-2">
          <div className="draggable-body-button">
            <Col sm={10}>
              <p className="h4 card-title font-size-22">All Floor Layout</p>
            </Col>
            <Col sm={1}>
              <Button
                onClick={handleAddAllProperty}
                color="secondary"
                className="btn btn-rounded  btn-info float-end w-sm"
              >
                Add
              </Button>
            </Col>
          </div>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredFloorLayout}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  id="table-search"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FloorLayoutList
