import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import Backdrop from "@mui/material/Backdrop"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import {
  CardBody,
  Input,
  Container,
  Col,
  Button,
  Card,
  Row,
  Label,
  CardTitle,
} from "reactstrap"
import LayoutImage from "./CalculatorLayoutImage/LayoutImage"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

function CalculatorLayout() {
  document.title = "Add Calculator Group"
  const history = useHistory()
  const { state } = useLocation()

  const [isDisabled, setIsDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [calculatorGroup, setCalculatorGroup] = useState({
    img: state ? state?.state?.img : "",
    name: state ? state.state.name : "",
  })

  const { img, name } = calculatorGroup

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setCalculatorGroup(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    if (img !== "" && name.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [calculatorGroup, firstFile, firstDataURL])

  const addCalculatorGroup = async () => {
    let formData = new FormData()
    formData.append("img", img)
    formData.append("name", name)
    setLoading(true)
    try {
      const res = await axios.post(URL.ADD_CALCULATOR_LAYOUT, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        setLoading(false)
        history.push("/all-calculator-layout")
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateCalculatorGroup = async () => {
    let formData = new FormData()
    formData.append("name", name)
    formData.append("img", img)
    setLoading(true)
    try {
      const res = await axios.put(
        URL.UPDATE_CALCULATOR_LAYOUT + `/${state?.state?.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        setLoading(false)
        history.push("/all-calculator-layout")
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Calculator Layout" : "Add Calculator Layout"}
              </CardTitle>

              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateCalculatorGroup : addCalculatorGroup}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Col component="form" autoComplete="off">
                <div className="mb-3">
                  <Label>Name</Label>

                  <Input
                    name="name"
                    value={name}
                    onChange={onChange}
                    id="outlined-basic"
                    placeholder="Name"
                  />
                </div>

                <LayoutImage
                  img={img}
                  firstFile={firstFile}
                  setFirstFile={setFirstFile}
                  firstDataURL={firstDataURL}
                  setFirstDataURL={setFirstDataURL}
                  setCalculatorGroup={setCalculatorGroup}
                />
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CalculatorLayout
