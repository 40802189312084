import React, { useEffect } from "react"
import { Container, Label, Input, Col } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function AllPropertyImage({
  image,
  firstFile,
  setFirstFile,
  firstDataURL,
  setFirstDataURL,
  setAddProperty,
}) {
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL(result)
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleLogo = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setAddProperty(prevState => ({
        ...prevState,
        feature_image: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setFirstFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#logo")
    temp.value = null
    setFirstFile(null)
    setFirstDataURL(null)
    setAddProperty(prevState => ({
      ...prevState,
      feature_image: [],
    }))
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Label>Featured Image</Label>
        <div className="img-container">
          {Object.keys(image).length > 0 && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={`${image.image_path}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${image.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt="Image"
                  loading="lazy"
                />
                <i
                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                  onClick={handleRemoveMedia}
                />
              </div>
            </div>
          )}

          {firstDataURL && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={firstDataURL}
                  srcSet={firstDataURL}
                  alt="Banner"
                  loading="lazy"
                />
                <i
                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                  onClick={handleRemoveMedia}
                />
              </div>
            </div>
          )}
        </div>
        <Col sm={4}>
          <Input
            id="logo"
            type="file"
            name="image"
            placeholder="Choose image"
            accept="image/*"
            onChange={handleLogo}
          />
        </Col>
      </Container>
    </React.Fragment>
  )
}

export default AllPropertyImage
