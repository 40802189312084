import React, { useEffect } from "react"
import { Card, CardBody, CardTitle, Col, Row, Label, Input } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

const BannerSection = ({
  commercialData,
  setCommercialData,
  bannerFile,
  setBannerFile,
  bannerDataURL,
  setBannerDataURL,
}) => {
  const { banner_heading, banner_subheading, banner_img } = commercialData

  const handleChange = e => {
    setCommercialData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setCommercialData(prevState => ({
        ...prevState,
        banner_img: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setBannerFile(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (bannerFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setBannerDataURL(result)
        }
      }
      fileReader.readAsDataURL(bannerFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [bannerFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile")
    temp.value = null
    setBannerDataURL(null)
    setCommercialData(prevState => ({
      ...prevState,
      banner_img: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-3">Banner Section</CardTitle>

            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="banner_heading"
                onChange={handleChange}
                value={banner_heading || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>

            <div className="mb-3">
              <Label htmlFor="formrow-subheading-Input">Sub Heading</Label>
              <Input
                type="text"
                name="banner_subheading"
                onChange={handleChange}
                value={banner_subheading || ""}
                className="form-control"
                id="formrow-subheading-Input"
                placeholder="Enter Sub Heading"
              />
            </div>

            <Row>
              <Col className="col-12">
                <CardTitle className="h4 mb-4">Upload Image</CardTitle>
                <div className="row">
                  <Col sm={6}>
                    <div className="mt-3">
                      <div className="img-container">
                        {Object.keys(banner_img).length > 0 && (
                          <div className="media-container">
                            <div className="media-main">
                              <img
                                className="media-img"
                                src={`${banner_img?.image_path}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${banner_img?.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt="Image"
                                loading="lazy"
                              />
                              <div>
                                <i
                                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                  onClick={handleRemoveMedia}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {bannerDataURL && bannerFile?.type?.includes("image") && (
                          <div className="media-container">
                            <div className="media-main">
                              <img
                                className="media-img"
                                src={bannerDataURL}
                                srcSet={bannerDataURL}
                                alt="Banner"
                                loading="lazy"
                              />
                              <div>
                                <i
                                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                  onClick={handleRemoveMedia}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <Input
                        name="banner_img"
                        placeholder="Choose image"
                        accept="image/*"
                        type="file"
                        onChange={handleMedia}
                        multiple
                        id="formFile"
                      />
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BannerSection
