import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Backdrop from "@mui/material/Backdrop"
import {
  Input,
  Container,
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { toast } from "react-toastify"

import LoadingSpinner from "../../assets/images/ball-triangle.svg"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function AllProperty() {
  document.title = "All Property"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const [allProperty, setAllProperty] = useState([])
  const [filteredAllProperty, setFilteredAllProperty] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getAllProperty = async () => {
    setLoading(true)
    try {
      const res = await axios.get(URL.ALL_PROPERTY)
      setLoading(false)
      setAllProperty(res.data?.data)
      setFilteredAllProperty(res.data?.data)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getAllProperty()
  }, [])

  const columns = [
    {
      name: "Vendor",
      selector: row => `${row.vendor.fname + " " + row.vendor.lname}`,
      sortable: true,
      width: "130px",
    },
    // {
    //   name: "Property ID",
    //   selector: row => row.property_id,
    //   // sortable: true,
    //   width: "130px",
    // },
    {
      name: "Property Category",
      selector: row => row.property_category.title,
      // sortable: true,
      width: "160px",
    },
    // {
    //   name: "Property Type",
    //   selector: row => row.property_type.title,
    // },
    {
      name: "Property Name",
      selector: row => row.property_name,
      width: "130px",
    },
    {
      name: "Feature Image",
      width: "140px",
      selector: row => (
        <img
          style={{
            height: "60px",
            width: "60px",
            objectFit: "contain",
          }}
          alt="Profile"
          src={row?.feature_image?.image_path}
        />
      ),
    },
    {
      name: "Visibility",
      width: "140px",
      cell: row => (
        <div>
          <Button
            variant="outline"
            color={`${row.visibility === "Public" ? `success` : `dark`} `}
            className="btn btn-rounded float-end w-sm"
            onClick={() => handleUpdateVisibility(row)}
          >
            {row.visibility === "Public" ? "PUBLIC" : "PRIVATE"}
          </Button>
        </div>
      ),
    },
    {
      name: "Feature",
      width: "140px",
      cell: row => (
        <div>
          <Button
            variant="outline"
            color={`${row.feature === true ? `success` : `danger`} `}
            className="btn btn-rounded float-end w-sm"
            onClick={() => handleFeatures(row)}
          >
            {row.feature === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      name: "Status",
      width: "140px",
      cell: row => (
        <div>
          <Button
            variant="outline"
            color={`${row.status === true ? `success` : `dark`} `}
            className="btn btn-rounded float-end w-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            {row.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      name: "Action",
      cell: row => (
        <Col
          sm={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex gap-2 float-end">
            <Button
              className="btn btn-rounded btn-warning  w-sm"
              onClick={() => handleEditAllProperty(row)}
            >
              Edit
            </Button>
            <Button
              color="danger"
              className="btn btn-rounded  w-sm"
              onClick={() => showDeleteModal(row)}
            >
              Delete
            </Button>
          </div>
        </Col>
      ),
    },
  ]

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(URL.UPDATE_PROPERTY_STATUS + `/${item.id}`, {
        status: !sendStatus,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        getAllProperty()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdateVisibility = async item => {
    let visibility = item.visibility === "Public" ? "Private" : "Public"
    try {
      const res = await axios.put(
        URL.UPDATE_PROPERTY_VISIBILITY + `/${item.id}`,
        {
          visibility: visibility,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        getAllProperty()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleFeatures = async item => {
    let sendFeature = item.status
    try {
      const res = await axios.put(URL.UPDATE_PROPERTY_FEATURE + `/${item.id}`, {
        feature: !sendFeature,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        getAllProperty()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  // const handleViews = async item => {
  //   let sendFeature = item.status
  //   try {
  //     const res = await axios.put(URL.UPDATE_PROPERTY_FEATURE + `/${item.id}`, {
  //       feature: !sendFeature,
  //     })
  //     if (res.data.success) {
  //       toast.success(res.data.message)
  //       getAllProperty()
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     if (error.response) {
  //       toast.error(error.response.data.message)
  //     } else if (error.request) {
  //     } else {
  //       toast.error(error)
  //     }
  //   }
  // }

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = allProperty.filter(item => {
      return (
        item.vendor.fname.toLowerCase().match(search.toLowerCase()) ||
        item.vendor.lname.toLowerCase().match(search.toLowerCase())
      )
    })

    setFilteredAllProperty(result)
  }, [search])

  const handleAddAllProperty = row => {
    history.push("/add-property")
  }

  const handleEditAllProperty = row => {
    history.push("/add-property", { state: row })
  }

  const handleDeleteAllProperty = async item => {
    try {
      const res = await axios.delete(URL.DELETE_PROPERTY, {
        data: {
          entryIds: [item.id],
        },
      })
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getAllProperty()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>All Property List</ModalHeader>
        <ModalBody>
          Do you want to delete the row with vendor name{" "}
          {deleteRecord.vendor?.fname}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteAllProperty(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Row className="py-4 px-2">
          <div className="draggable-body-button">
            <Col sm={10}>
              <p className="h4 card-title font-size-22">Property List</p>
            </Col>
            <Col sm={1}>
              <Button
                onClick={handleAddAllProperty}
                color="secondary"
                className="btn btn-rounded btn-info float-end w-sm"
              >
                Add
              </Button>
            </Col>
          </div>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredAllProperty}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  id="table-search"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllProperty
