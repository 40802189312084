import React, { useEffect, useState } from "react"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory } from "react-router-dom"
import ManageClientList from "./ManageClientList"
import ManageSpaceOwner from "./ManageSpaceOwner"
import { toast } from "react-toastify"
import { Container, Row, Button } from "reactstrap"

const ManageSOCList = () => {
  document.title = "Space Owner And Clients List"
  const history = useHistory()
  const [data, setData] = useState([])
  const [clientList, setClientList] = useState([])
  const [spaceOwner, setSpaceOwner] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getManangeSOC = async () => {
    try {
      const res = await axios.get(URL?.ALL_SPACE_OWNER_AND_CLIENT)
      if (res?.data?.success) {
        setData(res?.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getManangeSOC()
  }, [])

  useEffect(() => {
    const getManangeSOC = () => {
      try {
        const res = axios.get(URL?.ALL_SPACE_OWNER_AND_CLIENT)
        setData(res?.data?.data)
      } catch (error) {
        console.log(error)
      }
    }

    getManangeSOC()
  }, [])

  const getClientList = () => {
    const list = data?.filter(el => el?.category === "Client")
    setClientList(list)
  }

  const getSpaceOwner = () => {
    const list = data?.filter(el => el?.category === "Space_Owner")
    setSpaceOwner(list)
  }

  useEffect(() => {
    getClientList()
  }, [data?.length])

  useEffect(() => {
    getSpaceOwner()
  }, [data?.length])

  const handleNavigate = () => {
    history.push("/add-space-owner-and-client")
  }

  const handleUpdateStatus = async item => {
    setData([])
    let sendStatus = item.status
    try {
      const res = await axios.put(
        URL.UPDATE_SPACE_OWNER_AND_CLIENT_STATUS + `${item.id}`,
        {
          status: !sendStatus,
        }
      )
      if (res.data.success) {
        getManangeSOC()
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleDelete = async item => {
    try {
      const res = await axios.delete(
        URL.DELETE_SPACE_OWNER_AND_CLIENT + `${item.id}`
      )
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getManangeSOC()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="mb-3">
              <div className="py-4 draggable-body-button">
                <p className="h4 card-title font-size-22">Space Owner</p>
                <Button
                  onClick={handleNavigate}
                  className="me-2 btn btn-rounded btn-info float-end w-sm"
                >
                  Add
                </Button>
              </div>

              <ManageSpaceOwner
                toggle={toggle}
                showModal={showModal}
                deleteRecord={deleteRecord}
                showDeleteModal={showDeleteModal}
                data={spaceOwner}
                setData={setSpaceOwner}
                handleDelete={handleDelete}
                handleUpdateStatus={handleUpdateStatus}
              />
            </div>
            <div className="mb-3">
              <div className="h4 card-title font-size-22 pb-3">
                Clients List
              </div>
              <ManageClientList
                toggle={toggle}
                showModal={showModal}
                deleteRecord={deleteRecord}
                showDeleteModal={showDeleteModal}
                data={clientList}
                setData={setClientList}
                handleDelete={handleDelete}
                handleUpdateStatus={handleUpdateStatus}
              />
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageSOCList
