import React, { useEffect } from "react"
import ReactPlayer from "react-player"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function FourthSection({
  manageHomeData,
  setManageHomeData,
  fourthFile,
  setFourthFile,
  fourthDataURL,
  setFourthDataURL,
}) {
  const { fourth_section_heading, fourth_section_desc, fourth_section_file } =
    manageHomeData

  const handleChange = e => {
    setManageHomeData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let url = fourth_section_file.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setManageHomeData(prevState => ({
        ...prevState,
        fourth_section_file: img,
      }))
    }
    const file = e.target.files[0]
    setFourthFile(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (fourthFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFourthDataURL(result)
        }
      }
      fileReader.readAsDataURL(fourthFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [fourthFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile3")
    temp.value = null
    setFourthDataURL(null)
    setManageHomeData(prevState => ({
      ...prevState,
      fourth_section_file: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <CardTitle className="h4 py-3">Fourth Section</CardTitle>
        <Card>
          <CardBody>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="fourth_section_heading"
                onChange={handleChange}
                value={fourth_section_heading || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <Row>
              <Col>
                <Card className="shadow">
                  <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data={fourth_section_desc}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setManageHomeData(prevState => ({
                          ...prevState,
                          fourth_section_desc: data,
                        }))
                      }}
                    />
                  </Form>
                </Card>
              </Col>
            </Row>

            <Row>
              <Label>Upload Video</Label>
              <Col sm={12}>
                <div>
                  <div
                    className={
                      fourth_section_file !== undefined &&
                      Object.keys(fourth_section_file).length > 0
                        ? `img-container  mb-5`
                        : `img-container `
                    }
                  >
                    {fourth_section_file !== undefined &&
                      Object.keys(fourth_section_file).length > 0 &&
                      url === "mp4" && (
                        <div className="media-container">
                          <div className="media-main">
                            <ReactPlayer
                              url={fourth_section_file.image_path}
                              controls={true}
                              height="150px"
                              width="150px"
                            />
                            <div>
                              <i
                                className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                onClick={handleRemoveMedia}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                    {fourthDataURL && fourthFile?.type?.includes("video") && (
                      <div className="media-container">
                        <div className="media-main">
                          <ReactPlayer
                            url={fourthDataURL}
                            controls={true}
                            height="150px"
                            width="150px"
                          />
                          <div>
                            <i
                              className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                              onClick={handleRemoveMedia}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <Col sm={4}>
                    <Input
                      name="file"
                      accept="video/*"
                      type="file"
                      onChange={handleMedia}
                      multiple
                      id="formFile3"
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default FourthSection
