import React from "react"

import { Card, CardBody, CardTitle, Col, Row, Label, Input } from "reactstrap"

function ContactSection({ manageHomeData, setManageHomeData }) {
  const { contact_us_head, contact_us_subhead } = manageHomeData

  const handleChange = e => {
    setManageHomeData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <CardTitle className="h4 py-3">Contact Section</CardTitle>
        <Card>
          <CardBody>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="contact_us_head"
                onChange={handleChange}
                value={contact_us_head || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Heading"
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="formrow-subheading-Input">Sub Heading</Label>
              <Input
                type="text"
                name="contact_us_subhead"
                onChange={handleChange}
                value={contact_us_subhead || ""}
                className="form-control"
                id="formrow-subheading-Input"
                placeholder="Enter Sub Heading"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ContactSection
