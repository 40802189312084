import React, { useEffect } from "react"
import { Row, Col, Card, Label, CardBody, Input } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

const Image = ({
  img,
  imgFile,
  setImgFile,
  imgDataURL,
  setImgDataURL,
  setManageAbout,
}) => {
  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setManageAbout(prevState => ({
        ...prevState,
        img: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setImgFile(file)
  }

  useEffect(() => {
    setTimeout(() => {
      let fileReader,
        isCancel = false
      if (imgFile) {
        fileReader = new FileReader()
        fileReader.onload = e => {
          const { result } = e.target
          if (result && !isCancel) {
            setImgDataURL(result)
          }
        }

        fileReader?.readAsDataURL(imgFile)
      }
      return () => {
        isCancel = true
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort()
        }
      }
    }, 600)
  }, [imgFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile")
    temp.value = null
    setImgDataURL([])
    setManageAbout(prevState => ({
      ...prevState,
      img: {},
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Image</Label>
            </div>
            <Row>
              <Col>
                <div
                  className={
                    img !== undefined && Object?.keys(img)?.length > 0
                      ? `img-container  mb-5`
                      : `img-container `
                  }
                >
                  {img !== undefined && Object?.keys(img)?.length > 0 && (
                    <div className="media-container">
                      <div className="media-main">
                        <img
                          className="media-img"
                          src={`${img.image_path}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${img.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt="Image"
                          loading="lazy"
                        />
                        <div>
                          <i
                            className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                            onClick={handleRemoveMedia}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {imgDataURL.length > 0 && (
                    <div className="media-container">
                      <div className="media-main">
                        <img
                          className="media-img"
                          src={imgDataURL}
                          srcSet={imgDataURL}
                          alt="Image"
                          loading="lazy"
                        />
                        <div>
                          <i
                            className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                            onClick={handleRemoveMedia}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <Col sm={4}>
                  <Input
                    name="file"
                    accept="image/*"
                    type="file"
                    onChange={handleMedia}
                    multiple
                    id="formFile"
                  />
                </Col>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Image
