import React, { useEffect, useState, useCallback, useRef } from "react"
import { Table } from "antd"
import { useHistory } from "react-router-dom"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import update from "immutability-helper"
import {
  Col,
  Row,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { toast } from "react-toastify"
import DragIcon from "../../../assets/images/drag.png"

const type = "DragableBodyRow"

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop(
    () => ({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {}
        if (dragIndex === index) {
          return {}
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? "drop-over-downward" : "drop-over-upward",
        }
      },
      drop: item => {
        moveRow(item.index, index)
      },
    }),
    [index]
  )

  const [, drag] = useDrag(
    () => ({
      type,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index]
  )

  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    ></tr>
  )
}

const components = {
  body: {
    row: DragableBodyRow,
  },
}

function ProcessList() {
  document.title = "Our Process"
  const history = useHistory()
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    },
    [data]
  )

  const columns = [
    {
      key: "id",
      width: 40,
      render: () => (
        <img
          style={{
            textAlign: "center",
            height: "20px",
            width: "20px",
            objectFit: "cover",
          }}
          alt="Image"
          src={DragIcon}
        />
      ),
    },
    {
      title: "Process",
      dataIndex: "process",
      key: "process",
      width: 480,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={() => handleUpdateStatus(record)}
            color={`${record.status === true ? `success` : `dark`} `}
          >
            {record.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div size="middle">
          <Button
            className="btn btn-rounded btn-warning w-sm me-3"
            onClick={() => handleEditTeam(record)}
          >
            Edit
          </Button>
          <Button
            color="danger"
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ]

  const getProcessList = async () => {
    try {
      const res = await axios.get(URL.ALL_PROCESS)
      setData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProcessList()
  }, [])

  const temp = data.map((item, idx) => {
    return { name: item.process, id: item.id, sort_order: idx + 1 }
  })

  useEffect(() => {
    saveSortOrder()
  }, [data])

  useEffect(() => {}, [data])

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(URL.UPDATE_PROCESS_STATUS + `${item.id}`, {
        status: !sendStatus,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        getProcessList()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.success(res.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleDelete = async item => {
    try {
      const res = await axios.delete(URL.DELETE_PROCESS, {
        data: {
          entryIds: item.id,
        },
      })
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        getProcessList()
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.success(res.data.message)
      } else if (error.request) {
      } else {
        toast.success(res.data.message)
      }
    }
  }

  const saveSortOrder = async () => {
    try {
      const res = await axios.put(URL.UPDATE_PROCESS_SORT_ORDER, {
        new_order: temp,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleNavigate = () => {
    history.push("/add-our-process")
  }

  const handleEditTeam = record => {
    history.push("/add-our-process", { state: record })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Our Process</ModalHeader>
        <ModalBody>
          Do you want to delete the row with process name {deleteRecord.process}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDelete(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col sm={11}>
              <div className="h4 card-title font-size-22">Our Process</div>
            </Col>
            <Col sm={1}>
              <Button
                className="btn btn-rounded btn-info w-sm me-3 float-end"
                onClick={handleNavigate}
              >
                Add
              </Button>
            </Col>
          </Row>

          <Row>
            <DndProvider backend={HTML5Backend}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                components={components}
                onRow={(record, index) => ({
                  index,
                  moveRow,
                })}
                scroll={{ x: "max-content" }}
              />
            </DndProvider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProcessList
