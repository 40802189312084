import axios from "./api_helper"
import URL from "./url_helper"
import { toast } from "react-toastify"

export const getCommercialData = async (
  commercialData,
  setCommercialData,
  setLoading
) => {
  setLoading(true)
  try {
    const res = await axios.get(URL.ALL_COMMERCIAL)
    if (res.data.success) {
      setCommercialData({
        banner_heading: res.data.data.banner_heading,
        banner_subheading: res.data.data.banner_subheading,
        banner_img: res.data.data.banner_img,
        first_section_heading: res.data.data.first_section_heading,
        first_section_desc: res.data.data.first_section_desc,
        first_section_file: res.data.data.first_section_file,
        second_section_heading: res.data.data.second_section_heading,
        second_section_desc: res.data.data.second_section_desc,
        second_section_file: res.data.data.second_section_file,
        third_section_heading: res.data.data.third_section_heading,
        third_section_desc: res.data.data.third_section_desc,
        third_section_file: res.data.data.third_section_file,
        gallery_head: res.data.data.gallery_head,
        gallery_desc: res.data.data.gallery_desc,
        commercial_gallery: res.data.data.commercial_gallery,
        floor_plan_head: res.data.data.floor_plan_head,
        floor_plan_desc: res.data.data.floor_plan_desc,
        floor_modified_image: res.data.data.floor_modified_image,
        floor_original_image: res.data.data.floor_original_image,
        virtual_tour_head: res.data.data.virtual_tour_head,
        virtual_tour_desc: res.data.data.virtual_tour_desc,
        virtual_tour_file: res.data.data.virtual_tour_file,
        removed_gallery_ids: [],

        // Our process
        our_process_head: res.data.data.our_process_head,
        our_process_subhead: res.data.data.our_process_subhead,
        our_process_image: res.data.data.our_process_image,
      })
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  } catch (error) {
    setLoading(false)
    toast.error(error)
    if (error.response) {
      toast.error(error.response.data.message)
    } else if (error.request) {
    } else {
      toast.error(error)
    }
  }
}

export const postCommercialData = async (
  commercialData,
  setCommercialData,
  setLoading
) => {
  setLoading(true)
  let formData = new FormData()
  Object.keys(commercialData).forEach(fieldName => {
    if (commercialData[fieldName]) {
      if (fieldName === "commercial_gallery") {
        commercialData[fieldName].forEach(el => {
          formData.append(fieldName, el)
        })
      } else if (fieldName === "removed_gallery_ids") {
        formData.append(fieldName, JSON.stringify(commercialData[fieldName]))
      } else {
        formData.append(fieldName, commercialData[fieldName])
      }
    }
  })

  try {
    const res = await axios.put(URL.UPDATE_COMMERCIAL, formData)
    if (res.data.success) {
      getCommercialData(commercialData, setCommercialData, setLoading)
      toast.success(res.data.message)
    }
    setLoading(false)
  } catch (error) {
    toast.error(error)
    setLoading(false)
    if (error.response) {
      toast.error(error.response.data.message)
    } else if (error.request) {
    } else {
      toast.error(error)
    }
  }
}
