import React from "react"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, registerables } from "chart.js"
ChartJS.register(...registerables)

const LoginUsageChart = ({ labelsData, avg_login }) => {
  const data = {
    labels: labelsData,
    datasets: [
      {
        label: "Average Login Analytics",
        fill: true,
        lineTension: 0.5,
        backgroundColor: "rgba(85, 110, 230, 0.2)",
        borderColor: "#556ee6",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#556ee6",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#556ee6",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: avg_login,
      },
    ],
  }
  var option = {
    scales: {
      yAxes: [
        {
          ticks: {
            max: 100,
            min: 20,
            stepSize: 10,
          },
        },
      ],
    },
  }

  return <Line width={474} height={300} data={data} options={option} />
}

export default LoginUsageChart
