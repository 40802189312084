import React, { useState, useEffect } from "react"
import Select from "react-select"

import URL from "../../helpers/url_helper"

import axios from "../../helpers/api_helper"
import { toast } from "react-toastify"

import { useHistory, useLocation } from "react-router-dom"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"

function AddNewUser() {
  document.title = "Add User"
  let { state } = useLocation()
  const history = useHistory()
  const [roles, setRoles] = useState([])
  const [roleId, setRoleId] = useState(null)
  const [dropdownValue, setDropdownValue] = useState("")
  const [adminDetails, setAdminDetails] = useState({
    userName: state ? state?.state?.name : "",
    emailAddress: state ? state?.state?.email : "",
    contactNumber: state ? state?.state?.phone_no : "",
    password: "",
  })

  const { userName, emailAddress, contactNumber, password } = adminDetails
  const [isDisabled, setIsDisabled] = useState(true)

  const getRoles = async () => {
    try {
      const res = await axios.get("role/all")
      setRoles(res.data.roles)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getRoles()
  }, [])

  const onChange = e => {
    setAdminDetails(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const createAdmin = async () => {
    try {
      const res = await axios.post(URL.CREATE_ADMIN, {
        name: userName,
        email: emailAddress,
        phone_no: contactNumber,
        password: password,
        role_id: roleId,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-admin-user")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateAdmin = async () => {
    try {
      const res = await axios.put(URL.UPDATE_ADMIN + `/${state.state.id}`, {
        name: userName,
        email: emailAddress,
        phone_no: contactNumber,
        password: password,
        role_id: roleId,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-admin-user")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    if (state) {
      let temp = roles?.find(el => el.id === state?.state?.role?.id)
      setDropdownValue(temp)
      setRoleId(state?.state?.role?.id)
    } else {
      setDropdownValue(null)
    }
  }, [roles.length])

  const handleGoBack = () => {
    history.push("/all-admin-user")
  }

  useEffect(() => {
    if(
      userName.length > 0 && 
      emailAddress.length > 0 &&
      contactNumber > 0 && 
      // password.length > 0 &&
      dropdownValue !== undefined
    ){
    setIsDisabled(false)
    }else{
    setIsDisabled(true)
    }
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4 px-2">
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state?.state ? "Edit User" : "Add User"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state?.state ? updateAdmin : createAdmin}
                  className={
                    isDisabled
                    ? `btn btn-rounded w-sm mb-4`
                    : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                {
                  // <Button onClick={handleGoBack}  color="secondary" className="btn btn-rounded w-sm mb-4">Back</Button>
                }
                
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label>Select Role</Label>
                    <Select
                      onChange={(event, val) => {
                        setRoleId(event.id)
                        setDropdownValue(event)
                      }}
                      value={dropdownValue}
                      options={roles}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      classNamePrefix="select2-selection"
                      placeholder="Select Role"
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Name</Label>
                    <Input
                      type="text"
                      name="userName"
                      onChange={onChange}
                      value={userName}
                      className="form-control"
                      id="formrow-name-Input"
                      placeholder="Enter Name"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="example-email-input">Email</Label>
                    <Input
                      type="email"
                      name="emailAddress"
                      onChange={onChange}
                      value={emailAddress}
                      className="form-control"
                      id="formrow-heading-Input"
                      placeholder="Email Address"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="example-number-input">Contact Number</Label>
                    <Input
                      className="form-control"
                      name="contactNumber"
                      type="number"
                      onChange={onChange}
                      value={contactNumber}
                      id="example-number-input"
                      placeholder="Contact Number"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="example-password-input">Password</Label>
                    <Input
                      className="form-control"
                      name="password"
                      type="password"
                      onChange={onChange}
                      value={password}
                      id="example-password-input"
                      placeholder="Password"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddNewUser
