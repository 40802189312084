import React, { useEffect, useState } from "react"
import { Container, Label, Input, Col } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function RenderImage({ image, setRenderList, entries, index }) {
  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  console.log(firstFile, firstDataURL)

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile && Object.keys(firstFile).length) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL([...result])
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleImage = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      // let file_size = e.target.files[0].size
      // if (file_size > 10485760) {
      //   alert("File size exceeded the 10 MB limit!")
      // }
      let tempEntries = [...entries]
      let currObj = {
        title: "",
        ...tempEntries[index],
      }
      tempEntries[index] = { ...currObj, image: img }
      setRenderList(prevState => ({
        ...prevState,
        entries: [...tempEntries],
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setFirstFile(file)
  }

  return (
    <React.Fragment>
      <Container fluid={true} className="mb-3">
        <Label>Image</Label>
        <div className="img-container p-2">
          {Object.keys(image).length > 0 && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={`${image.image_path}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${image.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt="Image"
                  loading="lazy"
                />
              </div>
            </div>
          )}

          {firstDataURL && (
            <div className="media-container">
              <div className="media-main">
                <img
                  className="media-img"
                  src={firstDataURL}
                  srcSet={firstDataURL}
                  alt="Banner"
                  loading="lazy"
                />
              </div>
            </div>
          )}
        </div>

        <Col sm={4}>
          <Input
            type="file"
            name="image"
            placeholder="Choose image"
            accept="image/*"
            onChange={handleImage}
          />
        </Col>
      </Container>
    </React.Fragment>
  )
}

export default RenderImage
