import axios from "axios"
// const API_URL = "http://13.233.49.246:8080/"

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

axiosApi.interceptors.request.use(function (config) {
  const authUser = JSON?.parse(localStorage?.getItem("authUser"))
  if (authUser) {
    config.headers["x-auth-token"] = authUser?.admin?.token
  }

  return config
})

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}


export default axiosApi;