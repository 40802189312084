import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ReactPlayer from "react-player"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function VirtualTourSection({
  commercialData,
  setCommercialData,
  virtualFloorFile,
  setVirtualFloorFile,
  virtualFloorDataURL,
  setVirtualFloorDataURL,
}) {
  const { virtual_tour_head, virtual_tour_desc, virtual_tour_file } =
    commercialData

  const handleChange = e => {
    setCommercialData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let url = virtual_tour_file.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setCommercialData(prevState => ({
        ...prevState,
        virtual_tour_file: img,
      }))
    }
    const file = e.target.files[0]
    setVirtualFloorFile(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (virtualFloorFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setVirtualFloorDataURL(result)
        }
      }
      fileReader.readAsDataURL(virtualFloorFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [virtualFloorFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile5")
    temp.value = null
    setVirtualFloorDataURL(null)
    setCommercialData(prevState => ({
      ...prevState,
      virtual_tour_file: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-3">3D Virtual Tour Section</CardTitle>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="first_section_heading"
                onChange={handleChange}
                value={virtual_tour_head || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <Row>
              <Col>
                <Card className="shadow">
                  <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data={virtual_tour_desc}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setCommercialData(prevState => ({
                          ...prevState,
                          virtual_tour_desc: data,
                        }))
                      }}
                    />
                  </Form>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Label>Upload Video/Image</Label>
                <div className="row">
                  <Col sm={6}>
                    <div>
                      <div className="img-container p-2">
                        {virtual_tour_file !== undefined &&
                          Object.keys(virtual_tour_file).length > 0 &&
                          url === "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <ReactPlayer
                                  url={virtual_tour_file.image_path}
                                  controls={true}
                                  height="150px"
                                  width="150px"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {virtualFloorDataURL &&
                          virtualFloorFile?.type?.includes("video") && (
                            <div className="media-container">
                              <div className="media-main">
                                <ReactPlayer
                                  url={virtualFloorDataURL}
                                  controls={true}
                                  height="150px"
                                  width="150px"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {virtual_tour_file !== undefined &&
                          Object.keys(virtual_tour_file).length > 0 &&
                          url !== "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={`${virtual_tour_file.image_path}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${virtual_tour_file.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt="Image"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {virtualFloorDataURL &&
                          virtualFloorFile?.type?.includes("image") && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={virtualFloorDataURL}
                                  srcSet={virtualFloorDataURL}
                                  alt="Banner"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <Input
                        name="file"
                        accept="image/*, video/*"
                        type="file"
                        onChange={handleMedia}
                        multiple
                        id="formFile5"
                      />
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default VirtualTourSection
