import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory, useLocation } from "react-router-dom"
import {
  CardBody,
  Input,
  Container,
  Row,
  Col,
  Button,
  Card,
  Label,
} from "reactstrap"
import Select from "react-select"
import Backdrop from "@mui/material/Backdrop"
import SmallImage from "./CalculatorItemImage/SmallImage"
import MediumImage from "./CalculatorItemImage/MediumImage"
import LargeImage from "./CalculatorItemImage/LargeImage"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"
import SmallMultiImages from "./CalculatorItemImage/SmallMultiImages"
import MediumMultiImages from "./CalculatorItemImage/MediumMultiImages"
import LargeMultiImage from "./CalculatorItemImage/LargeMultiImage"

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

function CalculatorItem() {
  document.title = "Add Calculator Group Item"
  const history = useHistory()
  const { state } = useLocation()

  const [isDisabled, setIsDisabled] = useState(false)
  const [calculatorItem, setCalculatorItem] = useState({
    // img: state ? state?.state?.img : "",
    layout_name: state ? state?.state?.group?.layout?.name : "",
    group_id: state ? state?.state?.group?.id : "",
    group_name: state ? state?.state?.group?.name : "",
    name: state ? state?.state?.name : "",

    sizeFt: state ? state?.state?.sizeFt : 1,
    qty: state ? state?.state?.qty : 1,
    minqty: state ? state?.state?.minqty : 1,

    sm_size: state ? state?.state?.sm_size : "",
    md_size: state ? state?.state?.md_size : "",
    lg_size: state ? state?.state?.lg_size : "",
    sm_image: state ? state?.state?.sm_image : "",
    md_image: state ? state?.state?.md_image : "",
    lg_image: state ? state?.state?.lg_image : "",

    sm_multi_image: state ? state?.state?.sm_multi_image : [],
    md_multi_image: state ? state?.state?.md_multi_image : [],
    lg_multi_image: state ? state?.state?.lg_multi_image : [],

    sm_multi_removed_imgs: [],
    md_multi_removed_imgs: [],
    lg_multi_removed_imgs: [],
  })

  const {
    layout_name,
    group_id,
    name,
    sizeFt,
    qty,
    minqty,
    sm_size,
    md_size,
    lg_size,
    sm_image,
    md_image,
    lg_image,
  } = calculatorItem

  const [loading, setLoading] = useState(false)

  const [secondFile, setSecondFile] = useState(null)
  const [secondDataURL, setSecondDataURL] = useState(null)

  const [smallImages, setSmallImages] = useState([])
  const [smallImageFiles, setSmallImageFiles] = useState([])

  const [thirdFile, setThirdFile] = useState(null)
  const [thirdDataURL, setThirdDataURL] = useState(null)

  const [mediumImages, setMediumImages] = useState([])
  const [mediumImageFiles, setMediumImageFiles] = useState([])

  const [fourthFile, setFourthFile] = useState(null)
  const [fourthDataURL, setFourthDataURL] = useState(null)

  const [largeImages, setLargeImages] = useState([])
  const [largeImageFiles, setLargeImageFiles] = useState([])

  const [layouts, setLayouts] = useState([])
  const [layoutsDropdown, setLayoutsDropdown] = useState("")

  const [calculatorGroup, setCalculatorGroup] = useState([])
  const [dropdownValue, setDropdownValue] = useState("")

  const onChange = e => {
    if (e.target.value.length > 235) {
      return
    }
    setCalculatorItem(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleChange = e => {
    setCalculatorItem(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  var invalidChars = ["-", "e", "+", "E"]

  const onKeyDown = e => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const getLayout = async () => {
    try {
      const res = await axios.get(URL.ALL_CALCULATOR_LAYOUT)
      if (res.data.success) {
        setLayouts(res.data.layouts)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLayout()
  }, [])

  const addProperty = async () => {
    setLoading(true)
    let formData = new FormData()

    Object.keys(calculatorItem).forEach(fieldName => {
      if (calculatorItem[fieldName]) {
        if (fieldName === "sm_multi_image") {
          calculatorItem[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        } else if (fieldName === "md_multi_image") {
          calculatorItem[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        } else if (fieldName === "lg_multi_image") {
          calculatorItem[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        }
      }
    })

    formData.append("name", name)
    formData.append("sizeFt", parseInt(1))
    formData.append("qty", parseInt(1))
    formData.append("minqty", parseInt(1))
    formData.append("sm_size", sm_size)
    formData.append("md_size", md_size)
    formData.append("lg_size", lg_size)
    formData.append("sm_image", sm_image)
    formData.append("md_image", md_image)
    formData.append("lg_image", lg_image)
    formData.append("layout_id", parseInt(layoutsDropdown?.id))
    formData.append("group_id", parseInt(dropdownValue?.id))

    try {
      const res = await axios.post(URL.ADD_CALCULATOR_GROUP_ITEM, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-calculator-group-item")
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateProperty = async () => {
    setLoading(true)
    let formData = new FormData()

    Object.keys(calculatorItem).forEach(fieldName => {
      if (calculatorItem[fieldName]) {
        if (fieldName === "sm_multi_image") {
          calculatorItem[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        } else if (fieldName === "md_multi_image") {
          calculatorItem[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        } else if (fieldName === "lg_multi_image") {
          calculatorItem[fieldName].forEach(el => {
            formData.append(fieldName, el)
          })
        } else if (fieldName === "sm_multi_removed_imgs") {
          formData.append(fieldName, JSON.stringify(calculatorItem[fieldName]))
        } else if (fieldName === "md_multi_removed_imgs") {
          formData.append(fieldName, JSON.stringify(calculatorItem[fieldName]))
        } else if (fieldName === "lg_multi_removed_imgs") {
          formData.append(fieldName, JSON.stringify(calculatorItem[fieldName]))
        }
      }
    })

    formData.append("name", name)
    formData.append("sizeFt", parseInt(1))
    formData.append("qty", parseInt(1))
    formData.append("minqty", parseInt(1))
    formData.append("sm_size", sm_size)
    formData.append("md_size", md_size)
    formData.append("lg_size", lg_size)
    formData.append("sm_image", sm_image)
    formData.append("md_image", md_image)
    formData.append("lg_image", lg_image)

    formData.append("layout_id", parseInt(layoutsDropdown?.id))
    formData.append("group_id", parseInt(dropdownValue?.id))

    try {
      const res = await axios.put(
        URL.UPDATE_CALCULATOR_GROUP_ITEM + `/${state.state.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-calculator-group-item")
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    let temp = layouts.find(el => el.name === layout_name)
    setLayoutsDropdown(temp)
  }, [layout_name, layouts?.length])

  useEffect(() => {
    setCalculatorGroup(
      layoutsDropdown?.SpaceCalculatorGroup
        ? layoutsDropdown?.SpaceCalculatorGroup
        : []
    )

    setDropdownValue("")
  }, [layoutsDropdown])

  useEffect(() => {
    let temp = calculatorGroup.find(el => el.id === group_id)
    setDropdownValue(temp)
  }, [group_id, calculatorGroup?.length])

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <p className="h4 card-title font-size-22">
                {state
                  ? "Edit Calculator Group Item"
                  : "Add Calculator Group Item"}
              </p>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateProperty : addProperty}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>
                <Button
                  className="btn btn-rounded float-end w-sm mb-4"
                  color="secondary"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Col component="form">
                <div className="mb-3">
                  <Label>Select Calculator Layout</Label>

                  <Select
                    value={layoutsDropdown}
                    options={layouts}
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => `${option.id}`}
                    onChange={(event, val) => {
                      setLayoutsDropdown(event)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label>Select Calculator Group</Label>

                  <Select
                    value={dropdownValue}
                    options={calculatorGroup}
                    getOptionLabel={option => `${option.name}`}
                    getOptionValue={option => `${option.id}`}
                    onChange={(event, val) => {
                      setDropdownValue(event)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label>Item Name</Label>

                  <Input
                    type="text"
                    name="name"
                    value={name || ""}
                    onChange={onChange}
                    id="formrow-name-Input"
                    placeholder="Item Name"
                  />
                </div>

                <div className="mb-3">
                  <Label>Small Size Value</Label>
                  <Input
                    type="text"
                    name="sm_size"
                    value={sm_size}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                  />
                </div>

                <div className="mb-3">
                  <Label>Medium Size Value</Label>
                  <Input
                    type="text"
                    name="md_size"
                    value={md_size}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                  />
                </div>

                <div className="mb-3">
                  <Label>Large Size Value</Label>
                  <Input
                    type="text"
                    name="lg_size"
                    value={lg_size}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                  />
                </div>

                <SmallImage
                  sm_image={sm_image}
                  secondFile={secondFile}
                  setSecondFile={setSecondFile}
                  secondDataURL={secondDataURL}
                  setSecondDataURL={setSecondDataURL}
                  setCalculatorItem={setCalculatorItem}
                />

                <SmallMultiImages
                  smallImages={smallImages}
                  setSmallImages={setSmallImages}
                  smallImageFiles={smallImageFiles}
                  setSmallImageFiles={setSmallImageFiles}
                  calculatorItem={calculatorItem}
                  setCalculatorItem={setCalculatorItem}
                />

                <MediumImage
                  md_image={md_image}
                  thirdFile={thirdFile}
                  setThirdFile={setThirdFile}
                  thirdDataURL={thirdDataURL}
                  setThirdDataURL={setThirdDataURL}
                  setCalculatorItem={setCalculatorItem}
                />

                <MediumMultiImages
                  mediumImages={mediumImages}
                  setMediumImages={setMediumImages}
                  mediumImageFiles={mediumImageFiles}
                  setMediumImageFiles={setMediumImageFiles}
                  calculatorItem={calculatorItem}
                  setCalculatorItem={setCalculatorItem}
                />

                <LargeImage
                  lg_image={lg_image}
                  fourthFile={fourthFile}
                  setFourthFile={setFourthFile}
                  fourthDataURL={fourthDataURL}
                  setFourthDataURL={setFourthDataURL}
                  setCalculatorItem={setCalculatorItem}
                />

                <LargeMultiImage
                  largeImages={largeImages}
                  setLargeImages={setLargeImages}
                  largeImageFiles={largeImageFiles}
                  setLargeImageFiles={setLargeImageFiles}
                  calculatorItem={calculatorItem}
                  setCalculatorItem={setCalculatorItem}
                />
              </Col>
            </CardBody>
          </Card>

          <Row className="py-4">
            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateProperty : addProperty}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm mb-4`
                      : `btn btn-rounded w-sm mb-4 btn-info`
                  }
                >
                  Save
                </Button>
                <Button
                  className="btn btn-rounded float-end w-sm mb-4"
                  color="secondary"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CalculatorItem
