import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Input, Container, Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { toast } from "react-toastify"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function VendorList() {
  document.title = "Vendor List"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [vendorList, setVendorList] = useState([])
  const [filteredVendorList, setFilteredVendorList] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => setShowModal(false)
  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getVendorData = async () => {
    try {
      const res = await axios.get(URL?.ALL_VENDOR)
      setVendorList(res?.data?.vendors)
      setFilteredVendorList(res?.data?.vendors)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorData()
  }, [])

  const columns = [
    {
      name: "First Name",
      selector: row => row.fname,
      sortable: true,
      width: "140px",
    },
    {
      name: "Last Name",
      selector: row => row.lname,
      sortable: true,
      width: "140px",
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: row => row.role,
      width: "160px",
    },
    // {
    //   name: "Parent Vendor",
    //   selector: row => row.parent,
    // },
    {
      name: "Agency",
      selector: row => row.agency_name,
      width: "180px",
    },
    {
      name: "Profile",
      selector: row => (
        <img
          style={{
            height: "60px",
            width: "60px",
            objectFit: "contain",
          }}
          alt="Profile"
          src={row.profile}
        />
      ),
      width: "180px",
    },
    {
      name: "Status",
      cell: row => (
        <div>
          <Button
            variant="outline"
            style={{ whiteSpace: "nowrap" }}
            color={`${row.status === true ? `success` : `dark`} `}
            className="btn btn-rounded float-end w-sm"
            onClick={() => handleUpdateStatus(row)}
          >
            {row.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      name: "Action",
      width: "280px",
      cell: row => (
        <Col
          sm={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex gap-2 float-end">
            <Button
              className="btn btn-rounded btn-warning  w-sm"
              onClick={() => handleEditVendorList(row)}
            >
              Edit
            </Button>
            <Button
              // onClick={() => handleDeleteVendorList(row)}
              onClick={() => showDeleteModal(row)}
              color="danger"
              className="btn btn-rounded  w-sm "
            >
              Delete
            </Button>
          </div>
        </Col>
      ),
    },
  ]

  const handleUpdateStatus = async item => {
    let sendStatus = item.status
    try {
      const res = await axios.put(URL.UPDATE_VENDOR_STATUS + `${item.id}`, {
        status: !sendStatus,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        getVendorData()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = vendorList.filter(item => {
      return item.fname.toLowerCase().match(search.toLowerCase())
    })

    setFilteredVendorList(result)
  }, [search])

  const handleAddVendorList = row => {
    history.push("/add-vendor")
  }

  const handleEditVendorList = row => {
    history.push("/add-vendor", { state: row })
  }

  const handleDeleteVendorList = async item => {
    try {
      const res = await axios.delete(URL.DELETE_VENDOR + `/${item.id}`)
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getVendorData()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Vendor List</ModalHeader>
        <ModalBody>
          Do you want to delete the row with email {deleteRecord.email}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteVendorList(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <Row>
          <Col
            sm={11}
            className="d-flex justify-content-between align-items-center"
          >
            <p className="h4 card-title font-size-22">All Vendor</p>
          </Col>
          <Col sm={1}>
            <Button
              onClick={handleAddVendorList}
              color="secondary"
              className="btn btn-info btn-rounded float-end w-sm mb-4"
            >
              Add
            </Button>
          </Col>
        </Row>
        <Container fluid={true} sm={12}>
          <DataTable
            columns={columns}
            data={filteredVendorList}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            customStyles={customStyles}
            subHeaderComponent={
              <Col className="pt-4 data-table" sm={12}>
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  id="table-search"
                  value={search}
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VendorList
