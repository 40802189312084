import React, { Suspense, useEffect, useState } from "react"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"
import { toast } from "react-toastify"

const GeneralSiteSettingLogo = React.lazy(() =>
  import("./GeneralSiteSettingLogo")
)
const GeneralSiteSettingIcon = React.lazy(() =>
  import("./GeneralSiteSettingIcon")
)

function GeneralSiteSetting(props) {
  document.title = "Edit General Site Setting"
  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)
  const [secondFile, setSecondFile] = useState(null)
  const [secondDataURL, setSecondDataURL] = useState(null)

  const [generalSettings, setGeneralSettings] = useState({
    logo: {},
    favicon: {},
    title: "",
    description: "",
    copyright: "",
  })

  const [isDisabled, setIsDisabled] = useState(true)

  const getGeneralSiteSettings = async () => {
    try {
      const res = await axios.get(URL.ALL_GENERAL_SITE_SETTING)
      setGeneralSettings({
        logo: res?.data?.data?.logo,
        favicon: res?.data?.data?.favicon,
        title: res?.data?.data?.title,
        description: res?.data?.data?.description,
        copyright: res?.data?.data?.copyright,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getGeneralSiteSettings()
  }, [])

  const { logo, favicon, title, description, copyright } = generalSettings

  const onChange = e => {
    setGeneralSettings(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const updateGeneralSiteSettings = async () => {
    let formData = new FormData()
    Object.keys(generalSettings).forEach(fieldName => {
      if (generalSettings[fieldName]) {
        formData.append(fieldName, generalSettings[fieldName])
      }
    })
    try {
      const res = await axios.put(URL.UPDATE_GENERAL_SITE_SETTING, formData)
      if (res.data.success) {
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  // useEffect(() => {
  //   if (
  //     (Object.keys(logo).length > 0 &&
  //       Object.keys(favicon).length > 0 &&
  //       logo.name !== "" &&
  //       favicon !== "" &&
  //       title.length > 0 &&
  //       description.length > 0 &&
  //       copyright.length > 0) ||
  //     (logo.name !== undefined && favicon.name !== undefined)
  //   ) {
  //     setIsDisabled(false)
  //   } else {
  //     setIsDisabled(true)
  //   }
  // })

  console.log(Object.keys(logo).length > 0 && logo.name !== "")
  console.log(logo.name)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row className="py-4">
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <p className="h4 card-title font-size-22">
                Edit General Site Setting
              </p>

              <Button
                className="btn btn-info btn-rounded float-end w-sm mb-4"
                // disabled={isDisabled}
                // className={
                //   isDisabled
                //     ? "btn btn-rounded float-end w-sm mb-4"
                //     : "btn btn-info btn-rounded float-end w-sm mb-4"
                // }
                onClick={updateGeneralSiteSettings}
              >
                Save
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Suspense fallback={<div>Loading...</div>}>
                    <GeneralSiteSettingLogo
                      logo={logo}
                      firstFile={firstFile}
                      setFirstFile={setFirstFile}
                      firstDataURL={firstDataURL}
                      setFirstDataURL={setFirstDataURL}
                      setGeneralSettings={setGeneralSettings}
                    />
                  </Suspense>

                  <Suspense fallback={<div>Loading...</div>}>
                    <GeneralSiteSettingIcon
                      favicon={favicon}
                      secondFile={secondFile}
                      setSecondFile={setSecondFile}
                      secondDataURL={secondDataURL}
                      setSecondDataURL={setSecondDataURL}
                      setGeneralSettings={setGeneralSettings}
                    />
                  </Suspense>

                  <div className="mt-3">
                    <label className="form-label">Title</label>
                    <input
                      name="title"
                      value={title}
                      className="form-control"
                      type="text"
                      onChange={onChange}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="form-label">Description</label>
                    <input
                      name="description"
                      value={description}
                      className="form-control"
                      type="text"
                      onChange={onChange}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="form-label">Copyright</label>
                    <input
                      name="copyright"
                      value={copyright}
                      className="form-control"
                      type="text"
                      onChange={onChange}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GeneralSiteSetting
