import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { CardTitle, Col, Container, Row } from "reactstrap"
import moment from "moment/moment"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import Backdrop from "@mui/material/Backdrop"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"

// Small Cards
import MonthlyUsers from "components/DataMetrics/MonthlyUsers"
import AvgLoginDuration from "components/DataMetrics/AvgLoginDuration"
import AvgKIOSKDuration from "components/DataMetrics/AvgKIOSKDuration"

// Properties rented pie chart
// import PropertiesRented from "components/DataMetrics/PropertiesRented"

// Average footfall line chart
import AverageFootfall from "components/DataMetrics/AverageFootfall"

// Average login usage chart
import AvgLoginUsageChart from "components/DataMetrics/AvgLoginUsageChart"

// Hot Property Cards
import HotPropertyCards from "components/DataMetrics/Cards/HotPropertyCards"

// Tables
import MonthlyUsersTable from "components/DataMetrics/Tables/MonthlyUsersTable"
import TotalLoginTable from "components/DataMetrics/Tables/TotalLoginTable"
import AverageKIOSKTable from "components/DataMetrics/Tables/AverageKIOSKTable"
import PropertiesCreated from "components/DataMetrics/PropertiesCreated"
import VendorsAdded from "components/DataMetrics/VendorsAdded"
import YearlyKioskUsage from "components/DataMetrics/YearlyKioskUsage"

const analytics = {
  icon: "mdi mdi-calendar-month",
  title: "Monthly Average Usage",
  desc: "View All Vendors",
}

const loginAnalytics = {
  icon: "mdi mdi-login",
  title: "Average Login Duration",
  value: "20",
  badgeValue: "423",
  color: "success",
  desc: "View All Users",
}

const kioskAnalytics = {
  icon: "mdi mdi-google-analytics",
  // title: "Average KIOSK usage",
  title: "Monthly KIOSK usage",
  value: "99",
  color: "success",
  desc: "KIOSK details",
}

const monthlyCreatedProperties = {
  icon: "bx bx-building-house",
  title: "Property created this month",
  value: "3",
  color: "success",
  desc: "Property details",
}

const monthlyAddedVendors = {
  icon: "fas fa-user-tie",
  title: "Vendors added this month",
  value: "3",
  color: "success",
  desc: "Vendor details",
}

const loadingSpinnerStyles = {
  zIndex: 999999999,
  fontSize: "5rem",
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
}

const DataMetrics = props => {
  document.title = "Viztown | Data Metrics"
  const [loading, setLoading] = useState(false)
  const [loginDataList, setLoginDataList] = useState([])
  const [monthlyData, setMonthlyData] = useState([])
  const [hotProperty, setHotProperty] = useState([])
  const [footfall, setFootfall] = useState([])
  const [monthlyProperty, setMonthlyProperty] = useState([])
  const [vendorsAdded, setVendorsAdded] = useState([])
  const [monthlyKiosk, setMonthlyKiosk] = useState([])
  const [yearlyKiosk, setYearlyKiosk] = useState([])

  const getLoginDataList = async () => {
    setLoading(true)
    try {
      const res = await axios.get(URL.GET_VENDOR_MONTHLY_LOGIN_ANALYTICS)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      setLoginDataList(
        res.data.data.filter(
          currElem => currElem?.month === moment().format("MMMM")
        )
      )
    } catch (error) {
      setLoading(false)
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const getVendorMonthlyLogin = async () => {
    try {
      const res = await axios.get(URL.GET_VENDOR_MONTHLY_LOGIN_ANALYTICS)
      if (res.data.success) {
        setMonthlyData(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const getHotProperty = async () => {
    try {
      const res = await axios.get(URL.GET_HOT_PROPERTY)
      if (res.data.success) {
        setHotProperty(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const getFootfallByMonth = async () => {
    try {
      const res = await axios.get(URL.GET_FOOTFALL_BY_MONTH)
      if (res.data.success) {
        setFootfall(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const getMonthlyCreatedProperty = async () => {
    try {
      const res = await axios.get(URL.GET_MONTYLY_CREATED_PROPERTY)
      if (res.data.success) {
        setMonthlyProperty(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const getMonthlyCreatedVendors = async () => {
    try {
      const res = await axios.get(URL.GET_MONTHLY_VENDORS_CREATED)
      if (res.data.success) {
        setVendorsAdded(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const getMonthlyKioskUsage = async () => {
    try {
      const res = await axios.get(URL.GET_MONTHLY_KIOSK_USAGE)
      if (res.data.success) {
        setMonthlyKiosk(res.data.kiosk_usage_this_month)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const getYearlyKioskUsage = async () => {
    try {
      const res = await axios.get(URL.GET_YEARLY_KIOSK_USAGE)
      if (res.data.success) {
        setYearlyKiosk(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    getVendorMonthlyLogin()
    getLoginDataList()
    getHotProperty()
    getFootfallByMonth()
    getMonthlyCreatedProperty()
    getMonthlyCreatedVendors()
    getMonthlyKioskUsage()
    getYearlyKioskUsage()
  }, [])

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div style={loadingSpinnerStyles}>
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid>
          <Row className="py-4 px-2">
            <div className="draggable-body-button">
              <Col sm={12}>
                <p className="h4 card-title font-size-22">Data Metrics</p>
              </Col>
            </div>
          </Row>

          <Row>
            <Col xl="12">
              <Row>
                <MonthlyUsers
                  report={analytics}
                  loginDataList={loginDataList}
                />
                <AvgLoginDuration
                  report={loginAnalytics}
                  loginDataList={loginDataList}
                />
                <AvgKIOSKDuration
                  report={kioskAnalytics}
                  monthlyKiosk={monthlyKiosk}
                />

                <PropertiesCreated
                  report={monthlyCreatedProperties}
                  monthlyProperty={monthlyProperty}
                />

                <VendorsAdded
                  report={monthlyAddedVendors}
                  vendorsAdded={vendorsAdded}
                />

                <AvgLoginUsageChart monthlyData={monthlyData} />
                <AverageFootfall footfall={footfall} />
                <YearlyKioskUsage yearlyKiosk={yearlyKiosk} />

                <div className="d-flex mb-4">
                  <i className="mdi mdi-fire me-1 font-size-16 property-icon"></i>
                  <CardTitle>Hot properties this week</CardTitle>
                </div>
                {hotProperty.map((currElem, index) => {
                  return <HotPropertyCards key={index} currElem={currElem} />
                })}

                <MonthlyUsersTable />
                <TotalLoginTable />
                <AverageKIOSKTable />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DataMetrics.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(DataMetrics)
