import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import {
  CardBody,
  Input,
  Col,
  Card,
  Row,
  Container,
  Button,
  Label,
  CardTitle,
} from "reactstrap"

function Amenities() {
  document.title = "Add Amenities"
  const history = useHistory()
  const { state } = useLocation()

  const [aminities, setAminities] = useState({
    name: state ? state?.state?.name : "",
  })

  const { name } = aminities
  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setAminities(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addAmenities = async () => {
    try {
      const res = await axios.post(URL.ADD_AMENITIES, {
        name: name,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/amenities")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateAmenities = async () => {
    try {
      const res = await axios.put(URL.UPDATE_AMENITIES + `/${state.state.id}`, {
        name: name,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/amenities")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (name.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [name])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Amenities" : "Add Amenities"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  onClick={state ? updateAmenities : addAmenities}
                  className={
                    isDisabled
                      ? `btn btn-rounded w-sm float-end mb-4`
                      : `btn btn-rounded w-sm float-end mb-4 btn-info`
                  }
                >
                  Save
                </Button>

                <Button
                  color="secondary"
                  className="btn btn-rounded w-sm mb-4"
                  onClick={handleGoBack}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Col component="form" noValidate autoComplete="off">
                <div className="mb-3">
                  <Label>Name</Label>
                  <Input
                    name="name"
                    value={name}
                    onChange={onChange}
                    id="outlined-basic"
                    placeholder="Name"
                  />
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Amenities
