import React, { useState, useEffect } from "react"
import DataTable from "react-data-table-component"
import {
  Row,
  Col,
  CardTitle,
  Button,
  Input,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useHistory } from "react-router-dom"

import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { toast } from "react-toastify"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

const ManageRole = () => {
  document.title = "Manage Role"
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [roleList, setRoleList] = useState([])
  const [filteredRoleList, setFilteredRoleList] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState({})

  const toggle = () => {
    setShowModal(false)
  }

  const showDeleteModal = record => {
    setShowModal(true)
    setDeleteRecord(record)
  }

  const getAllRoles = async () => {
    try {
      const res = await axios.get(URL.GET_ALL_ROLES)
      console.log(res)
      setRoleList(res.data.roles)
      setFilteredRoleList(res.data.roles)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteRoles = async item => {
    try {
      const res = await axios.delete(URL.DELETE_ROLE + `/${item.id}`)
      if (res.data.success) {
        setShowModal(false)
        setDeleteRecord({})
        toast.success(res.data.message)
        getAllRoles()
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    getAllRoles()
  }, [])

  const columns = [
    {
      name: "S. no",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Edit",
      cell: row => (
        <Button
          className="btn btn-rounded btn-warning float-end w-sm"
          onClick={() => handleEditRoles(row)}
        >
          Edit
        </Button>
      ),
    },
    {
      name: "Delete",
      cell: row =>
        row.name !== "Super Admin" && (
          <Button
            color="danger"
            className="btn btn-rounded float-end w-sm me-3"
            // onClick={() => handleDeleteRoles(row.id)}
            onClick={() => showDeleteModal(row)}
          >
            Delete
          </Button>
        ),
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = roleList.filter(item => {
      return item.name.toLowerCase().match(search.toLowerCase())
    })

    setFilteredRoleList(result)
  }, [search])

  const handleAddMenu = row => {
    history.push("/add-role-management")
  }

  const handleEditRoles = row => {
    history.push("/add-role-management", { state: row })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Role Management</ModalHeader>
        <ModalBody>
          Do you want to delete this row with role {deleteRecord.name}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDeleteRoles(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <div className="page-content">
        <div className="draggable-body-button"></div>
        <Row className="py-4 px-2">
          <Col
            sm={12}
            className="d-flex justify-content-between align-items-center"
          >
            <CardTitle className="h4 card-title font-size-22">
              Role Management
            </CardTitle>

            <Button
              onClick={handleAddMenu}
              color="secondary"
              className="btn btn-rounded btn-info float-end w-sm"
            >
              Add
            </Button>
          </Col>
        </Row>
        <Container fluid={true}>
          <DataTable
            columns={columns}
            data={filteredRoleList}
            customStyles={customStyles}
            fixedHeader
            pagination
            selectableRowsHighlight
            highlightOnHover
            fixedHeaderScrollHeight="500px"
            subHeader
            subHeaderComponent={
              <Col className="pt-4 data-table">
                <i className="table-search-icon bx bx-search-alt font-size-22" />
                <Input
                  label="Search"
                  placeholder="Search"
                  id="table-search"
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            }
            subHeaderAlign="left"
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageRole
