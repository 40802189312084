import React, { useEffect, useState } from "react"
import Backdrop from "@mui/material/Backdrop"
import { Col, Container, Row, Button, CardTitle } from "reactstrap"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"

import ExperienceFirstSection from "components/Commercial/ExperienceSection/ExperienceFirstSection"
import ExperienceSecondSection from "components/Commercial/ExperienceSection/ExperienceSecondSection"
import ExperienceThirdSection from "components/Commercial/ExperienceSection/ExperienceThirdSection"
import ExperienceFourthSection from "components/Commercial/ExperienceSection/ExperienceFourthSection"
import {
  getExperienceCenterData,
  postExperienceCenterData,
} from "helpers/experienceCenterData"

function ExperienceCenter() {
  document.title = "Experience Center"
  const [loading, setLoading] = useState(false)
  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)
  const [secondFile1, setSecondFile1] = useState(null)
  const [secondDataURL1, setSecondDataURL1] = useState(null)
  const [secondFile2, setSecondFile2] = useState(null)
  const [secondDataURL2, setSecondDataURL2] = useState(null)
  const [thirdFile, setThirdFile] = useState(null)
  const [thirdDataURL, setThirdDataURL] = useState(null)
  const [fourthFile, setFourthFile] = useState(null)
  const [fourthDataURL, setFourthDataURL] = useState(null)
  const [experienceCenterData, setExperienceCenterData] = useState({
    first_section_heading: "",
    first_section_desc: "",
    first_section_file: {},
    second_section_heading: "",
    second_section_desc: "",
    second_section_orgimg: {},
    second_section_modimg: {},
    third_section_heading: "",
    third_section_desc: "",
    third_section_file: {},
    fourth_section_heading: "",
    fourth_section_desc: "",
    fourth_section_file: {},
  })

  useEffect(() => {
    getExperienceCenterData(
      experienceCenterData,
      setExperienceCenterData,
      setLoading
    )
  }, [])

  const onSubmit = () => {
    setFirstDataURL(null)
    setSecondDataURL1(null)
    setSecondDataURL2(null)
    setThirdDataURL(null)
    setFourthDataURL(null)
    postExperienceCenterData(
      experienceCenterData,
      setExperienceCenterData,
      setLoading
    )
  }

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div
            style={{
              zIndex: 999999999,
              fontSize: "5rem",
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                Edit Experience Center
              </CardTitle>

              <Button
                onClick={onSubmit}
                className="btn btn-rounded btn-info float-end w-sm mb-4"
              >
                Save Changes
              </Button>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <div className="page-title-box d-block align-items-center justify-content-between">
                <ExperienceFirstSection
                  firstFile={firstFile}
                  setFirstFile={setFirstFile}
                  firstDataURL={firstDataURL}
                  setFirstDataURL={setFirstDataURL}
                  experienceCenterData={experienceCenterData}
                  setExperienceCenterData={setExperienceCenterData}
                />

                <ExperienceSecondSection
                  secondFile1={secondFile1}
                  setSecondFile1={setSecondFile1}
                  secondDataURL1={secondDataURL1}
                  setSecondDataURL1={setSecondDataURL1}
                  secondFile2={secondFile2}
                  setSecondFile2={setSecondFile2}
                  secondDataURL2={secondDataURL2}
                  setSecondDataURL2={setSecondDataURL2}
                  experienceCenterData={experienceCenterData}
                  setExperienceCenterData={setExperienceCenterData}
                />

                <ExperienceThirdSection
                  thirdFile={thirdFile}
                  setThirdFile={setThirdFile}
                  thirdDataURL={thirdDataURL}
                  setThirdDataURL={setThirdDataURL}
                  experienceCenterData={experienceCenterData}
                  setExperienceCenterData={setExperienceCenterData}
                />

                <ExperienceFourthSection
                  fourthFile={fourthFile}
                  setFourthFile={setFourthFile}
                  fourthDataURL={fourthDataURL}
                  setFourthDataURL={setFourthDataURL}
                  experienceCenterData={experienceCenterData}
                  setExperienceCenterData={setExperienceCenterData}
                />
              </div>
            </Col>
            <Col sm={12} className="py-2">
              <Button
                onClick={onSubmit}
                className="btn btn-rounded btn-info float-end w-sm mb-4"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExperienceCenter
