import axios from "./api_helper"
import URL from "./url_helper"
import { toast } from "react-toastify"

export const getExperienceCenterData = async (
  experienceCenterData,
  setExperienceCenterData,
  setLoading
) => {
  setLoading(true)
  try {
    const res = await axios.get(URL.ALL_EXPERIENCE_CENTER)
    console.log(res.data.data)
    if (res.data.success) {
      setExperienceCenterData({
        first_section_heading: res.data.data.first_section_heading,
        first_section_desc: res.data.data.first_section_desc,
        first_section_file: res.data.data.first_section_file,
        second_section_heading: res.data.data.second_section_heading,
        second_section_desc: res.data.data.second_section_desc,
        second_section_orgimg: res.data.data.second_section_orgimg,
        second_section_modimg: res.data.data.second_section_modimg,
        third_section_heading: res.data.data.third_section_heading,
        third_section_desc: res.data.data.third_section_desc,
        third_section_file: res.data.data.third_section_file,
        fourth_section_heading: res.data.data.fourth_section_heading,
        fourth_section_desc: res.data.data.fourth_section_desc,
        fourth_section_file: res.data.data.fourth_section_file,
      })
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  } catch (error) {
    setLoading(false)
    toast.error(error)
    if (error.response) {
      toast.error(error.response.data.message)
    } else if (error.request) {
    } else {
      toast.error(error)
    }
  }
}

export const postExperienceCenterData = async (
  experienceCenterData,
  setExperienceCenterData,
  setLoading
) => {
  setLoading(true)
  let formData = new FormData()
  console.log(experienceCenterData)
  Object.keys(experienceCenterData).forEach(fieldName => {
    if (experienceCenterData[fieldName]) {
      formData.append(fieldName, experienceCenterData[fieldName])
    }
  })

  try {
    const res = await axios.put(URL.ALL_EXPERIENCE_CENTER_UPDATE, formData)
    if (res.data.success) {
      getExperienceCenterData(
        experienceCenterData,
        setExperienceCenterData,
        setLoading
      )
      toast.success(res.data.message)
    }
    setLoading(false)
  } catch (error) {
    toast.error(error)
    setLoading(false)
    if (error.response) {
      toast.error(error.response.data.message)
    } else if (error.request) {
    } else {
      toast.error(error)
    }
  }
}
