import React, { useEffect, useState } from "react"
import { Card, Col, Row, Label, Input, Button, Container, CardTitle } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"

function ManageProcess() {
  document.title = "Add Our Process"
  const history = useHistory()
  const { state } = useLocation()
  const [manageTeam, setManageTeam] = useState({
    process: state ? state?.state?.process : "",
  })

  const { process } = manageTeam
  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    if(e.target.value.length > 35) {
      return;
    }
    setManageTeam(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addProcess = async () => {
    try {
      const res = await axios.post(URL.ADD_PROCESS, {
        process,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/our-process-steps")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateProcess = async () => {
    try {
      const res = await axios.put(URL.UPDATE_PROCESS + `/${state.state.id}`, {
        process,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/our-process-steps")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if(process.length > 0){
     setIsDisabled(false)
    }else{
     setIsDisabled(true)
    }
  }, [process])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col sm={12} className="d-flex justify-content-between align-items-center">
              <CardTitle className="h4 card-title font-size-22">
                {state?.state ? "Edit Our Process" : "Add Our Process"}
              </CardTitle>
            <div className="d-flex flex-wrap gap-2 float-end">
              <Button
                disabled={isDisabled}
                onClick={state ? updateProcess : addProcess}
                className={
                  isDisabled
                  ? `btn btn-rounded w-sm mb-4`
                  : `btn btn-rounded w-sm mb-4 btn-info`
                }
              >
                Save
              </Button>
            
              <Button
                color="secondary"
                className="btn btn-rounded w-sm mb-4"
                onClick={handleGoBack}
              >
                Back
              </Button>
              </div>
            </Col>
            <Col>
              <Card>
                <Row className="p-2">
                  <Col>
                    <div className="mt-3 py-2">
                      <Label>Process</Label>
                      <Input
                        name="process"
                        value={process}
                        onChange={onChange}
                        id="outlined-basic"
                        label="Process"
                        variant="outlined"
                        placeholder="Process"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageProcess
