import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function GallerySection({
  galleryImages,
  setGalleryImages,
  galleryImageFiles,
  setGalleryImageFiles,
  commercialData,
  setCommercialData,
}) {
  const {
    commercial_gallery,
    gallery_head,
    gallery_desc,
    removed_gallery_ids,
  } = commercialData

  const handleChange = e => {
    setCommercialData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleMedia = e => {
    const { files } = e.target
    const validImageFiles = []
    if (e.target.files) {
      let img = e.target.files
      setCommercialData(prevState => ({
        ...prevState,
        commercial_gallery: [...img, ...commercial_gallery],
      }))
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file)
      }
    }
    if (validImageFiles.length) {
      setGalleryImageFiles([...galleryImageFiles, ...validImageFiles])
      return
    }
    alert("Selected images are not of valid type!")
  }

  const handleRemoveMedia = item => {
    let temp = commercial_gallery
    temp = temp.filter(el => el.id !== item.id)
    setCommercialData(prevState => ({
      ...prevState,
      commercial_gallery: temp,
      removed_gallery_ids: [...removed_gallery_ids, item.id],
    }))
  }

  const handleRemove = (item, index) => {
    let fileToRemove = galleryImageFiles[index]
    const temp = galleryImages.filter((el, idx) => idx !== index)
    let temp_ = commercial_gallery.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })
    let temp__ = galleryImageFiles.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })

    setCommercialData(prevState => ({
      ...prevState,
      commercial_gallery: [...temp_],
    }))
    setGalleryImages([...temp])
    setGalleryImageFiles([...temp__])
  }

  useEffect(() => {
    const fileReaders = []
    let isCancel = false
    if (galleryImageFiles.length) {
      const promises = galleryImageFiles.map(file => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReaders.push(fileReader)
          fileReader.onload = e => {
            const { result } = e.target
            if (result) {
              resolve(result)
            }
          }
          fileReader.onabort = () => {
            reject(new Error("File reading aborted"))
          }
          fileReader.onerror = () => {
            reject(new Error("Failed to read file"))
          }
          fileReader.readAsDataURL(file)
        })
      })
      Promise.all(promises)
        .then(images => {
          if (!isCancel) {
            setGalleryImages([...images])
          }
        })
        .catch(reason => {
          console.log(reason)
        })
    }
    return () => {
      isCancel = true
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [galleryImageFiles])

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-3">Gallery Section</CardTitle>

            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="first_section_heading"
                onChange={handleChange}
                value={gallery_head || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <Row>
              <Col>
                <Card className="shadow">
                  <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data={gallery_desc}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setCommercialData(prevState => ({
                          ...prevState,
                          gallery_desc: data,
                        }))
                      }}
                    />
                  </Form>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <CardTitle className="h4 mb-4">Gallery</CardTitle>
                <div className="row">
                  <Col sm={12}>
                    <div className="mt-3">
                      <div className="img-container">
                        {commercial_gallery.length > 0 &&
                          commercial_gallery?.map(item => {
                            return (
                              item.image_name && (
                                <div key={item.id} className="media-container">
                                  <div className="media-main">
                                    <img
                                      className="media-img"
                                      src={`${item.image_path}?w=164&h=164&fit=crop&auto=format`}
                                      srcSet={`${item.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                      alt="Banner"
                                      loading="lazy"
                                    />
                                    <div>
                                      <i
                                        className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                        onClick={() => handleRemoveMedia(item)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          })}

                        {galleryImages.length > 0 &&
                          galleryImages?.map((image, index) => {
                            return (
                              <div key={index} className="media-container">
                                <div className="media-main">
                                  <img
                                    className="media-img"
                                    src={image}
                                    srcSet={image}
                                    alt="Banner"
                                    loading="lazy"
                                  />
                                  <div>
                                    <i
                                      className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                      onClick={() =>
                                        handleRemove(galleryImages, index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                      <Input
                        name="file"
                        accept="image/*"
                        type="file"
                        onChange={handleMedia}
                        multiple
                        id="gallery_images"
                      />
                      <Label
                        htmlFor="gallery_images"
                        className="font-size-12 text-gray"
                      >
                        (You Can Select Multiple Images)
                      </Label>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default GallerySection
