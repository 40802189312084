import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { useHistory, useLocation } from "react-router-dom"
import {
  CardBody,
  Col,
  Card,
  Input,
  Container,
  Row,
  Button,
  Label,
} from "reactstrap"

function WhyPartners() {
  document.title = "Add Why Partner"
  const history = useHistory()
  const { state } = useLocation()
  const [adminDetails, setAdminDetails] = useState({
    heading: state ? state?.state?.heading : "",
    description: state ? state?.state?.description : "",
    linkTo: state ? state?.state?.link : "",
  })

  const { heading, description, linkTo } = adminDetails
  const [isDisabled, setIsDisabled] = useState(true)

  const onChange = e => {
    setAdminDetails(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const createWhyPartners = async () => {
    try {
      const res = await axios.post(URL.WHY_PARTNER_ADD, {
        heading: heading,
        description: description,
        link: linkTo,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/why-partner")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateWhyPartners = async () => {
    try {
      const res = await axios.put(
        URL.WHY_PARTNER_UPDATE + `/${state?.state?.id}`,
        {
          heading: heading,
          description: description,
          link: linkTo,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/why-partner")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (heading.length > 0 && description.length > 0 && linkTo.length > 0) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [heading, description, linkTo])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="py-4">
              <Col
                lg={12}
                className="d-flex justify-content-between align-items-center"
              >
                <p className="h4 card-title font-size-22">
                  {state ? "Edit Why Partners" : "Add Why Partners"}
                </p>
                <div className="d-flex flex-wrap gap-2 float-end">
                  <Button
                    disabled={isDisabled}
                    onClick={state ? updateWhyPartners : createWhyPartners}
                    className={
                      isDisabled
                        ? "btn btn-rounded w-sm mb-4"
                        : "btn btn-rounded w-sm mb-4 btn-info"
                    }
                  >
                    Save
                  </Button>

                  <Button
                    color="secondary"
                    className="btn btn-rounded  w-sm mb-4"
                    onClick={handleGoBack}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </div>
          </Row>

          <Card>
            <CardBody>
              <Col component="form" autoComplete="off">
                <div className="mb-3">
                  <Label>Heading</Label>
                  <Input
                    name="heading"
                    value={heading}
                    onChange={onChange}
                    id="outlined-basic"
                    placeholder="Heading"
                  />
                </div>
                <div className="mb-3">
                  <Label>Description</Label>

                  <Input
                    name="description"
                    value={description}
                    onChange={onChange}
                    id="outlined-basic"
                    placeholder="Description"
                    variant="outlined"
                  />
                </div>
                <div className="mb-3">
                  <Label>Link</Label>
                  <Input
                    name="linkTo"
                    value={linkTo}
                    onChange={onChange}
                    id="outlined-basic"
                    placeholder="Link"
                    variant="outlined"
                  />
                </div>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WhyPartners
