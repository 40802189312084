import React, { useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import SubMenuList from "./SubMenuList"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

function EditAdminMenu() {
  const history = useHistory()
  const { state } = useLocation()
  const [menuDetails, setMenuDetails] = useState({
    menuName: state ? state?.state?.label : "",
    link: state ? state?.state?.link : "",
  })

  const { menuName, link } = menuDetails

  const onChange = e => {
    setMenuDetails(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleUpdateMenu = async id => {
    try {
      const res = await axios.put(URL.UDPATE_MENU + `/${id}`, {
        name: menuName,
        link: link,
      })
      if (res.data.success) {
        history.push("/all-admin-menu")
        toast.success(res.data.message)
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                Edit Menu/ Sub Menu
              </CardTitle>

              <Button
                className="btn btn-rounded w-sm mb-4 float-end"
                onClick={handleGoBack}
              >
                Back
              </Button>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <div className="mb-3">
                <Label>Menu Name</Label>
                <Input
                  name="menuName"
                  value={menuName}
                  onChange={onChange}
                  label="Menu"
                />
              </div>

              <div className="mb-3">
                <Label>Link</Label>

                <Input
                  name="link"
                  value={link}
                  onChange={onChange}
                  label="Menu"
                />
              </div>
              <Row>
                <Col sm={12}>
                  <Button
                    className="btn btn-info btn-rounded w-sm float-end mb-4"
                    onClick={() => handleUpdateMenu(state?.state?.id)}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {state?.state?.children.map((item, index) => (
            <SubMenuList
              key={item.id}
              item={item}
              itemIndex={index}
              mainData={state?.state?.children}
            />
          ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditAdminMenu
