import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"

import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import RenderImage from "./3DRenderImage"

function Render() {
  const { state } = useLocation()
  document.title = state ? "Edit 3D Render" : "Add 3D Render"
  const history = useHistory()
  const [renderList, setRenderList] = useState({
    property_id: state ? state?.state?.property_id : "",
    vendor_id: state ? state?.state?.vendor_id : "",
    sub_sub_menu_id: state ? state?.state?.sub_sub_menu_id : "",
    entries: state
      ? state.state.ThreeDRenderChild
      : [{ id: null, image: {}, title: "" }],
  })

  const { property_id, vendor_id, sub_sub_menu_id, entries } = renderList

  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")
  const [propertyList, setPropertyList] = useState([])
  const [propertyDropdown, setPropertyDropdown] = useState("")

  const [typeList, setTypeList] = useState([])
  const [typeDropdown, setTypeDropdown] = useState("")
  const [removedEntries, setRemovedEntries] = useState([])

  const handleAddEntries = () => {
    setRenderList(prevState => ({
      ...prevState,
      entries: [...entries, { id: null, image: {}, title: "" }],
    }))
  }

  const handleRemoveEntries = async (index, item) => {
    const list = [...entries]

    if (entries.length == 1) {
      return toast.error(
        "Minimum one site-photo is required, so we can't delete that entry"
      )
    }

    if (item?.id) {
      let tempList = [...removedEntries]
      tempList.push(item.id)
      tempList = [...new Set(tempList)]
      setRemovedEntries(tempList)
    }

    list.splice(index, 1)
    setRenderList(prevState => ({
      ...prevState,
      entries: list,
    }))
  }

  const getVendorParent = async () => {
    let vendorOptions = []
    try {
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let property = item.Property
        let obj = {
          id,
          name,
          property,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getVendorParent()
  }, [])

  const getAllRenderMenu = async () => {
    try {
      const res = await axios.get(URL.ALL_RENDER_SUB_SUB_MENU)
      setTypeList(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllRenderMenu()
  }, [])

  useEffect(() => {
    let temp = typeList.find(el => el.id === sub_sub_menu_id)
    setTypeDropdown(temp)
  }, [sub_sub_menu_id, vendorList.length])

  useEffect(() => {
    let temp = vendorList.find(el => el.id === vendor_id)
    setVendorDropdown(temp)
    setPropertyList(temp?.property)
  }, [vendor_id, vendorList?.length])

  useEffect(() => {
    let temp = propertyList?.find(el => el?.id === property_id)
    setPropertyDropdown(temp)
  }, [property_id, propertyList?.length])

  const handleEntryChange = (e, index) => {
    const { name, value } = e.target
    const list = [...entries]
    list[index][name] = value
    setRenderList(prevState => ({
      ...prevState,
      entries: list,
    }))
  }

  const handleSaveRender = async () => {
    let formData = new FormData()
    Object.keys(renderList).forEach(fieldName => {
      if (renderList[fieldName]) {
        if (fieldName === "entries") {
          formData.append("entries", JSON.stringify(entries))
          renderList[fieldName].forEach(el => {
            formData.append("image", el.image)
          })
        }
      }
    })

    formData.append("property_id", propertyDropdown?.id)
    formData.append("vendor_id", vendorDropdown?.id)
    formData.append("sub_sub_menu_id", typeDropdown?.id)

    try {
      const res = await axios.post(URL.ADD_RENDER_PROPERTY, formData)
      if (res.data.success) {
        history.push("/3d-render")
        toast.success(res.data.message)
      }
    } catch (error) {
      toast.error(error)
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdateRender = async () => {
    let formData = new FormData()
    let entries_ = entries.map(el => {
      return {
        id: el.id,
        title: el.title,
        image: { name: el.image.name },
      }
    })

    Object.keys(renderList).forEach(fieldName => {
      if (renderList[fieldName]) {
        if (fieldName === "entries") {
          formData.append("entries", JSON.stringify(entries_))
          renderList[fieldName].forEach(el => {
            formData.append("image", el.image)
          })
        }
      }
    })

    formData.append("property_id", propertyDropdown?.id)
    formData.append("vendor_id", vendorDropdown?.id)
    formData.append("sub_sub_menu_id", typeDropdown?.id)

    axios
      .post(URL.DELETE_RENDER_PROPERY_CHILD, {
        ids: removedEntries,
      })
      .then(async res => {
        try {
          const res = await axios.put(
            URL.UPDATE_RENDER_PROPERTY + `/${state?.state?.id}`,
            formData
          )
          if (res.data.success) {
            history.push("/3d-render")
            toast.success(res.data.message)
          }
        } catch (error) {
          toast.error(error)
          console.log(error)
          if (error.response) {
            toast.error(error.response.data.message)
          } else if (error.request) {
          } else {
            toast.error(error)
          }
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit 3D Render" : "Add 3D Render"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  className="btn btn-info btn-rounded w-sm mb-4"
                  onClick={state ? handleUpdateRender : handleSaveRender}
                >
                  Save
                </Button>

                <Link to="/3d-render">
                  <Button
                    color="secondary"
                    className="btn btn-rounded w-sm mb-4"
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label>Vendor</Label>
                    <Select
                      value={vendorDropdown}
                      options={vendorList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Vendor"
                      onChange={(event, val) => {
                        setVendorDropdown(event)

                        setPropertyList(event?.property)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Property</Label>
                    <Select
                      value={propertyDropdown}
                      options={propertyList}
                      getOptionLabel={option => `${option.property_id}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Property"
                      onChange={(event, val) => {
                        setPropertyDropdown(event)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Type</Label>
                    <Select
                      value={typeDropdown}
                      options={typeList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      classNamePrefix="select2-selection"
                      placeholder="Select Type"
                      onChange={(event, val) => {
                        setTypeDropdown(event)
                      }}
                    />
                  </div>

                  {entries?.map((singleEntry, index) => {
                    return (
                      <div key={index} className="add-children">
                        <RenderImage
                          image={singleEntry.image}
                          index={index}
                          entries={entries}
                          setRenderList={setRenderList}
                        />

                        <div className="mb-3">
                          <Label>Title</Label>
                          <Input
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={singleEntry.title}
                            onChange={e => handleEntryChange(e, index)}
                          />
                        </div>
                        <div className="mb-3">
                          {entries.length > 1 && (
                            <Button
                              color="secondary"
                              onClick={() =>
                                handleRemoveEntries(index, singleEntry)
                              }
                              className="btn btn-rounded btn-danger w-sm me-4 mb-4"
                            >
                              Remove
                            </Button>
                          )}
                          {entries.length - 1 === index && (
                            <Button
                              color="secondary"
                              onClick={handleAddEntries}
                              className="btn btn-rounded btn-success w-sm mb-4"
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  className="btn btn-info btn-rounded w-sm mb-4"
                  onClick={state ? handleUpdateRender : handleSaveRender}
                >
                  Save
                </Button>

                <Link to="/3d-render">
                  <Button
                    color="secondary"
                    className="btn btn-rounded w-sm mb-4"
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Render
