import React, { useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import ManageMenuImage from "./ManageMenuImage"

import {
  CardBody,
  Col,
  Input,
  Label,
  Card,
  Container,
  Row,
  Button,
} from "reactstrap"

function ManageMenu() {
  const history = useHistory()
  const { state } = useLocation()

  const [manageMenu, setManageMenu] = useState({
    name: state ? state?.state?.name : "",
    banner_heading: state ? state?.state?.banner_heading : "",
    banner_description: state ? state?.state?.banner_description : "",
    banner_img: state ? state?.state?.banner_img : "",
  })

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  const { name, banner_heading, banner_description, banner_img } = manageMenu

  const onChange = e => {
    if (e.target.value.length > 100) {
      return
    }
    setManageMenu(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleDescription = e => {
    if (e.target.value.length > 1000) {
      return
    }
    setManageMenu(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const updateTestimonial = async () => {
    let formData = new FormData()
    Object.keys(manageMenu).forEach(fieldName => {
      if (manageMenu[fieldName]) {
        formData.append(fieldName, manageMenu[fieldName])
      }
    })
    try {
      const res = await axios.put(
        URL.UPDATE_MANAGE_MENU + `/${state?.state?.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-menu")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="py-4">
              <Col
                lg={12}
                className="d-flex justify-content-between align-items-center"
              >
                <p className="h4 card-title font-size-22">
                  {state ? "Edit Menu" : "Add Menu"}
                </p>
                <div className="d-flex flex-wrap gap-2 float-end">
                  <Button
                    onClick={updateTestimonial}
                    className="btn btn-info btn-rounded float-end w-sm"
                  >
                    Save
                  </Button>
                  <Button
                    className="btn btn-rounded float-end w-sm"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </div>
          </Row>
          <Card>
            <CardBody>
              <div className="mb-3">
                <Label>Menu Name</Label>
                <Input
                  name="name"
                  value={name}
                  onChange={onChange}
                  id="outlined-basic"
                  placeholder="Menu Name"
                  variant="outlined"
                />
              </div>

              <div className="mb-3">
                <Label>Banner Heading</Label>
                <Input
                  name="banner_heading"
                  value={banner_heading}
                  onChange={onChange}
                  id="outlined-basic"
                  placeholder="Banner Heading"
                  variant="outlined"
                />
              </div>

              <div className="mb-3">
                <Label>Banner Description</Label>
                <Input
                  name="banner_description"
                  value={banner_description}
                  onChange={handleDescription}
                  id="outlined-basic"
                  placeholder="Banner Heading"
                  variant="outlined"
                />
              </div>

              <ManageMenuImage
                banner_img={banner_img}
                firstFile={firstFile}
                setFirstFile={setFirstFile}
                firstDataURL={firstDataURL}
                setFirstDataURL={setFirstDataURL}
                setManageMenu={setManageMenu}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageMenu
