import React, { useEffect, useState } from "react"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Image from "./Image"
import Image1 from "./Image1"
import Image2 from "./Image2"
import { toast } from "react-toastify"
import { CardBody, Container, Row, Col, Button, Card, Label } from "reactstrap"
import LoadingSpinner from "../../assets/images/ball-triangle.svg"
import Backdrop from "@mui/material/Backdrop"

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

const ManageAboutImage = () => {
  document.title = "Manage About"
  const [loading, setLoading] = useState(false)
  const [manageAbout, setManageAbout] = useState({
    description: "",
    img: {},
    img1: {},
    img2: {},
  })

  const [imgFile, setImgFile] = useState([])
  const [imgDataURL, setImgDataURL] = useState([])
  const [img1File, setImg1File] = useState([])
  const [img1DataURL, setImg1DataURL] = useState([])
  const [img2File, setImg2File] = useState([])
  const [img2DataURL, setImg2DataURL] = useState([])

  const { description, img, img1, img2 } = manageAbout

  const getManageHomeData = async () => {
    setLoading(true)
    try {
      const res = await axios.get(URL.MANAGE_ABOUT)
      if (res.data.success) {
        setManageAbout(prevState => ({
          ...prevState,
          description: res.data.data.description,
          img: res.data.data.img,
          img1: res.data.data.img1,
          img2: res.data.data.img2,
        }))
      }

      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } catch (error) {
      setLoading(false)
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const clearFieldData = () => {
    setImgFile([])
    setImgDataURL([])
    setImg1File([])
    setImg1DataURL([])
    setImg2File([])
    setImg2DataURL([])
  }

  const updateManageAbout = async () => {
    setLoading(true)
    const formData = new FormData()
    Object?.keys(manageAbout).forEach(fieldName => {
      formData.append(fieldName, manageAbout[fieldName])
    })

    try {
      const res = await axios.put(URL.UPDATE_MANAGE_ABOUT, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        getManageHomeData()
        clearFieldData()
      }
      setLoading(false)
    } catch (error) {
      toast.error(error)
      setLoading(false)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    getManageHomeData()
  }, [])

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div
            style={{
              zIndex: 999999999,
              fontSize: "5rem",
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={LoadingSpinner} />
          </div>
        </Backdrop>
      )}
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Row>
              <div className="py-4 draggable-body-button">
                <p className="h4 card-title font-size-22">Edit About Details</p>
                <Button
                  className="btn btn-rounded btn-info float-end w-sm"
                  onClick={updateManageAbout}
                >
                  Save Changes
                </Button>
              </div>
            </Row>

            <Row>
              <Col sm={12}>
                <Card>
                  <CardBody>
                    <Label htmlFor="formrow-heading-Input">Description</Label>
                    <CKEditor
                      editor={ClassicEditor}
                      CardBody
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setManageAbout(prevState => ({
                          ...prevState,
                          description: data,
                        }))
                      }}
                    />
                  </CardBody>
                </Card>

                <Image
                  img={img}
                  imgFile={imgFile}
                  setImgFile={setImgFile}
                  imgDataURL={imgDataURL}
                  setImgDataURL={setImgDataURL}
                  manageAbout={manageAbout}
                  setManageAbout={setManageAbout}
                />
                <Image1
                  img1={img1}
                  img1File={img1File}
                  setImg1File={setImg1File}
                  img1DataURL={img1DataURL}
                  setImg1DataURL={setImg1DataURL}
                  manageAbout={manageAbout}
                  setManageAbout={setManageAbout}
                />
                <Image2
                  img2={img2}
                  img2File={img2File}
                  setImg2File={setImg2File}
                  img2DataURL={img2DataURL}
                  setImg2DataURL={setImg2DataURL}
                  manageAbout={manageAbout}
                  setManageAbout={setManageAbout}
                />
              </Col>
            </Row>
            <Col sm={12} className="py-2">
              <Button
                className="btn btn-rounded btn-info float-end w-sm"
                onClick={updateManageAbout}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageAboutImage
