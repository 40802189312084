import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import Select from "react-select"
import { toast } from "react-toastify"
import {
  Input,
  Container,
  Col,
  Row,
  Button,
  CardBody,
  Label,
  Card,
} from "reactstrap"

function AddAdmin() {
  document.title = "Add Space Owner And Clients"
  const history = useHistory()
  const { state } = useLocation()
  const [manageSOC, setManageSOC] = useState({
    heading: state ? state.state.heading : "",
    category: state ? state.state.category : "",
  })

  const { heading, category } = manageSOC
  const [isDisabled, setIsDisabled] = useState(true)
  const [dropdownValue, setDropdownValue] = useState("")

  const onChange = e => {
    if(e.target.value.length > 25) {
      return;
    }
    setManageSOC(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addManageSOC = async () => {
    try {
      const res = await axios.post(URL.ADD_SPACE_OWNER_AND_CLIENT, {
        heading: heading,
        category: dropdownValue.category,
      })
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-space-owner-and-client")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateManageSOC = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_SPACE_OWNER_AND_CLIENT + `${state.state.id}`,
        {
          heading: heading,
          category: dropdownValue.category,
        }
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-space-owner-and-client")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  console.log(dropdownValue, isDisabled)

  const [manageSOCOptions, setManageSOCOptions] = useState([
    {
      id: 1,
      name: "Clients",
      category: "Client",
    },
    {
      id: 2,
      name: "Space Owner",
      category: "Space_Owner",
    },
  ])

  useEffect(() => {
    let temp = manageSOCOptions.find(el => el.category === category)
    setDropdownValue(temp)
  }, [category])

  useEffect(() => {
    if (heading.length > 0 && dropdownValue !== undefined) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [heading, dropdownValue])

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="py-4">
              <Col
                lg={12}
                className="d-flex justify-content-between align-items-center"
              >
                <p className="h4 card-title font-size-22">
                  {state
                    ? "Edit Space Owner And Clients"
                    : "Add Space Owner And Clients"}
                </p>
                <div className="d-flex flex-wrap gap-2 float-end">
                  <Button
                    disabled={isDisabled}
                    onClick={state ? updateManageSOC : addManageSOC}
                    className={
                      isDisabled
                        ? `btn btn-rounded w-sm mb-4`
                        : `btn btn-rounded w-sm mb-4 btn-info`
                    }
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    className="btn btn-rounded float-end w-sm mb-4"
                    onClick={handleGoBack}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </div>

            <Col sm={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                          disablePortal
                          id="combo-box-demo"
                          value={dropdownValue}
                          options={manageSOCOptions}
                          getOptionLabel={option => `${option.name}`}
                          getOptionValue={option => `${option.id}`}
                          renderInput={params => (
                            <Input {...params} label="Category" />
                          )}
                          onChange={event => {
                            setDropdownValue(event)
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <Label>Heading</Label>
                        <Input
                          name="heading"
                          value={heading}
                          onChange={onChange}
                          id="outlined-basic"
                          label="Heading"
                          variant="outlined"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/* <Row>
              <Col component="form" noValidate autoComplete="off">
               
              </Col>
            </Row> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddAdmin
