import React, { useRef, useState, useCallback, useEffect } from "react"
import { Table } from "antd"
import update from "immutability-helper"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useHistory } from "react-router-dom"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import DragIcon from "../../assets/images/drag.png"

const type = "DragableBodyRow"

const DragableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop(
    () => ({
      accept: type,
      collect: monitor => {
        const { index: dragIndex } = monitor.getItem() || {}
        if (dragIndex === index) {
          return {}
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? "drop-over-downward" : "drop-over-upward",
        }
      },
      drop: item => {
        moveRow(item.index, index)
      },
    }),
    [index]
  )

  const [, drag] = useDrag(
    () => ({
      type,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index]
  )

  drop(drag(ref))

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    ></tr>
  )
}

const components = {
  body: {
    row: DragableBodyRow,
  },
}

const ManageSpaceOwner = ({
  data,
  setData,
  handleDelete,
  handleUpdateStatus,
  toggle,
  showModal,
  showDeleteModal,
  deleteRecord,
}) => {
  const history = useHistory()

  const columns = [
    {
      key: "id",
      width: 40,
      render: () => (
        <img
          style={{
            textAlign: "center",
            height: "20px",
            width: "20px",
            objectFit: "cover",
          }}
          alt="Image"
          src={DragIcon}
        />
      ),
    },
    {
      title: "Heading",
      dataIndex: "heading",
      key: "heading",
      width: 440,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <div>
          <Button
            variant="outline"
            className="btn btn-rounded w-sm"
            onClick={() => handleUpdateStatus(record)}
            color={`${record.status === true ? `success` : `dark`} `}
          >
            {record.status === true ? "ACTIVE" : "NOT ACTIVE"}
          </Button>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <div size="middle">
          <Button
            className="btn btn-rounded w-sm btn-warning me-3"
            onClick={() => handleEditSOC(record)}
          >
            Edit
          </Button>
          <Button
            color="danger"
            variant="outline "
            className="btn btn-rounded w-sm"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ]

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    },
    [data]
  )

  const temp = data?.map((item, idx) => {
    return { heading: item.heading, id: item.id, sort_order: idx + 1 }
  })

  useEffect(() => {
    saveSortOrder()
  }, [data])

  const saveSortOrder = async () => {
    try {
      const res = await axios.put(
        URL.UPDATE_SPACE_OWNER_AND_CLIENT_STATUS_SORT_ORDER,
        {
          new_order: temp,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditSOC = record => {
    history.push("/add-space-owner-and-client", { state: record })
  }
  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader toggle={toggle}>Our Team</ModalHeader>
        <ModalBody>
          Do you want to delete the row with heading {deleteRecord.heading}?
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-rounded btn-info " onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            className="btn btn-rounded "
            onClick={() => handleDelete(deleteRecord)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <DndProvider backend={HTML5Backend}>
        <Table
          columns={columns}
          dataSource={data}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          scroll={{ x: "max-content" }}
        />
      </DndProvider>
    </React.Fragment>
  )
}

export default ManageSpaceOwner
