import React, { useEffect } from "react"
import { Col, Row, Input, Label } from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

function FloorModified({
  commercialData,
  setCommercialData,
  floorPlanFile2,
  setFloorPlanFile2,
  floorPlanDataURL2,
  setFloorPlanDataURL2,
}) {
  const { floor_modified_image } = commercialData

  let url = floor_modified_image.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setCommercialData(prevState => ({
        ...prevState,
        floor_modified_image: img,
      }))
    }
    const file = e.target.files[0]
    setFloorPlanFile2(file)
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (floorPlanFile2) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFloorPlanDataURL2(result)
        }
      }
      fileReader.readAsDataURL(floorPlanFile2)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [floorPlanFile2])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile4")
    temp.value = null
    setFloorPlanDataURL2(null)
    setCommercialData(prevState => ({
      ...prevState,
      floor_modified_image: [],
    }))
  }

  return (
    <Row>
      <Col className="col-12 mb-3">
        <Label>Modified Image</Label>
        <div className="row">
          <Col sm={6}>
            <div>
              <div className="img-container">
                {floor_modified_image !== undefined &&
                  Object.keys(floor_modified_image).length > 0 &&
                  url !== "mp4" && (
                    <div className="media-container">
                      <div className="media-main">
                        <img
                          className="media-img"
                          src={`${floor_modified_image.image_path}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${floor_modified_image.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt="Image"
                          loading="lazy"
                        />
                        <div>
                          <i
                            className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                            onClick={handleRemoveMedia}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                {floorPlanDataURL2 && floorPlanFile2?.type?.includes("image") && (
                  <div className="media-container">
                    <div className="media-main">
                      <img
                        className="media-img"
                        src={floorPlanDataURL2}
                        srcSet={floorPlanDataURL2}
                        alt="Banner"
                        loading="lazy"
                      />
                      <div>
                        <i
                          className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                          onClick={handleRemoveMedia}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Input
                name="file"
                accept="image/*"
                type="file"
                onChange={handleMedia}
                multiple
                id="formFile4"
              />
            </div>
          </Col>
        </div>
      </Col>
    </Row>
  )
}

export default FloorModified
