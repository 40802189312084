//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
// export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const LOGIN = "/auth/login/"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"
//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"
//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"
//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"
//starred mail
export const GET_STARRED_MAILS = "/starredmails"
//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"
//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"
//Send mail
export const GET_SENT_MAILS = "/sentmails"
//Trash mail
export const GET_TRASH_MAILS = "/trashmails"
//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"
//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"
//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"
//CART DATA
export const GET_CART_DATA = "/cart"
//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"
//SHOPS
export const GET_SHOPS = "/shops"
//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"
//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"
//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"
//TASKS
export const GET_TASKS = "/tasks"
//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"
//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"
export const TOP_SELLING_DATA = "/top-selling-data"
export const GET_EARNING_DATA = "/earning-charts-data"
export const GET_PRODUCT_COMMENTS = "/comments-product"
export const ON_LIKNE_COMMENT = "/comments-product-action"
export const ON_ADD_REPLY = "/comments-product-add-reply"
export const ON_ADD_COMMENT = "/comments-product-add-comment"

const URL = {
  // BASE_URL: "http://localhost:3001/",
  BASE_URL: process.env.REACT_APP_BASE_URL,
  LOGIN: "auth/login/",
  LOGOUT: "auth/logout",
  ALL_MENU: "menu/all",
  DELETE_MENU: "menu/delete-menu",
  ADD_MENU: "menu/add",
  ALL_ADMIN: "user/all",
  DELETE_ADMIN: "user/delete",
  CREATE_ADMIN: "user/add",
  UPDATE_ADMIN: "user/update",
  GET_ALL_ROLES: "role/all",
  MENU_BY_ROLE: "menu/by-role",
  UDPATE_MENU: "menu/update-menu",
  UPDATE_SUB_MENU: "menu/update-sub-menu",
  DELETE_SUB_MENU: "menu/delete-sub-menu",
  DELETE_ROLE: "role/delete",
  ADD_ROLE: "role/add",
  UPDATE_ROLE: "role/update",
  MANAGE_HOME: "home",
  UPDATE_MANAGE_HOME: "home/update",
  WHY_PARTNER_ALL: "why-partner/all",
  WHY_PARTNER_ADD: "why-partner/add",
  WHY_PARTNER_UPDATE: "why-partner/update",
  WHY_PARTNER_UPDATE_STATUS: "why-partner/update-status/",
  WHY_PARTNER_DELETE: "why-partner/delete",
  WHY_PARTNER_SORT_ORDER: "why-partner/update-sort-order",
  MANAGE_ABOUT: "about",
  UPDATE_MANAGE_ABOUT: "about/update",
  ALL_SPACE_OWNER_AND_CLIENT: "space-owner-and-client/all",
  ADD_SPACE_OWNER_AND_CLIENT: "space-owner-and-client/add",
  UPDATE_SPACE_OWNER_AND_CLIENT: "space-owner-and-client/update/",
  UPDATE_SPACE_OWNER_AND_CLIENT_STATUS: "space-owner-and-client/update-status/",
  DELETE_SPACE_OWNER_AND_CLIENT: "space-owner-and-client/delete/",
  UPDATE_SPACE_OWNER_AND_CLIENT_STATUS_SORT_ORDER:
    "space-owner-and-client/update-sort-order",
  ALL_MANAGE_TEAM: "team/all",
  ADD_MANAGE_TEAM: "team/add",
  UPDATE_MANAGE_TEAM: "team/update/",
  UPDATE_MANAGE_TEAM_STATUS: "team/update-status/",
  DELETE_MANAGE_TEAM: "team/delete/",
  MANAGE_TEAM_SORT_ORDER: "team/update-sort-order",
  ALL_CLIENTELE: "clientele/all",
  ADD_CLIENTELE: "clientele/add",
  UPDATE_CLIENTELE: "clientele/update/",
  UPDATE_CLIENTELE_STATUS: "clientele/update-status/",
  DELETE_CLIENTELE: "clientele/delete",
  ALL_TESTIMONIAL: "testimonial/all",
  ADD_TESTIMONIAL: "testimonial/add",
  UPDATE_TESTIMONIAL: "testimonial/update/",
  UPDATE_TESTIMONIAL_STATUS: "testimonial/update-status/",
  DELETE_TESTIMONIAL: "testimonial/delete",
  ALL_COMMERCIAL: "commercial",
  UPDATE_COMMERCIAL: "commercial/update",
  ALL_EXPERIENCE_CENTER: "exp-center",
  ALL_EXPERIENCE_CENTER_UPDATE: "exp-center/update",
  ALL_PROCESS: "process-step/all",
  ADD_PROCESS: "process-step/add",
  UPDATE_PROCESS: "process-step/update",
  UPDATE_PROCESS_STATUS: "process-step/update-status/",
  UPDATE_PROCESS_SORT_ORDER: "process-step/update-sort-order",
  DELETE_PROCESS: "process-step/delete",
  ALL_VENDOR: "vendor/all",
  ADD_VENDOR: "vendor/add",
  UPDATE_VENDOR: "vendor/update",
  UPDATE_VENDOR_STATUS: "vendor/update-status/",
  DELETE_VENDOR: "vendor/delete",
  ALL_VENDOR_PARENT: "vendor/parent/all",
  LOGIN_DATA: "vendor/login-data",
  ALL_PROPERTY_CATEGORY: "property/category/all",
  ADD_PROPERTY_CATEGORY: "property/category/add",
  UPDATE_PROPERTY_CATEGORY: "property/category/update",
  UPDATE_PROPERTY_CATEGORY_STATUS: "property/category/update-status",
  UPDATE_PROPERTY_CATEGORY_SORT_ORDER: "property/category/update-sort-order",
  DELETE_PROPERTY_CATEGORY: "/property/category/delete",
  ALL_PROPERTY_TYPE: "property/type/all",
  ADD_PROPERTY_TYPE: "property/type/add",
  UPDATE_PROPERTY_TYPE: "property/type/update",
  UPDATE_PROPERTY_TYPE_STATUS: "property/type/update-status",
  UPDATE_PROPERTY_TYPE_SORT_ORDER: "property/type/update-sort-order",
  DELETE_PROPERTY_TYPE: "property/type/delete",
  ALL_PROPERTY_FEATURE: "property/feature/all",
  ADD_PROPERTY_FEATURE: "property/feature/add",
  UPDATE_PROPERTY_FEATURES: "property/feature/update",
  UPDATE_PROPERTY_FEATURE_STATUS: "property/feature/update-status",
  UPDATE_PROPERTY_FEATURE_SORT_ORDER: "property/feature/update-sort-order",
  DELETE_PROPERTY_FEATURE: "property/feature/delete",
  ALL_VENDOR_MENU: "vendor/menu/all",
  UPDATE_VENDOR_MENU: "vendor/menu/update",
  UPDATE_VENDOR_MENU_STATUS: "vendor/menu/update-status",
  UPDATE_VENDOR_MENU_SORT_ORDER: "vendor/menu/update-sort-order",
  ALL_VENDOR_SUB_MENU: "vendor/submenu/all",
  UPDATE_VENDOR_SUB_MENU: "vendor/submenu/update",
  UPDATE_VENDOR_SUB_MENU_STATUS: "vendor/submenu/update-status",
  UPDATE_VENDOR_SUB_MENU_SORT_ORDER: "vendor/submenu/update-sort-order",
  ALL_VENDOR_SUB_SUB_MENU: "vendor/subsubmenu/all",
  ADD_VENDOR_SUB_SUB_MENU: "vendor/subsubmenu/add",
  UPDATE_VENDOR_SUB_SUB_MENU: "vendor/subsubmenu/update",
  UPDATE_VENDOR_SUB_SUB_MENU_STATUS: "vendor/subsubmenu/update-status",
  UPDATE_VENDOR_SUB_SUB_MENU_SORT_ORDER: "vendor/subsubmenu/update-sort-order",
  DELETE_VENDOR_SUB_SUB_MENU_STATUS: "vendor/subsubmenu/delete",
  ALL_AMENITIES: "amenitie/all",
  ADD_AMENITIES: "amenitie/add",
  UPDATE_AMENITIES: "amenitie/update",
  UPDATE_AMENITIES_STATUS: "amenitie/update-status",
  DELETE_AMENITIES: "amenitie/delete",
  ALL_KYC_TYPE: "kyc-type/all",
  ADD_KYC_TYPE: "kyc-type/add",
  UPDATE_KYC_TYPE: "kyc-type/update",
  UPDATE_KYC_TYPE_STATUS: "kyc-type/update-status",
  DELETE_KYC_TYPE: "kyc-type/delete",

  ALL_CALCULATOR_LAYOUT: "space/layout/all",
  ADD_CALCULATOR_LAYOUT: "space/layout/add",
  UPDATE_CALCULATOR_LAYOUT: "space/layout/update",
  UPDATE_CALCULATOR_LAYOUT_STATUS: "space/layout/update-status",
  UPDATE_CALCULATOR_LAYOUT_SORT_ORDER: "space/layout/update-sort-order",
  DELETE_CALCULATOUR_LAYOUT: "space/layout/delete",

  ALL_CALCULATOR_GROUP: "space/group/all",
  ADD_CALCULATOR_GROUP: "space/group/add",
  UPDATE_CALCULATOR_GROUP: "space/group/update",
  UPDATE_CALCULATOR_GROUP_STATUS: "space/group/update-status",
  UPDATE_CALCULATOR_GROUP_SORT_ORDER: "space/group/update-sort-order",
  DELETE_CALCULATOR_GROUP: "space/group/delete",
  ALL_CALCULATOR_GROUP_ITEM: "space/item/all",
  ADD_CALCULATOR_GROUP_ITEM: "space/item/add",
  UPDATE_CALCULATOR_GROUP_ITEM: "space/item/update",
  UPDATE_CALCULATOR_GROUP_ITEM_STATUS: "space/item/update-status",
  UPDATE_CALCULATOR_GROUP_ITEM_SORT_ORDER: "space/item/update-sort-order",
  DELETE_CALCULATOR_GROUP_ITEM: "space/item/delete",
  ALL_COST_AND_CIRCULATION: "space/cost-and-circulation",
  UPDATE_COST_AND_CIRCULATION: "space/cost-and-circulation/update",
  ALL_PROPERTY: "property/all",
  ADD_PROPERTY: "property/add",
  UPDATE_PROPERTY: "property/update",
  UPDATE_PROPERTY_STATUS: "property/update-status",
  UPDATE_PROPERTY_FEATURE: "property/update-feature",
  UPDATE_PROPERTY_VISIBILITY: "property/update-visibility",
  DELETE_PROPERTY: "property/delete",
  ALL_PROPERTY_INFO: "property/info-and-map/all",
  ADD_PROPERTY_INFO: "property/info-and-map/add",
  UPDATE_PROPERTY_INFO: "property/info-and-map/update",
  UPDATE_PROPERTY_INFO_STATUS: "property/info-and-map/update-status",
  DELETE_PROPERTY_INFO: "property/info-and-map/delete",
  ALL_FLOOR_LAYOUT: "property/floor-layout/all",
  ADD_FLOOR_LAYOUT: "property/floor-layout/add",
  UPDATE_FLOOR_LAYOUT: "property/floor-layout/update",
  UPDATE_FLOOR_LAYOUT_STATUS: "property/floor-layout/update-status",
  DELETE_FLOOR_LAYOUT: "property/floor-layout/delete",
  ALL_ACTUAL_SITE_DATA: "/property/actual-site/all",
  ALL_ACTUAL_SITE_DATA_MENU: "property/actual-site/sub-menu/all",
  ADD_ACTUAL_SITE_DATA: "property/actual-site/add",
  UPDATE_ACTUAL_SITE_DATA: "property/actual-site/update",
  UPDATE_ACTUAL_SITE_DATA_STATUS: "/property/actual-site/update-status",
  DELETE_ACTUAL_SITE_DATA: "property/actual-site/delete",
  DELETE_ACTUAL_SITE_DATA_CHILD: "property/actual-site/delete-child",
  ALL_PROPERTY_TOUR: "property/3d-tour/all",
  ALL_PROPERTY_TOUR_SUB_MENU: "property/3d-tour/sub-sub-menu/all",
  ADD_PROPERTY_TOUR: "property/3d-tour/add",
  UPDATE_PROPERTY_TOUR: "property/3d-tour/update",
  UPDATE_PROPERTY_TOUR_STATUS: "property/3d-tour/update-status",
  DELETE_PROPERTY_TOUR: "property/3d-tour/delete",
  DELETE_PROPRTY_TOUR_CHILD: "property/3d-tour/delete-child",
  ALL_RENDER_PROPERTY: "property/3d-render/all",
  ALL_RENDER_SUB_SUB_MENU: "property/3d-render/sub-sub-menu/all",
  ADD_RENDER_PROPERTY: "property/3d-render/add",
  UPDATE_RENDER_PROPERTY: "property/3d-render/update",
  UPDATE_RENDER_PROPERTY_STATUS: "property/3d-render/update-status",
  DELETE_RENDER_PROPERTY: "property/3d-render/delete",
  DELETE_RENDER_PROPERY_CHILD: "property/3d-render/delete-child",
  ALL_TOUR_PROPERTY: "property/3d-tour/all",
  ALL_TOUR_PROPERTY_SUB_MENU: "/property/3d-tour/sub-sub-menu/all",
  ADD_TOUR_PROPERTY: "property/3d-tour/add",
  UPDATE_TOUR_PROPERTY: "property/3d-tour/update",
  UPDATE_TOUR_PROPERTY_STATUS: "property/3d-tour/update-status",
  DELETE_TOUR_PROPERTY: "property/3d-tour/delete",
  DELETE_TOUR_PROPERTY_CHILD: "property/3d-tour/delete-child",
  ADD_PROPERTY_VIEW_DATA_COUNTER: "api/addCounter",
  ALL_PROPERTY_VIEW_DATA_VIEWS: "property/views",
  ALL_MANAGE_CONTACT: "contact/detail",
  UPDATE_MANAGE_CONTACT: "contact/detail/update",
  ALL_SOCIAL_LINK: "social-link/all",
  ADD_SOCIAL_LINK: "social-link/add",
  UPDATE_SOCIAL_LINK: "social-link/update",
  UPDATE_SOCIAL_LINK_STATUS: "social-link/update-status",
  UPDATE_SOCIAL_LINK_SORT_ORDER: "social-link/update-sort-order",
  DELETE_SOCIAL_LINK: "social-link/delete",
  ALL_GENERAL_SITE_SETTING: "general-site-setting",
  UPDATE_GENERAL_SITE_SETTING: "general-site-setting/update",
  ALL_MANAGE_MENU: "site-menu/all",
  UPDATE_MANAGE_MENU: "site-menu/update",
  UPDATE_MANAGE_MENU_STATUS: "site-menu/update-status",
  UPDATE_MANAGE_MENU_PRODUCT_STATUS: "site-menu/update-product",
  UPDATE_MANAGE_MENU_QUICK_LINK_STATUS: "site-menu/update-quicklink",
  DASHBOARD_ANALYTICS: "dashboard/analytics",
  AGREEMENT_ANALYTICS: "dashboard/agreement-analytics",
  ALL_SITE_BUILD: "site-build/all",
  TRIGGER_BUILD: "site-build/trigger",

  GET_VENDOR_MONTHLY_LOGIN_ANALYTICS: "/vendor/monthly-login-analytics",
  GET_MONTHLY_VENDORS_CREATED: "/vendor/monthly-created-vendors",
  GET_HOT_PROPERTY: "/property/monthly-hot-property",
  GET_FOOTFALL_BY_MONTH: "/property/property-footfall-by-month",
  GET_MONTYLY_CREATED_PROPERTY: "/property/monthly-created-property",

  GET_YEARLY_KIOSK_USAGE: "vendor/yearly-kiosk-usage",
  GET_MONTHLY_KIOSK_USAGE: "vendor/monthly-kiosk-usage",

  GET_BOQ_LAYOUT: "/boq/get-layouts",
  UPLOAD_BOQ_LAYOUT: "/boq/add-layouts",

  FLOOR_LAYOUT_SUB_SUB_MENU: "/property/floor-layout/sub-sub-menu/all",
}

export default URL
