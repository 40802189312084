import React from "react"

import { Col, Card, CardBody, CardTitle } from "reactstrap"
import AverageFootfallChart from "./Charts/AverageFootfallChart"

const AverageFootfall = ({ footfall }) => {
  let avg_view = footfall?.map(currElem => currElem?.avg_view).reverse()
  let labelsData = footfall
    ?.map(currElem => currElem?.month + " " + currElem?.year)
    .reverse()

  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Average Footfall</CardTitle>
            <AverageFootfallChart labelsData={labelsData} avg_view={avg_view} />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default AverageFootfall
