import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import FloorLayoutImage from "./FloorLayoutImage"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { toast } from "react-toastify"

function FloorLayout() {
  const { state } = useLocation()
  document.title = state ? "Edit Floor Layout" : "Add Floor Layout"
  const history = useHistory()
  const [floorLayout, setFloorLayout] = useState({
    property_id: state ? state?.state?.property?.id : "",
    vendor_id: state ? state?.state?.vendor?.id : "",
    sub_sub_menu_id: state ? state?.state?.sub_sub_menu?.id : "",
    title: state ? state.state.title : "",
    image: state ? state.state.image : "",
  })

  const { property_id, vendor_id, sub_sub_menu_id, title, image } = floorLayout

  const [vendorList, setVendorList] = useState([])
  const [vendorDropdown, setVendorDropdown] = useState("")

  const [menuList, setMenuList] = useState([])
  const [menuDropdown, setMenuDropdown] = useState("")

  const [propertyList, setPropertyList] = useState([])
  const [propertyDropdown, setPropertyDropdown] = useState("")

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)

  const [isDisabled, setIsDisabled] = useState(true)

  const getVendorParent = async () => {
    let vendorOptions = []
    try {
      const res = await axios.get(URL.ALL_VENDOR)
      res.data.vendors.map(item => {
        let name = `${item.fname + " " + item.lname}`
        let id = item.id
        let property = item.Property
        let obj = {
          id,
          name,
          property,
        }
        vendorOptions.push(obj)
      })
      setVendorList(vendorOptions)
    } catch (error) {
      console.log(error)
    }
  }

  const getMenu = async () => {
    try {
      const res = await axios.get(URL.FLOOR_LAYOUT_SUB_SUB_MENU)
      if (res.data.success) {
        setMenuList(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMenu()
  }, [])

  useEffect(() => {
    getVendorParent()
  }, [])

  useEffect(() => {
    let temp = vendorList.find(el => el.id === vendor_id)
    setVendorDropdown(temp)
    setPropertyList(temp?.property)
  }, [vendor_id, vendorList?.length])

  useEffect(() => {
    let temp = propertyList?.find(el => el?.id === property_id)
    setPropertyDropdown(temp)
  }, [property_id, propertyList?.length])

  useEffect(() => {
    let temp = menuList?.find(el => el?.id === sub_sub_menu_id)
    setMenuDropdown(temp)
  }, [sub_sub_menu_id, menuList?.length])

  const handleChange = e => {
    setFloorLayout(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSave = async () => {
    let formData = new FormData()
    formData.append("property_id", parseInt(propertyDropdown?.id))
    formData.append("vendor_id", parseInt(vendorDropdown?.id))
    formData.append("sub_sub_menu_id", parseInt(menuDropdown?.id))
    formData.append("title", title)
    formData.append("image", image)

    try {
      const res = await axios.post(URL.ADD_FLOOR_LAYOUT, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/floor-layout")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleUpdate = async () => {
    let formData = new FormData()
    formData.append("property_id", parseInt(propertyDropdown?.id))
    formData.append("vendor_id", parseInt(vendorDropdown?.id))
    formData.append("sub_sub_menu_id", parseInt(menuDropdown?.id))
    formData.append("title", title)
    formData.append("image", image)

    try {
      const res = await axios.put(
        URL.UPDATE_FLOOR_LAYOUT + `/${state.state.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/floor-layout")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    if (
      propertyDropdown !== undefined &&
      vendorDropdown !== undefined &&
      menuDropdown !== undefined &&
      title?.length > 0 &&
      image !== ""
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [propertyDropdown, vendorDropdown, menuDropdown, title, image])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-between align-items-center"
            >
              <CardTitle className="h4 card-title font-size-22">
                {state ? "Edit Floor Layout" : "Add Floor Layout"}
              </CardTitle>
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  className={
                    isDisabled
                      ? "btn btn-rounded w-sm mb-4"
                      : "btn btn-rounded w-sm mb-4 btn-info"
                  }
                  onClick={state ? handleUpdate : handleSave}
                >
                  Save
                </Button>

                <Link to="/floor-layout">
                  <Button
                    color="secondary"
                    className="btn btn-rounded w-sm mb-4"
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-3">Add Floor Layout</CardTitle>
                  <div className="mb-3">
                    <Label>Vendor</Label>
                    <Select
                      value={vendorDropdown}
                      options={vendorList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Vendor"
                      onChange={(event, val) => {
                        setVendorDropdown(event)
                        setPropertyList(event?.property)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Property</Label>
                    <Select
                      value={propertyDropdown}
                      options={propertyList}
                      getOptionLabel={option => `${option.property_id}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Property"
                      onChange={(event, val) => {
                        setPropertyDropdown(event)
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="formrow-heading-Input">Type</Label>
                    <Select
                      value={menuDropdown}
                      options={menuList}
                      getOptionLabel={option => `${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      placeholder="Select Type"
                      onChange={event => {
                        setMenuDropdown(event)
                      }}
                    />
                  </div>
                  <FloorLayoutImage
                    image={image}
                    firstFile={firstFile}
                    setFirstFile={setFirstFile}
                    firstDataURL={firstDataURL}
                    setFirstDataURL={setFirstDataURL}
                    setFloorLayout={setFloorLayout}
                  />
                  <div className="mb-3">
                    <Label>Title</Label>
                    <Input
                      type="text"
                      name="title"
                      value={title}
                      className="form-control"
                      placeholder="Title"
                      onChange={handleChange}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="d-flex flex-wrap gap-2 float-end">
                <Button
                  disabled={isDisabled}
                  className={
                    isDisabled
                      ? "btn btn-rounded w-sm mb-4"
                      : "btn btn-rounded w-sm mb-4 btn-info"
                  }
                  onClick={state ? handleUpdate : handleSave}
                >
                  Save
                </Button>

                <Link to="/floor-layout">
                  <Button
                    color="secondary"
                    className="btn btn-rounded w-sm mb-4"
                  >
                    Back
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FloorLayout
