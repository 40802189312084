import React, { useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import TestimonialLogo from "./TestimonialLogo"
import TestimonialImage from "./TestimonialImage"
import {
  Card,
  Input,
  Container,
  Button,
  Col,
  Row,
  CardBody,
  Label,
} from "reactstrap"

function Testimonial() {
  document.title = "Add Testimonial"
  const history = useHistory()
  const { state } = useLocation()

  const [testimonial, setTestimonial] = useState({
    name: state ? state?.state?.name : "",
    designation: state ? state?.state?.designation : "",
    description: state ? state?.state?.description : "",
    logo: state ? state?.state?.logo : "",
    image: state ? state?.state?.image : "",
  })

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)
  const [secondFile, setSecondFile] = useState(null)
  const [secondDataURL, setSecondDataURL] = useState(null)

  const { name, designation, description, logo, image } = testimonial

  const onChange = e => {
    setTestimonial(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleDescription = e => {
    setTestimonial(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addTestimonial = async () => {
    let formData = new FormData()
    Object.keys(testimonial).forEach(fieldName => {
      if (testimonial[fieldName]) {
        formData.append(fieldName, testimonial[fieldName])
      }
    })
    try {
      const res = await axios.post(URL.ADD_TESTIMONIAL, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/testimonial")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateTestimonial = async () => {
    let formData = new FormData()
    Object.keys(testimonial).forEach(fieldName => {
      if (testimonial[fieldName]) {
        formData.append(fieldName, testimonial[fieldName])
      }
    })
    try {
      const res = await axios.put(
        URL.UPDATE_TESTIMONIAL + `${state?.state?.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/testimonial")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="py-4">
              <Col
                lg={12}
                className="d-flex justify-content-between align-items-center"
              >
                <p className="h4 card-title font-size-22">
                  {state ? "Edit Testimonial" : "Add Testimonial"}
                </p>

                <div className="d-flex flex-wrap gap-2 float-end">
                  <Button
                    onClick={state ? updateTestimonial : addTestimonial}
                    className="btn btn-info btn-rounded float-end w-sm"
                  >
                    Save
                  </Button>

                  <Button
                    color="secondary"
                    className="btn btn-rounded float-end w-sm"
                    onClick={handleGoBack}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </div>
          </Row>

          <Card>
            <CardBody>
              <TestimonialLogo
                logo={logo}
                firstFile={firstFile}
                setFirstFile={setFirstFile}
                firstDataURL={firstDataURL}
                setFirstDataURL={setFirstDataURL}
                setTestimonial={setTestimonial}
              />

              <div className="mb-3">
                <Label>Name</Label>
                <Input
                  name="name"
                  value={name}
                  onChange={onChange}
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                />
              </div>

              <div className="mb-3">
                <Label>Designation</Label>
                <Input
                  name="designation"
                  value={designation}
                  onChange={onChange}
                  id="outlined-basic"
                  label="Designation"
                  variant="outlined"
                />
              </div>

              <div className="mb-3">
                <Label>Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleDescription}
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                />
              </div>

              <TestimonialImage
                image={image}
                secondFile={secondFile}
                setSecondFile={setSecondFile}
                secondDataURL={secondDataURL}
                setSecondDataURL={setSecondDataURL}
                setTestimonial={setTestimonial}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Testimonial
