import React, { useEffect } from "react"
import { Container, Label, Input } from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function TestimonialLogo({
  logo,
  firstFile,
  setFirstFile,
  firstDataURL,
  setFirstDataURL,
  setTestimonial,
}) {
  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL(result)
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleLogo = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setTestimonial(prevState => ({
        ...prevState,
        logo: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setFirstFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#logo")
    temp.value = null
    setFirstDataURL(null)
    setTestimonial(prevState => ({
      ...prevState,
      logo: [],
    }))
  }
  return (
    <React.Fragment>
      <Container fluid={true}>
        <div className="mb-3">
          <Label>Logo</Label>
          <div className="img-container p-2">
            {Object.keys(logo).length > 0 && (
              <div className="media-container">
                <div className="media-main">
                  <img
                    className="media-img"
                    src={`${logo.image_path}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${logo.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt="Image"
                    loading="lazy"
                  />
                  <i
                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                    onClick={handleRemoveMedia}
                  />
                </div>
              </div>
            )}

            {firstDataURL && (
              <div className="media-container">
                <div className="media-main">
                  <img
                    className="media-img"
                    src={firstDataURL}
                    srcSet={firstDataURL}
                    alt="Banner"
                    loading="lazy"
                  />
                  <i
                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                    onClick={handleRemoveMedia}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          <Input
            id="logo"
            type="file"
            name="logo"
            placeholder="Choose image"
            accept="image/*"
            onChange={handleLogo}
          />
        </div>
      </Container>
    </React.Fragment>
  )
}

export default TestimonialLogo
