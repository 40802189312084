import React, { useEffect } from "react"
import { Card, CardBody, Col, Row, Label, Input } from "reactstrap"

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm

function BannerSection({
  images,
  setImages,
  imageFiles,
  setImageFiles,
  manageHomeData,
  setManageHomeData,
}) {
  const {
    banner_heading,
    banner_subheading,
    first_button_name,
    first_button_link,
    second_button_name,
    second_button_link,
    removed_banner_ids,
    home_banner,
  } = manageHomeData

  const handleChange = e => {
    setManageHomeData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleMedia = e => {
    const { files } = e.target
    const validImageFiles = []
    if (e.target.files) {
      let img = e.target.files
      setManageHomeData(prevState => ({
        ...prevState,
        home_banner: [...img, ...home_banner],
      }))
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file)
      }
    }
    if (validImageFiles.length) {
      setImageFiles([...imageFiles, ...validImageFiles])
      return
    }
    alert("Selected images are not of valid type!")
  }

  const handleRemoveMedia = item => {
    let cmp = document.querySelector("#formFile1")
    cmp.value = null
    let temp = home_banner
    temp = temp.filter(el => el.id !== item.id)
    setManageHomeData(prevState => ({
      ...prevState,
      home_banner: temp,
      removed_banner_ids: [...removed_banner_ids, item.id],
    }))
  }

  const handleRemove = (item, index) => {
    let val = document.querySelector("#formFile1")
    val.value = null
    let fileToRemove = imageFiles[index]
    const temp = images.filter((el, idx) => idx !== index)

    let temp_ = home_banner.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })
    let temp__ = imageFiles.filter(el => {
      if (el?.name !== fileToRemove.name) {
        return el
      }
    })
    setManageHomeData(prevState => ({
      ...prevState,
      home_banner: [...temp_],
    }))
    setImages([...temp])
    setImageFiles([...temp__])
  }

  console.log("home", home_banner)

  useEffect(() => {
    const fileReaders = []
    let isCancel = false
    if (imageFiles.length) {
      const promises = imageFiles.map(file => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReaders.push(fileReader)
          fileReader.onload = e => {
            const { result } = e.target
            if (result) {
              resolve(result)
            }
          }
          fileReader.onabort = () => {
            reject(new Error("File reading aborted"))
          }
          fileReader.onerror = () => {
            reject(new Error("Failed to read file"))
          }
          fileReader.readAsDataURL(file)
        })
      })
      Promise.all(promises)
        .then(images => {
          if (!isCancel) {
            setImages([...images])
          }
        })
        .catch(reason => {
          console.log(reason)
        })
    }
    return () => {
      isCancel = true
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles])

  return (
    <Row>
      <Col sm={12}>
        <div className="h4 py-3 card-title font-size-18">Banner Section</div>
        <Card>
          <CardBody>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="banner_heading"
                onChange={handleChange}
                value={banner_heading || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <div className="mb-3">
              <Label htmlFor="formrow-subheading-Input">Sub Heading</Label>
              <Input
                type="text"
                name="banner_subheading"
                onChange={handleChange}
                value={banner_subheading || ""}
                className="form-control"
                id="formrow-subheading-Input"
                placeholder="Enter Sub Heading"
              />
            </div>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstButton-Input">
                    First Button
                  </Label>
                  <Input
                    type="text"
                    name="first_button_name"
                    className="form-control"
                    id="formrow-firstButton-Input"
                    placeholder="Enter First Button"
                    onChange={handleChange}
                    value={first_button_name || ""}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstButtonLink-Input">
                    First Button Link
                  </Label>
                  <Input
                    type="text"
                    name="first_button_link"
                    className="form-control"
                    id="formrow-firstButtonLink-Input"
                    placeholder="Enter First Button Link"
                    onChange={handleChange}
                    value={first_button_link || ""}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-secondButton-Input">
                    Second Button
                  </Label>
                  <Input
                    type="text"
                    name="second_button_name"
                    className="form-control"
                    id="formrow-secondButton-Input"
                    placeholder="Enter Second Button"
                    onChange={handleChange}
                    value={second_button_name || ""}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="formrow-secondButtonLink-Input">
                    Second Button Link
                  </Label>
                  <Input
                    type="text"
                    name="second_button_link"
                    className="form-control"
                    id="formrow-secondButtonLink-Input"
                    placeholder="Enter Second Button Link"
                    onChange={handleChange}
                    value={second_button_link || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Label>Banner</Label>
                <div className="row">
                  <Col sm={12}>
                    <div>
                      <div
                        className={
                          home_banner !== undefined && home_banner.length > 0
                            ? `img-container mb-5`
                            : `img-container `
                        }
                      >
                        {home_banner !== undefined &&
                          home_banner.length > 0 &&
                          home_banner?.map(item => {
                            return (
                              item.image_name && (
                                <div key={item.id} className="media-container">
                                  <div className="media-main">
                                    <img
                                      className="media-img"
                                      src={`${item.image_path}?w=164&h=164&fit=crop&auto=format`}
                                      srcSet={`${item.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                      alt="Banner"
                                      loading="lazy"
                                    />
                                    <i
                                      className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                      onClick={() => {
                                        handleRemoveMedia(item)
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                            )
                          })}

                        {images.length > 0 &&
                          images?.map((image, index) => {
                            return (
                              <div key={index} className="media-container">
                                <div className="media-main">
                                  <img
                                    className="media-img"
                                    src={image}
                                    srcSet={image}
                                    alt="Banner"
                                    loading="lazy"
                                  />
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={() => handleRemove(image, index)}
                                  />
                                </div>
                              </div>
                            )
                          })}
                      </div>
                      <Col sm={4}>
                        <Input
                          name="file"
                          accept="image/*"
                          type="file"
                          onChange={handleMedia}
                          multiple
                          id="formFile1"
                        />
                        <Label className="font-size-12 text-gray">
                          (You Can Select Multiple Images)
                        </Label>
                      </Col>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BannerSection
