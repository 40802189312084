import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

ClassicEditor.defaultConfig = {
  toolbar: {
    items: ["heading", "|", "bold", "italic", "|", "undo", "redo"],
  },
  language: "en",
}

function ExperienceFourthSection({
  experienceCenterData,
  setExperienceCenterData,
  fourthFile,
  setFourthFile,
  fourthDataURL,
  setFourthDataURL,
}) {
  const { fourth_section_heading, fourth_section_desc, fourth_section_file } =
    experienceCenterData

  const handleChange = e => {
    setExperienceCenterData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let url = fourth_section_file.image_path
  url = url?.substring(url.lastIndexOf(".") + 1)

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setExperienceCenterData(prevState => ({
        ...prevState,
        fourth_section_file: img,
      }))
    }
    const file = e.target.files[0]
    setFourthFile(file)
  }

  useEffect(() => {
    console.log("fourth section useEffect")
    let fileReader,
      isCancel = false
    if (fourthFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFourthDataURL(result)
        }
      }
      fileReader.readAsDataURL(fourthFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [fourthFile])

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#formFile4")
    temp.value = null
    setFourthDataURL(null)
    setExperienceCenterData(prevState => ({
      ...prevState,
      fourth_section_file: [],
    }))
  }

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-3">Fourth Section</CardTitle>
            <div className="mb-3">
              <Label htmlFor="formrow-heading-Input">Heading</Label>
              <Input
                type="text"
                name="fourth_section_heading"
                onChange={handleChange}
                value={fourth_section_heading || ""}
                className="form-control"
                id="formrow-heading-Input"
                placeholder="Enter Heading"
              />
            </div>
            <Row>
              <Col>
                <Label htmlFor="formrow-heading-Input">Description</Label>
                <Form method="post">
                  <CKEditor
                    editor={ClassicEditor}
                    data={fourth_section_desc}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setExperienceCenterData(prevState => ({
                        ...prevState,
                        fourth_section_desc: data,
                      }))
                    }}
                  />
                </Form>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Label className="form-label mt-4">Upload Image</Label>
                <div className="row">
                  <Col sm={12}>
                    <div className="mt-3">
                      <div className="img-container p-2">
                        {Object.keys(fourth_section_file).length > 0 &&
                          url !== "mp4" && (
                            <div className="media-container">
                              <div className="media-main">
                                <img
                                  className="media-img"
                                  src={`${fourth_section_file.image_path}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${fourth_section_file.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  alt="Image"
                                  loading="lazy"
                                />
                                <div>
                                  <i
                                    className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                    onClick={handleRemoveMedia}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                        {fourthDataURL && fourthFile?.type?.includes("image") && (
                          <div className="media-container">
                            <div className="media-main">
                              <img
                                className="media-img"
                                src={fourthDataURL}
                                srcSet={fourthDataURL}
                                alt="Banner"
                                loading="lazy"
                              />
                              <div>
                                <i
                                  className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                                  onClick={handleRemoveMedia}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <Col sm={4} className="mt-5">
                        <Input
                          name="file"
                          accept="image/*"
                          type="file"
                          onChange={handleMedia}
                          multiple
                          id="formFile4"
                        />
                      </Col>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ExperienceFourthSection
