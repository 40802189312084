import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Dashboard from "../pages/Dashboard/Dashboard"
import ManageHome from "../pages/Dashboard/ManageHome"
import GeneralSiteSetting from "../components/GeneralSiteSetting/GeneralSiteSetting"
import ManageAboutImage from "components/About Management/ManageAboutImage"
import ManageSOCList from "components/About Management/ManageSOCList"
import ManageSOC from "components/About Management/ManageSOC"
import ManageTeamList from "components/About Management/ManageTeamList"
import ManageTeam from "components/About Management/ManageTeam"

import Clientele from "../components/Partner/Clientele"
import ClienteleList from "../components/Partner/ClienteleList"
import Testimonial from "components/Partner/Testimonial"
import TestimonialList from "components/Partner/TestimonialList"

import Commercial from "pages/Dashboard/CommercialSection"
import ExperienceCenter from "pages/Dashboard/ExperienceCenter"
import AddNewUser from "components/User Management/AddNewUser"
import UserList from "components/User Management/UserList"
import ManageRole from "components/User Management/ManageRole"
import MenuList from "components/User Management/MenuList"
import AddRole from "components/User Management/AddRole"
import AddAdminMenu from "components/User Management/AddAdminMenu"
import ProcessList from "components/Commercial/OurProcess/ProcessList"
import ManageProcess from "components/Commercial/OurProcess/ManageProcess"
import VendorList from "components/Vendor Management/VendorList"
import Vendor from "components/Vendor Management/Vendor"
import LoginDataList from "components/Vendor Management/LoginDataList"
import PropertCategoryList from "components/PropertyParameters/PropertyCategoryList"
import PropertyCategory from "components/PropertyParameters/PropertyCategory"
import PropertyFeatures from "components/PropertyParameters/PropertyFeatures"
import PropertyFeaturesList from "components/PropertyParameters/PropertyFeaturesList"
import PropertyType from "components/PropertyParameters/PropertyType"
import PropertyTypeList from "components/PropertyParameters/PropertyTypeList"
import VendorMenu from "components/VendorMenu/VendorMenu"
import VendorMenuList from "components/VendorMenu/VendorMenuList"
import VendorSubMenu from "components/VendorMenu/VendorSubMenu"
import VendorSubMenuList from "components/VendorMenu/VendorSubMenuList"
import VendorSubSubMenu from "components/VendorMenu/VendorSubSubMenu"
import VendorSubSubMenuList from "components/VendorMenu/VendorSubSubMenuList"

import Amenities from "components/Agreement/Amenities"
import AmenitiesList from "components/Agreement/AmenitiesList"
import KYCList from "components/Agreement/KYCList"
import KYCType from "components/Agreement/KYCType"

import CalculatorLayout from "components/CalculatorManagement/CalculatorLayout"
import CalculatorLayoutList from "components/CalculatorManagement/CalculatorLayoutList"
import CalculatorGroup from "components/CalculatorManagement/CalculatorGroup"
import CalculatorGroupList from "components/CalculatorManagement/CalculatorGroupList"
import CalculatorItem from "components/CalculatorManagement/CalculatorItem"
import CalculatorItemList from "components/CalculatorManagement/CalculatorItemList"
import CostCirculationDetails from "components/CalculatorManagement/CostCirculationDetails"

import AllPropertyList from "components/PropertyManagement/AllPropertyList"
import AllProperty from "components/PropertyManagement/Pages/AllProperty"
import InfoMapList from "components/PropertyManagement/InfoMapList"
import ActualSiteDataList from "components/PropertyManagement/ActualSiteDataList"
import FloorLayoutList from "components/PropertyManagement/FloorLayoutList"
import RenderList from "components/PropertyManagement/3DRenderList"
import TourList from "components/PropertyManagement/3DTourList"
import PropertyViewsDataList from "components/PropertyManagement/PropertyViewsDataList"
import InfoMap from "components/PropertyManagement/Pages/InfoMap"
import ActualSiteData from "components/PropertyManagement/Pages/ActualSiteData"
import FloorLayout from "components/PropertyManagement/Pages/FloorLayout"
import Render from "components/PropertyManagement/Pages/3DRender"
import Tour from "components/PropertyManagement/Pages/3DTour"
import ManageContact from "components/ContactManagement/ManageContact"
import ManageSocialLinks from "components/ContactManagement/ManageSocialLinks"
import AddSocialLinks from "components/ContactManagement/AddSocialLinks"
import WhyPartnerList from "components/ManageHome/WhyPartnerList"
import WhyPartners from "components/ManageHome/WhyPartner"
import ManageMenuList from "components/ManageMenu/ManageMenuList"
import ManageMenu from "components/ManageMenu/ManageMenu"
import CreateBuild from "components/CreateBuild/CreateBuild"
import EditAdminMenu from "components/User Management/EditAdminMenu"
import DataMetrics from "pages/Dashboard/DataMetrics"

import BoqCalculator from "components/Boq-calculator/BoqCalculator"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: "/profile", component: UserProfile },
  { path: "/home-page", component: ManageHome },
  { path: "/general-site", component: GeneralSiteSetting },
  { path: "/manage-about", component: ManageAboutImage },
  { path: "/all-space-owner-and-client", component: ManageSOCList },
  { path: "/add-space-owner-and-client", component: ManageSOC },
  { path: "/all-team", component: ManageTeamList },
  { path: "/add-team", component: ManageTeam },
  { path: "/clientele", component: ClienteleList },
  { path: "/add-clientele", component: Clientele },
  { path: "/add-testimonial", component: Testimonial },
  { path: "/testimonial", component: TestimonialList },
  { path: "/commercial", component: Commercial },
  { path: "/experience-center", component: ExperienceCenter },
  { path: "/add-admin-user", component: AddNewUser },
  { path: "/all-admin-user", component: UserList },
  { path: "/all-role-management", component: ManageRole },
  { path: "/all-admin-menu", component: MenuList },
  { path: "/add-role-management", component: AddRole },
  { path: "/add-admin-menu", component: AddAdminMenu },
  { path: "/edit-admin-menu", component: EditAdminMenu },
  { path: "/our-process-steps", component: ProcessList },
  { path: "/add-our-process", component: ManageProcess },
  { path: "/vendors", component: VendorList },
  { path: "/add-vendor", component: Vendor },
  { path: "/vendors-login", component: LoginDataList },
  { path: "/property-category", component: PropertCategoryList },
  { path: "/add-property-category", component: PropertyCategory },
  { path: "/add-property-feature", component: PropertyFeatures },
  { path: "/property-feature", component: PropertyFeaturesList },
  { path: "/add-property-type", component: PropertyType },
  { path: "/property-type", component: PropertyTypeList },
  { path: "/add-vendor-menu", component: VendorMenu },
  { path: "/vendor-menu", component: VendorMenuList },
  { path: "/add-vendor-sub-menu", component: VendorSubMenu },
  { path: "/vendor-sub-menu", component: VendorSubMenuList },
  { path: "/add-vendor-sub-sub-menu", component: VendorSubSubMenu },
  { path: "/vendor-sub-sub-menu", component: VendorSubSubMenuList },
  { path: "/add-amenities", component: Amenities },
  { path: "/amenities", component: AmenitiesList },
  { path: "/kyc-type", component: KYCList },
  { path: "/add-kyc-type", component: KYCType },
  { path: "/all-calculator-layout", component: CalculatorLayoutList },
  { path: "/add-calculator-layout", component: CalculatorLayout },
  { path: "/add-calculator-group", component: CalculatorGroup },
  { path: "/all-calculator-group", component: CalculatorGroupList },
  { path: "/add-calculator-group-item", component: CalculatorItem },
  { path: "/all-calculator-group-item", component: CalculatorItemList },
  { path: "/add-circulation-details", component: CostCirculationDetails },
  { path: "/property", component: AllPropertyList },
  { path: "/info-and-map", component: InfoMapList },
  { path: "/add-info-map", component: InfoMap },
  { path: "/actual-site-data", component: ActualSiteDataList },
  { path: "/add-actual-site-data", component: ActualSiteData },
  { path: "/floor-layout", component: FloorLayoutList },
  { path: "/add-floor-layout", component: FloorLayout },
  { path: "/3d-render", component: RenderList },
  { path: "/add-3d-render", component: Render },
  { path: "/3d-tour", component: TourList },
  { path: "/add-property", component: AllProperty },
  { path: "/add-3d-tour", component: Tour },
  { path: "/property-views", component: PropertyViewsDataList },
  { path: "/add-contact", component: ManageContact },
  { path: "/all-social-links", component: ManageSocialLinks },
  { path: "/add-social-links", component: AddSocialLinks },
  { path: "/add-why-partner", component: WhyPartners },
  { path: "/why-partner", component: WhyPartnerList },
  { path: "/all-menu", component: ManageMenuList },
  { path: "/add-menu", component: ManageMenu },
  { path: "/create-build", component: CreateBuild },
  { path: "/data-metrics", component: DataMetrics },
  { path: "/boq-calculator", component: BoqCalculator },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { publicRoutes, authProtectedRoutes }
