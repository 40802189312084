import React, { useState, useEffect } from "react"
import { Col, Input, Row } from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import "flatpickr/dist/themes/material_blue.css"
import moment from "moment/moment"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function AverageKIOSKTable() {
  const [search, setSearch] = useState("")
  const [yearlyKiosk, setYearlyKiosk] = useState([])
  const [filteredYearlyKiosk, setFilteredYearlyKiosk] = useState([])

  const getYearlyKioskUsage = async () => {
    try {
      const res = await axios.get(URL.GET_YEARLY_KIOSK_USAGE)
      if (res.data.success) {
        setYearlyKiosk(res.data.data)
        setFilteredYearlyKiosk(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    getYearlyKioskUsage()
  }, [])

  const columns = [
    {
      name: "Month",
      selector: row => row.month,
      sortable: true,
    },
    {
      name: "Year",
      selector: row => row.year,
      sortable: true,
    },
    {
      name: "Duration",
      selector: row => row.duration + " " + 'Hours',
      sortable: true,
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = yearlyKiosk?.filter(item => {
      return item.month.toLowerCase().match(search.toLowerCase())
    })

    setFilteredYearlyKiosk(result)
  }, [search])

  return (
    <React.Fragment>
      <div className="pt-4">
        <Row className="mb-2">
          <div className="draggable-body-button">
            <Col sm={12}>
              <p className="h4 card-title font-size-16 ">Average KIOSK Usage</p>
              {/* <p className="h4 card-title font-size-16 ">Yearly KIOSK Usage</p> */}
            </Col>
          </div>
        </Row>
        <DataTable
          columns={columns}
          data={filteredYearlyKiosk}
          fixedHeader
          pagination
          selectableRowsHighlight
          highlightOnHover
          fixedHeaderScrollHeight="500px"
          subHeader
          customStyles={customStyles}
          subHeaderComponent={
            <Col className="pt-4 data-table">
              <i className="table-search-icon bx bx-search-alt font-size-22" />
              <Input
                label="Search"
                id="table-search"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </Col>
          }
          subHeaderAlign="left"
        />
      </div>
    </React.Fragment>
  )
}

export default AverageKIOSKTable
