import React, { useState, useEffect } from "react"
import { Col, Input, Row } from "reactstrap"
import DataTable from "react-data-table-component"
import URL from "helpers/url_helper"
import axios from "helpers/api_helper"
import "flatpickr/dist/themes/material_blue.css"
import moment from "moment/moment"

const customStyles = {
  rows: {
    style: {
      minHeight: "100px",
    },
  },
}

function MonthlyUsersTable() {
  const [search, setSearch] = useState("")
  const [loginDataList, setLoginDataList] = useState([])
  const [filteredLoginData, setFilteredLoginDataList] = useState([])
  const startOfMonth = moment().startOf("month").format("MM-DD-YYYY")
  const endOfMonth = moment().endOf("month").format("MM-DD-YYYY")

  const getVendorMonthlyLogin = async () => {
    try {
      const res = await axios.get(URL.GET_VENDOR_MONTHLY_LOGIN_ANALYTICS)
      if (res.data.success) {
        setLoginDataList(res.data.data)
        setFilteredLoginDataList(res.data.data)
      }
    } catch (error) {
      toast.error(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    getVendorMonthlyLogin()
  }, [])

  const columns = [
    {
      name: "Month",
      selector: row => row.month,
    },
    {
      name: "Year",
      selector: row => row.year,
    },
    {
      name: "Average login",
      selector: row => row.avg_login,
    },
    {
      name: "No. of logins",
      selector: row => row.num_of_logins,
      sortable: true,
    },
  ]

  const handleSearch = event => {
    if (event.target.value.length > 20) {
      return
    }
    setSearch(event.target.value)
  }

  useEffect(() => {
    const result = loginDataList?.filter(item => {
      return item?.month?.toLowerCase().match(search?.toLowerCase())
    })

    setFilteredLoginDataList(result?.reverse())
  }, [search])

  return (
    <React.Fragment>
      <div
        className="pt-4"
        // className="page-content"
      >
        <Row className="mb-2">
          <div className="draggable-body-button">
            <Col sm={12}>
              <p className="h4 card-title font-size-16 ">
                Monthly Average Usage
              </p>
            </Col>
          </div>
        </Row>
        <DataTable
          columns={columns}
          data={filteredLoginData}
          fixedHeader
          pagination
          selectableRowsHighlight
          highlightOnHover
          fixedHeaderScrollHeight="500px"
          subHeader
          customStyles={customStyles}
          subHeaderComponent={
            <Col className="pt-4 data-table">
              <i className="table-search-icon bx bx-search-alt font-size-22" />
              <Input
                label="Search"
                id="table-search"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </Col>
          }
          subHeaderAlign="left"
        />
      </div>
    </React.Fragment>
  )
}

export default MonthlyUsersTable
