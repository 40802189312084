import React from "react"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import Geocode from "react-geocode"
import { Col, Input, Label, Row } from "reactstrap"
Geocode.setApiKey("AIzaSyA3DVWS2TNZJCxXKbz3OmTQnVpy1JR1WgA")
Geocode.enableDebug()

export const GoogleMaps = props => {
  const { setAddProperty, property } = props
  const { address, sublocality, state, latitude, longitude } = property

  const handleChange = address => {
    let temp = property
    temp.address = address
    setAddProperty({ ...temp })
  }

  const handleSelect = address => {
    let temp = property
    temp.address = address
    setAddProperty({ ...temp })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        Geocode.fromLatLng(latLng.lat, latLng.lng).then(
          response => {
            const address = response.results[0].formatted_address,
              addressArray = response.results[0].address_components,
              sublocality = getCity(addressArray),
              area = getArea(addressArray),
              state_ = getState(addressArray)

            let temp = property
            temp.state = state_
            temp.sublocality = sublocality
            temp.address = address
            temp.latitude = latLng.lat
            temp.longitude = latLng.lng

            setAddProperty({ ...temp })
          },
          error => {
            console.error(error)
          }
        )
      })
      .catch(error => console.error("Error", error))
  }

  const getCity = addressArray => {
    let sublocality = ""
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        sublocality = addressArray[i].long_name
        return sublocality
      }
    }
  }

  const getArea = addressArray => {
    let area = ""
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name
            return area
          }
        }
      }
    }
  }

  const getState = addressArray => {
    let state = ""
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name
          return state
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div id="googleMaps">
        <Row>
          <Col md={12}>
            <div className="h-3">
              <Label>Location Details</Label>
            </div>
          </Col>
        </Row>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="googleMaps-container">
              <input
                className="form-control"
                {...getInputProps({
                  placeholder: "Search Places ...",
                  // className: "location-search-input",
                })}
              />
              <ul className="list-group w-100">
                {/* {loading && <div>Loading...</div>} */}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? "list-group-item active"
                    : "list-group-item"
                  // inline style for demonstration purpose

                  return (
                    <li
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      {suggestion.description}
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </PlacesAutocomplete>
        <Map
          style={{
            maxHeight: "400px",
          }}
          google={props.google}
          initialCenter={{
            lat: latitude,
            lng: longitude,
          }}
          center={{
            lat: latitude,
            lng: longitude,
          }}
        >
          <Marker
            position={{
              lat: latitude,
              lng: longitude,
            }}
          />
        </Map>
      </div>
      <Row>
        <Col md={3}>
          <div className="mb-3">
            <Label>Sub Locality</Label>
            <Input disabled value={sublocality} className="form-control" />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <Label>State</Label>
            <Input disabled value={state} className="form-control" />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <Label>Latitude</Label>
            <Input disabled className="form-control" value={latitude} />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <Label>Longitude</Label>
            <Input className="form-control" disabled value={longitude} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyA3DVWS2TNZJCxXKbz3OmTQnVpy1JR1WgA",
  region: "in",
  language: "en",
})(GoogleMaps)
