import React, { useEffect, useState } from "react"
import axios from "helpers/api_helper"
import URL from "helpers/url_helper"
import { useHistory, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import {
  CardBody,
  Input,
  Label,
  Col,
  Container,
  Row,
  Button,
  Card,
} from "reactstrap"

// const imageMimeType = /image\/(png|jpg|jpeg)/i
const imageMimeType = /image\/(png|jpg|jpeg|svg)/i

function ManageTeam() {
  document.title = "Add Team"
  const history = useHistory()
  const { state } = useLocation()
  const [manageTeam, setManageTeam] = useState({
    name: state ? state?.state?.name : "",
    designation: state ? state?.state?.designation : "",
    location: state ? state?.state?.location : "",
    img: state ? state?.state?.img : {},
  })

  const [firstFile, setFirstFile] = useState(null)
  const [firstDataURL, setFirstDataURL] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)

  const { name, designation, location, img } = manageTeam

  const onChange = e => {
    if (e.target.value.length > 35) {
      return
    }
    setManageTeam(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const addManageTeam = async () => {
    let formData = new FormData()
    Object.keys(manageTeam).forEach(fieldName => {
      if (manageTeam[fieldName]) {
        formData.append(fieldName, manageTeam[fieldName])
      }
    })
    try {
      const res = await axios.post(URL.ADD_MANAGE_TEAM, formData)
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-team")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  const updateManageTeam = async () => {
    let formData = new FormData()
    Object.keys(manageTeam).forEach(fieldName => {
      if (manageTeam[fieldName]) {
        formData.append(fieldName, manageTeam[fieldName])
      }
    })
    try {
      const res = await axios.put(
        URL.UPDATE_MANAGE_TEAM + `${state?.state?.id}`,
        formData
      )
      if (res.data.success) {
        toast.success(res.data.message)
        history.push("/all-team")
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
      } else {
        toast.error(error)
      }
    }
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (firstFile) {
      fileReader = new FileReader()
      fileReader.onload = e => {
        const { result } = e.target
        if (result && !isCancel) {
          setFirstDataURL(result)
        }
      }
      fileReader.readAsDataURL(firstFile)
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [firstFile])

  const handleMedia = e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      setManageTeam(prevState => ({
        ...prevState,
        img: img,
      }))
    }
    const file = e.target.files[0]
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid")
      return
    }
    setFirstFile(file)
  }

  const handleRemoveMedia = () => {
    let temp = document.querySelector("#image")
    temp.value = null
    setFirstDataURL(null)
    setManageTeam(prevState => ({
      ...prevState,
      img: [],
    }))
  }

  const handleGoBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (
      img !== "" &&
      name.length > 0 &&
      designation.length > 0 &&
      location.length > 0
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [name, designation, location, img])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <div className="py-4">
              <Col
                lg={12}
                className="d-flex justify-content-between align-items-center"
              >
                <p className="h4 card-title font-size-22">
                  {state ? "Edit Team" : "Add Team"}
                </p>
                <div className="d-flex flex-wrap gap-2 float-end">
                  <Button
                    disabled={isDisabled}
                    onClick={state ? updateManageTeam : addManageTeam}
                    className={
                      isDisabled
                        ? "btn btn-rounded float-end w-sm mb-4"
                        : "btn btn-info btn-rounded float-end w-sm mb-4"
                    }
                  >
                    Save
                  </Button>
                  <Button
                    className="btn btn-rounded float-end w-sm mb-4"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </div>
          </Row>
          <Card>
            <CardBody>
              <div className="mb-3">
                <label>Full Name</label>

                <Input
                  name="name"
                  value={name}
                  onChange={onChange}
                  id="outlined-basic"
                  label="Full Name"
                  placeholder="Full Name"
                />
              </div>
              <div className="mb-3">
                <label>Designation</label>
                <Input
                  name="designation"
                  value={designation}
                  onChange={onChange}
                  id="outlined-basic"
                  label="Designation"
                  placeholder="Designation"
                />
              </div>
              <div className="mb-3">
                <label>Location</label>
                <Input
                  name="location"
                  value={location}
                  onChange={onChange}
                  id="outlined-basic"
                  label="Location"
                  placeholder="Location"
                />
              </div>

              <Label>Image</Label>
              <div className="img-container p-2">
                {Object.keys(img).length > 0 && (
                  <div className="media-container">
                    <div className="media-main">
                      <img
                        className="media-img"
                        src={`${img.image_path}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${img.image_path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt="Image"
                        loading="lazy"
                      />
                      <div>
                        <i
                          className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                          onClick={handleRemoveMedia}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {firstDataURL && (
                  <div className="media-preview-container">
                    <div className="media-preview">
                      <img
                        className="media-preview-img"
                        src={firstDataURL}
                        srcSet={firstDataURL}
                        alt="Banner"
                        loading="lazy"
                      />
                      <i
                        className="media-remove-icon mdi mdi-delete-outline d-block font-size-24"
                        onClick={handleRemoveMedia}
                      />
                    </div>
                  </div>
                )}
              </div>

              <Input
                id="image"
                type="file"
                name="img"
                placeholder="Choose image"
                accept="image/*"
                onChange={handleMedia}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageTeam
